import {Component, Input, OnInit} from '@angular/core';
import { environment } from '../../environments/environment';
import { DataService } from '../services/data.service';
import { ActivatedRoute } from '@angular/router';
import {VideoCard, TagWiseContent, TopicCard} from "../models/video/video.interface";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ProgressSpinnerDialogComponent} from "../shared/progress-spinner-dialog/progress-spinner-dialog.component";
import {HttpErrorResponse} from "@angular/common/http";
import {NavbarService} from "../services/navbar.service";

interface MixedCard {
  type: 'video' | 'topic';
  data: VideoCard | TopicCard;
}

@Component({
  selector: 'app-tags-view',
  templateUrl: './tags-view.component.html',
  styleUrls: ['./tags-view.component.scss']
})
export class TagsViewComponent implements OnInit {
  @Input('tag_id') id: number = 0;
  tagName: string = '';
  backendUrl: string = '';
  mode: 'popup' | 'view' = 'view';
  newest_tag_content:MixedCard[] =[];
  all_tag_content: MixedCard[] = [];
  filtered_tag_content: MixedCard[] = [];
  searchText: string ='';
  filter_option: string = 'all';
  sort_option: string = 'views';


  constructor(private dataService: DataService,
              private route: ActivatedRoute,
              private dialog: MatDialog,
              public navbarService: NavbarService,) { }

  ngOnInit(): void {
    this.backendUrl = environment.backendURL;
    if (this.id) {
      this.mode = 'popup';
      this.getTagContent();
    } else {
      this.route.params.subscribe(params => {
        this.id = params['id'];
        this.getTagContent();
      });
    }
  }

  getTagContent(): void {
    const dialogRef: MatDialogRef<ProgressSpinnerDialogComponent> =
      this.dialog.open(ProgressSpinnerDialogComponent, {
        panelClass: 'transparent',
        disableClose: true,
        data: "Bitte warten...",
      });

    const tagUrl = `user/tags/${this.id}/`;
    this.dataService.getURL(tagUrl, {observe: 'body', responseType: 'json'}).subscribe((resp: TagWiseContent) => {
      this.tagName = resp.name;

      // add videos to mixed card
      for (let i of resp.videos as VideoCard[]) {
        this.all_tag_content.push({type: "video", data: i});
      }

      // add topics
      for (let i of resp.topics as TopicCard[]) {
        this.all_tag_content.push({type: "topic", data: i});
      }

      // apply filter
      this.applySearchFilter()

      // filter newest data
      const today = new Date();
      const priorDate = new Date(new Date().setDate(today.getDate() - 30));
      this.newest_tag_content = this.all_tag_content.filter(el => new Date(el.data.added_on).getTime() > priorDate.getTime());
      dialogRef.close();
    }, (err: HttpErrorResponse) => {
      console.error(err);
      if (err.status === 429) {
        window.alert(err.error.detail);
      } else {
        window.alert(err.error);
      }
      dialogRef.close();
    });
  }

  backTo() {
    window.history.back();
  }

  scrollToLeftRight(id: string, left: boolean = false) {
    const element = document.getElementById(id);
    // element has 2+x children, first and last children are buttons
    // scroll distance = width of each child * number of children visible
    const child_width = element.firstElementChild.clientWidth;
    const viewport_width = element.clientWidth;
    const number_of_visible_children = Math.max(Math.floor(viewport_width / child_width), 1);
    // above number may become 0 for very large zoom levels, hence using max

    let scroll_distance = (child_width + 20) * number_of_visible_children;
    // margin-right is added
    if (left) {
      scroll_distance = -scroll_distance;
    }

    element.scrollBy({
      behavior: "smooth",
      left: scroll_distance,
      top: 0
    });
  }

  applySearchFilter() {
    // first search
    //this.searchText = this.searchText.trim();
    this.filtered_tag_content = this.all_tag_content.filter(el =>
      el.data.title.toLowerCase().includes(this.searchText.trim().toLowerCase())
    );

    // then filter
    switch (this.filter_option) {
      case 'all':
        // do nothing
        break;
      case 'featured':
        this.filtered_tag_content = this.filtered_tag_content.filter(el => el.data.is_featured);
        break;
      case 'external':
        this.filtered_tag_content = this.filtered_tag_content.filter(el => el.data.is_external);
        break;
    }

    // then sort
    switch (this.sort_option) {
      case 'added_on':
        this.filtered_tag_content = this.filtered_tag_content.sort((a, b) => new Date(b.data.added_on).getTime() - new Date(a.data.added_on).getTime());
        break;
      case 'last_edited_on':
        this.filtered_tag_content = this.filtered_tag_content.sort((a, b) => new Date(b.data.last_edited_on).getTime() - new Date(a.data.last_edited_on).getTime());
        break;
      case 'views':
        this.filtered_tag_content = this.filtered_tag_content.sort((a, b) => b.data.views - a.data.views);
        break;
    }

    document.getElementById('discover-tags').scrollIntoView({behavior: 'smooth', block: "start", inline: "nearest"});
    if (this.filtered_tag_content.length) {
      // element may not be ready
      document.getElementById('filtered_tag_content')?.scrollIntoView({behavior: 'smooth'});
    }
  }
}
