import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {environment} from '../../environments/environment';
import {MiniDetails, VideoCard, VideoHistoryCard, ChecklistItem, TopicCard} from '../models/video/video.interface';
import {PlaylistCard} from '../models/playlist/playlist';
import {AuthService, urlify} from '../services/auth.service';
import {DataService} from '../services/data.service';
import {NavbarService} from '../services/navbar.service';
import {TranslateService} from "@ngx-translate/core";
import {Friend} from "../dashboard/global-administration/partner-organizations/partner-organizations.component";
import {UserActivitiesOverview, RecentCreator} from "../models/activity.interface";
import {UtilityService} from '../services/utility.service';
import { Chart } from 'chart.js';
import { forkJoin } from 'rxjs';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { KeyValuePipe } from '@angular/common';


// a local interface to organise data
interface MixedCard {
  type: 'video' | 'topic' | 'playlist';
  data: VideoCard | PlaylistCard | TopicCard;
}

@Component({
  selector: 'app-quickwin-overview',
  templateUrl: './quickwin-overview.component.html',
  styleUrls: ['./quickwin-overview.component.scss'],
  providers: [KeyValuePipe]
})
export class QuickwinOverviewComponent implements OnInit {
  @ViewChild('mat_chips') mat_chips: ElementRef;

  backendUrl: string;

  is_site_notice_closed: boolean = false;
  site_notice = this.authService.company.site_notice;
  formatted_site_notice: string = '';

  page: number = 1;

  tags: MiniDetails[] = [];
  partner_companies: Friend[] = [];
  all_videos: VideoCard[] = [];
  all_video_count: number = 0;
  all_playlists: PlaylistCard[] = [];
  all_playlist_count: number = 0;
  all_topic_count: number = 0;
  all_topics: TopicCard[] = [];
  all_groups: ChecklistItem[] = [];
  recent_users: RecentCreator[] = [];
  history_videos: VideoHistoryCard[] = [];
  history_video_count: number = 0;
  mixed_cards: MixedCard[] = [];
  mixed_featured_content: MixedCard[] = [];

  options = {observe: 'body', responseType: 'json'};
  showMore: boolean = false;
  firstRowTags: MiniDetails[] = [];
  moreTags: MiniDetails[] = [];
  userActivityResp: UserActivitiesOverview = null;
  increasePercentage: number = 0;
  greetings_text: string = "";
  active_days_label: string[] = [];  // ['Mo', 'Tu'…] last seven days, including today
  seven_days_label: string[] = [];  // labels when user was active
  my_dept_name: string = "";
  my_team_name: string = "";

  chart_button_toggle: string = 'all';
  is_chart_ready: boolean = false;
  chart1: any;
  chart2: any;
  defaultOrder: [string, number][] = [
    ['userBlock', 1],
    ['tags', 1],
    ['continueEditing', 1],
    ['featured', 1],
    ['continueWatching', 1],
    ['explore', 1],
    ['clypp', 1],
    ['playlist', 1],
    ['explorePages', 1],
  ];

  homePageLabelIconMap = {
    userBlock: [this.translateService.instant('Your activities'), 'mood'], // [ icon, name ] to show in the side panel according to blocks
    tags: [this.translateService.instant('Keywords'), 'tag'],
    continueEditing: [this.translateService.instant('Continue editing'), 'movie_edit'],
    featured: [this.translateService.instant('Hervorgehoben'), 'grade'],
    continueWatching: [this.translateService.instant('Continue watching'), 'history'],
    explore: [this.translateService.instant('Explore your groups'), 'groups'],
    clypp: [this.translateService.instant('Latest Clypps for you'), 'play_circle'],
    playlist: [this.translateService.instant('Selected playlists for you'), 'playlist_play'],
    explorePages: [this.translateService.instant('Explore pages from others'), 'sticky_note_2']
  };

  currentOrder: [string, number][] = [];

  constructor(private dataService: DataService,
              private router: Router,
              protected navbarService: NavbarService,
              public authService: AuthService,
              public utilityService: UtilityService,
              private translateService: TranslateService,) {
    this.formatted_site_notice = urlify(this.site_notice);
    this.currentOrder = JSON.parse(JSON.stringify(this.defaultOrder));
    this.getCookies();
  }

  // hide buttons if no scroll is there
  showHideScrollButtons() {
    // load all left-right buttons and hide them if not needed
    let divs = document.getElementsByClassName("left-right-div");
    for (let i of Array(divs.length).keys()) {
      let div = divs.item(i);
      if (div.scrollWidth <= div.clientWidth) {
        // no overflow, hide buttons
        div.getElementsByClassName("left-scroll-button").item(0).remove();
        div.getElementsByClassName("right-scroll-button").item(0).remove();
      }
    }
  }

  ngOnInit(): void {
    this.navbarService.getUserProfileDetails();
    this.backendUrl = environment.backendURL;
    this.navbarService.disableCreationMode();

    // load tags and partnerships
    const interval = setTimeout(() => {
      // load after 1 sec, as auth service may not be ready
      this.getPartnerships();
      this.getFeaturedTags();
      this.getDeptAndGroups();
      clearTimeout(interval);
    }, 500);

    this.getUserActivityDetails();
    this.getAllBlockData();
    if (localStorage.getItem('site_notice_close')) {
      this.is_site_notice_closed = true;
    }

    // if we are coming to this page from microsoft sign in, and if we are in teams browser, then close the window
    // or, if history length is 2 (then also close this)
    if (document.referrer.indexOf('microsoft') > -1) {
      if (navigator.userAgent.indexOf('Teams') > -1 || history.length == 2) {
        window.alert('Your sign-in was successful. Please close this tab and redirect to Microsoft Teams.');
        window.close();
      }
    }

    // find hour of the day to show greetings
    const d = new Date();
    const hour = d.getHours();
    if (hour <= 11) {
      this.greetings_text = this.translateService.instant("Good morning");
    } else if (hour <= 16) {
      this.greetings_text = this.translateService.instant("Good afternoon");
    } else {
      this.greetings_text = this.translateService.instant("Good evening");
    }
  }

  getAllBlockData(){
    const featuredVideos = this.dataService.getURL('user/videos/?is_featured=true&sort_by=added_on', this.options);
    const continueWatching = this.dataService.getURL('user/videos/history/?page=1', this.options);
    const featuredPlaylist = this.dataService.getURL('user/playlists/?page=1&is_featured=true', this.options);
    const newestClypp = this.dataService.getURL('user/videos/?sort_by=added_on&page=1', this.options);
    // only show playlists in my dept, not in the entire company
    const newestPlaylist = this.dataService.getURL('user/playlists/?page=1&sort_by=added_on&dept', this.options);
    const featuredTopics = this.dataService.getURL('user/topics/?page=1&is_featured=true', this.options);
    const newestTopics = this.dataService.getURL('user/topics/?page=1&sort_by=added_on', this.options);

    forkJoin([featuredVideos, continueWatching, featuredPlaylist, newestClypp, newestPlaylist,
      featuredTopics, newestTopics]).subscribe(
      (responses) => {
        this.history_videos = responses[1]['data'] as VideoHistoryCard[];
        this.history_video_count = responses[1]['length'];
        this.all_videos = responses[3]['data'] as VideoCard[];
        this.all_video_count = responses[3]['length'];
        this.all_playlists = responses[4]['data'] as PlaylistCard[];
        this.all_playlist_count = responses[4]['length'];
        this.all_topics = responses[6]['data'] as TopicCard[];
        this.all_topic_count = responses[6]['length'];

        // load the mixed cards
        for (let i of responses[0]['data'] as VideoCard[]) {
          this.mixed_featured_content.push({type: "video", data: i});
        }
        for (let i of responses[2]['data'] as PlaylistCard[]) {
          this.mixed_featured_content.push({type: "playlist", data: i});
        }
        for (let i of responses[5]['data'] as TopicCard[]) {
          this.mixed_featured_content.push({type: "topic", data: i});
        }

        // now sort them
        this.mixed_featured_content.sort((a, b) =>
          new Date(b.data.added_on).getTime() - new Date(a.data.added_on).getTime());

        // show hide buttons after a few seconds to render data
        const interval = setInterval(() => {
          // load after 2 sec, as html may not be ready
          this.showHideScrollButtons();
          clearInterval(interval);
        }, 2000);
      },
      (error) => {
        console.error(error);
      }
    );
  }

  siteNoticeClose() {
    this.is_site_notice_closed = true;
    localStorage.setItem('site_notice_close', 'true');
  }

  // load dept/team labels and groups from checklist data
  getDeptAndGroups() {
    this.my_dept_name = this.authService.checklist_data.find(
      (el) => el.id == this.authService.userDetails.department && el.type == 'dept'
    )?.name;

    this.my_team_name = this.authService.checklist_data.find(
      (el) => el.id == this.authService.userDetails.team && el.type == 'team'
    )?.name;

    this.all_groups = this.authService.checklist_data.filter(g => g.type == 'group');
  }

  getFeaturedTags(): void {
    this.dataService.getURL('user/featured-tags/').subscribe((data: MiniDetails[]) => {
      this.tags = data;
      this.firstRowTags = JSON.parse(JSON.stringify(this.tags));
      setTimeout(() => this.setLoop(), 0);
    });
  }

  getPartnerships(): void {
    // load partner companies only if enabled
    if (this.authService.company.is_partner_content_shown) {
      this.dataService.getURL('user/friends/', this.options)
        .subscribe((res: Friend[]) => {
          this.partner_companies = res;
        });
    }
  }

  setLoop(){
    let widthOfDiv = this.mat_chips?.nativeElement.offsetWidth - 15;
    let list = document.querySelectorAll('mat-chip');
    let sum = 0;
    let count = 0;
    list.forEach(chip => {
      sum += chip['offsetWidth'] + 8;
      if (!(sum >= (widthOfDiv - 200))) {
        count += 1;
      }
    })
    if(widthOfDiv < sum) {
      this.moreTags = this.firstRowTags.splice(count - 3);
    }
    this.firstRowTags = JSON.parse(JSON.stringify(this.firstRowTags));
  }

  showMoreFn(){
    this.showMore = !this.showMore;
    this.moreTags = JSON.parse(JSON.stringify(this.tags));
  }

  partnerSelected(partnership_id: number){
    this.router.navigate(['partnership', partnership_id]);
  }

  tagSelected(tag_id: number) {
    this.router.navigate(['tag', tag_id]);
  }

  getUserActivityDetails() {
    this.dataService.getURL('user/activities/overview/', this.options)
      .subscribe((res: UserActivitiesOverview) => {
        this.userActivityResp = res;
        this.recent_users = this.userActivityResp.recent_creators;

        // load the mixed cards
        for (let i of this.userActivityResp.my_latest_videos) {
          this.mixed_cards.push({type: "video", data: i});
        }
        for (let i of this.userActivityResp.my_latest_playlists) {
          this.mixed_cards.push({type: "playlist", data: i});
        }
        for (let i of this.userActivityResp.my_latest_topics) {
          this.mixed_cards.push({type: "topic", data: i});
        }
        // now sort them
        this.mixed_cards.sort((a, b) =>
          new Date(b.data.last_edited_on).getTime() - new Date(a.data.last_edited_on).getTime());

        // now slice it to three items
        this.mixed_cards = this.mixed_cards.slice(0, 3)

        // load the days active card
        const initial = res.n_14_days - res.n_7_days;
        const final = res.n_7_days;
        // formula: (final-initial)/initial
        if (initial != 0){
          this.increasePercentage = Math.round(((final - initial) / initial) * 100);
        } // else, do not show

        // prepare last 7 active days
        for (let i of this.userActivityResp.active_date_7_days){
          this.active_days_label.push(new Date(i).toDateString().slice(0,2));
        }
        let i = 6;
        let seven_days: Date[] = [];
        while (i>=0) {
          // get the UTC date, because server time is in UTC
          const today = new Date();
          // now load the labels of last 7 days (including today)
          seven_days.push(new Date(today.setDate(today.getUTCDate() - i)));
          i--;
        }
        for (let i of seven_days) {
          this.seven_days_label.push(i.toDateString().slice(0, 2));
        }
        const showUserBlock = this.currentOrder.find(([key, value]) => key == 'userBlock' && value == 1);
        if (showUserBlock) {
          // not undefined
          this.chartInterval();
        }
      }, (err) => {
        window.alert(err.error.detail);
      });
  }

  // takes user to video edit page
  editLastVideo(video: VideoCard) {
    const video_id: string = video.id;
    switch (video.state) {
      case 'CO' || 'PR':
        this.router.navigate([`/create-video/${video_id}/review`]);
        break;
      case 'UP' || 'FA':
        this.router.navigate([`/create-video/${video_id}/trim`]);
        break;
      default:
        // NO video
        this.router.navigate([`/create-video/${video_id}/record`]);
        break;
    }
  }

  scrollToLeftRight(id: string, left: boolean = false){
    const element = document.getElementById(id);
    // element has 2+x children, first and last children are buttons
    // scroll distance = width of each child * number of children visible
    const child_width = element.firstElementChild.clientWidth;
    const viewport_width = element.clientWidth;
    const number_of_visible_children = Math.max(Math.floor(viewport_width/child_width), 1);
    // above number may become 0 for very large zoom levels, hence using max

    let scroll_distance = (child_width + 20) * number_of_visible_children;
    // margin-right is added
    if (left) {
      scroll_distance = -scroll_distance;
    }

    element.scrollBy({
      behavior: "smooth",
      left: scroll_distance,
      top: 0
    });
  }

  navigateUserPage(user_id) {
    this.router.navigate(['user', user_id]);
  }

  viewGroup(id: number) {
    this.router.navigate(['groups', id]);
  }

  loadCharts() {
    Chart.defaults.font.family = "nunito";
    Chart.defaults.font.size = 16;
    const canvas1 = document.getElementById('canvas1') as HTMLCanvasElement;
    const canvas2 = document.getElementById('canvas2') as HTMLCanvasElement;

    this.chart1 = new Chart(canvas1, {
      type: 'doughnut',
      data: {
        labels: [
          'Clypps',
          'Playlists',
          this.translateService.instant('Pages'),
        ],
        datasets: [
          {
            data: [
              this.userActivityResp.n_all_videos,
              this.userActivityResp.n_all_playlists,
              this.userActivityResp.n_all_topics
            ],
            backgroundColor: ['#5B93FF', '#FF8F6B', '#FFD66B'],
          },
        ]
      },
      options: {
        scales: {
          x: {
            display: false,
          },
          y: {
            display: false
          }
        },
        plugins: {
          legend: {
            display: true,
            position: 'right',

            labels: {
              usePointStyle: true,
              color: 'black',
            },
          },
          tooltip: {
            enabled: true
          }
        },
        aspectRatio: 1.9,
        cutout: '80%',
        responsive: true,
        maintainAspectRatio: true
      },
    });

    this.chart2 = new Chart(canvas2, {
      type: 'doughnut',
      data: {
        labels: [
          'Clypps',
          'Playlists',
          this.translateService.instant('Pages'),
        ],
        datasets: [
          {
            data: [
              this.userActivityResp.n_my_videos,
              this.userActivityResp.n_my_playlists,
              this.userActivityResp.n_my_topics
            ],
            backgroundColor: ['#5B93FF', '#FF8F6B', '#FFD66B'],
          },
        ]
      },
      options: {
        scales: {
          x: {
            display: false,
          },
          y: {
            display: false
          }
        },
        plugins: {
          legend: {
            display: true,
            position: 'right',

            labels: {
              usePointStyle: true,
              color: 'black',
            },
          },
          tooltip: {
            enabled: true
          }
        },
        aspectRatio: 1.9,
        cutout: '80%',
        responsive: true,
        maintainAspectRatio: true
      },
    });

    this.is_chart_ready = true;
  }

  onChartChange() {
    const chart = (this.chart_button_toggle === 'mine') ? this.chart2 : this.chart1;
    chart.options.animation.duration = 2000;
    chart.reset();
    chart.update();
  }

  chartInterval(){
    const interval = setInterval(() => {
      // load after 1 sec, as html may not be ready
      this.loadCharts();
      clearInterval(interval);
    }, 500);
  }

  getCookies() {
    if (document.cookie.includes('homepageOrder')) {
      const value = `; ${document.cookie}`;
      const parts = value.split("; homepageOrder=");
      if (parts.length == 2) {
        this.currentOrder = JSON.parse(parts.pop().split(';').shift());
      }
    }
  }

  saveCurrentOrder() {
    const expires = new Date();
    expires.setMonth(expires.getMonth() + 2);  // save it for 2 months
    document.cookie = `homepageOrder=${JSON.stringify(this.currentOrder)}; expires=${expires.toUTCString()}; secure; samesite=None; path=/;`;
  }

  resetHomePageOrder() {
    this.currentOrder = JSON.parse(JSON.stringify(this.defaultOrder));
    // do not save, let user save via done button
    // this.saveCurrentOrder();
    this.chartInterval();
  }

  sectionDropped(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.currentOrder, event.previousIndex, event.currentIndex);
  }

  toggleVisibility(index: number): void {
    const [key, value] = this.currentOrder[index];
    this.currentOrder[index][1] = +!value;  // if zero, then make it 1; if 1, then make it 0
    if (key == 'userBlock' && value == 1) {
      this.chartInterval();
    }
  }

}
