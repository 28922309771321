<!--title and filter-->
<div class="d-flex justify-content-between flex-wrap">
  <h2 class="fontStyle d-flex align-items-center" translate>Recent activities</h2>
  <!--filter options-->

  <mat-form-field appearance="outline">
    <mat-label translate>Activity</mat-label>
    <input [formControl]="activityFormControl" [matAutocomplete]="auto3" matInput type="text">
    <mat-autocomplete #auto3="matAutocomplete" (optionSelected)="onActivitySelected($event)"
                      [displayWith]="activityDisplayFn.bind(this)">
      <mat-option value="">
        {{ 'Alle'|translate }}
      </mat-option>
      <mat-option *ngFor="let act of filteredActivities | async" [title]="act.activity_code"
                  [value]="act.activity_code">
            <span *ngIf="german_labels">
              {{ act.prefix_de }}&nbsp;{{ act.link_de }}&nbsp;{{ act.suffix_de }}
            </span>
        <span *ngIf="!german_labels">
              {{ act.prefix_en }}&nbsp;{{ act.link_en }}&nbsp;{{ act.suffix_en }}
            </span>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label translate>Filter users</mat-label>
    <input [formControl]="user_name_form_control" [matAutocomplete]="auto2"
           matInput
           placeholder="{{'Name' | translate}}" type="text">
    <mat-autocomplete #auto2="matAutocomplete">
      <mat-option (onSelectionChange)="onUserSelected($event, user.id)"
                  *ngFor="let user of filteredOptions | async"
                  [title]="user.string" [value]="user.name">
        {{ user.name }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>

<table [dataSource]="dataSource" fixedLayout="true" mat-table style="width: 100%;">
  <!-- user Column -->
  <ng-container matColumnDef="user">
    <td *matCellDef="let element" class="pr-2" mat-cell style="width:80%">
      <b [title]="element.email">{{ element.first_name }}&nbsp;{{ element.last_name }}</b>
      {{ element.prefix }}
      <a *ngIf="element.href" [href]="element.href"
         [matTooltip]="element.supporting_text" matTooltipPosition="right"
         target="_blank">
        {{ element.link_text }}
      </a>
      <span *ngIf="element.href.length == 0"
            [matTooltip]="element.supporting_text" matTooltipPosition="right">
                {{ element.link_text }}
              </span>
      {{ element.suffix }}
    </td>
  </ng-container>

  <!-- time Column -->
  <ng-container matColumnDef="time">
    <td *matCellDef="let element" [title]="element.created_on" mat-cell style="text-align: end;"
        style="width:20%">
      {{ utilityService.timeSince(element.created_on, "ago") | async }}
    </td>
  </ng-container>
  <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
</table>

<mat-paginator (page)="updatePage($event)" [length]="resultsLength"
               [pageIndex]="currentPage" class="pagination"
               hidePageSize
               pageSize="10"
               showFirstLastButtons></mat-paginator>

<mat-progress-bar mode="indeterminate" *ngIf="inProgress"></mat-progress-bar>
