import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DataService} from 'src/app/services/data.service';
import {TranslateService} from '@ngx-translate/core';
import 'quill-mention';
import 'quill-emoji';
import {AuthService} from "../services/auth.service";
import {TranslationDetails} from "../models/video/video.interface";

interface Translation extends TranslationDetails {
  // custom
  vtt_file_data: any;
}


@Component({
  selector: 'app-add-translations',
  templateUrl: './add-translations.component.html',
  styleUrls: ['./add-translations.component.scss']
})
export class AddTranslationsComponent implements OnInit {
  @ViewChild('customQuill', {static: false}) customQuill: any;
  stretch = null;
  newLanguage = null;
  translations: Translation[] = [];
  languages: [string, string][] = [];
  languages_dict: any; // to keep track of all languages
  add_languages_array: [string, string][] = []; // to keep track of languages which can be added
  inProgress = false;
  is_ai_service_disabled: boolean = false;  // disable button

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialog: MatDialog,
              public dialogRef: MatDialogRef<AddTranslationsComponent>,
              private httpClient: HttpClient,
              private dataService: DataService,
              private snackBar: MatSnackBar,
              private authService: AuthService,
              private translateService: TranslateService) {
    for (let i of this.authService.languages.entries()) {
      this.languages.push(i);
    }
    this.languages_dict = new Map(this.languages);
    // this will disable some buttons like auto translate
    this.is_ai_service_disabled = !this.authService.company.is_transcription_service_enabled;
  }

  ngOnInit(): void {
    this.inProgress = true;
    this.dataService.getURL(`user/videos/${this.data.video_id}/translations/`).subscribe((res: Translation[]) => {
      this.translations = res;
      this.updateLangDict();
      this.translations.map(async ele => {
        ele.vtt_file_data = ele.vtt_file ? await this.readTextFile(ele.vtt_file) : null;
      })
      this.inProgress = false;
    }, (err) => {
      this.inProgress = false;
      window.alert(err.error);
    })

    this.dialogRef.backdropClick().subscribe(()=>{
      this.closePopup();
    });

  }

  closePopup(): void {
    this.dialogRef.close(this.translations);
  }

  newLang() {
    this.inProgress = true;
    this.dataService.postURL<any>(`user/videos/${this.data.video_id}/translations/`, {language: this.newLanguage}, {
      observe: 'body',
      responseType: 'json'
    })
      .subscribe(async (resp: Translation) => {
        resp.vtt_file_data = resp.vtt_file ? await this.readTextFile(resp.vtt_file) : null;
        this.translations.unshift(resp);
        this.updateLangDict();
        this.inProgress = false;
        this.snackBar.open(this.translateService.instant('Erfolgreich'), '', {duration: 2500});
        this.newLanguage = null;
      }, (err: HttpErrorResponse) => {
        window.alert(err.error);
        this.inProgress = false;
      });
  }

  readTextFile(file) {
    return new Promise((resolve) =>
      this.httpClient.get(file, {responseType: 'text' as const}).subscribe(data => {
        resolve(data);
      })
    )
  }

  save(translation: Translation, index: number) {
    // index is the tab index: 0 for title/desc and 1 for vtt file
    this.inProgress = true;
    if (index == 0) {
      let obj = {
        title: translation.title,
        desc: translation.desc === null ? '' : translation.desc  // desc can not be null
      }
      this.dataService.putURL(`user/translations/${translation.id}/`, obj,{observe: 'body', responseType: 'json'}).
      subscribe((res: any) => {
        this.snackBar.open(this.translateService.instant('Gespeichert'), '', {duration: 2500});
        this.inProgress = false;
      }, (err) => {
        console.error(err);
        this.snackBar.open(this.translateService.instant('Ein Fehler ist aufgetreten'), '', {duration: 2000});
        this.inProgress = false;
      });
    } else {
      let transcriptFormObj = translation.vtt_file_data;
      const textToBLOB = new Blob([transcriptFormObj], {type: 'text/plain'});
      let file = new File([textToBLOB], `${this.data.video_id}_${translation.language}.vtt`);
      let formData = new FormData();
      formData.append('vtt_file', file);
      this.dataService.putURL(`user/translations/${translation.id}/`, formData, {observe: 'body',responseType: 'json'}).
      subscribe((res: any) => {
        if (res['response'] == 'failure') {
          window.alert(res['reason']);
        } else {
          this.snackBar.open(this.translateService.instant('Gespeichert'), '', {duration: 2500});
        }
        this.inProgress = false;
      }, (error) => {
        window.alert(error.error.text);
        this.inProgress = false;
      });
    }
  }

  delete_or_update(translation_id: number, update_existing = false, confirmed = false) {
    // based on the boolean, this method either just deletes the translation
    // or also creates a new one!
    if (!confirmed) {
      const message = this.translateService.instant("Bist du sicher?");
      confirmed = window.confirm(message);
    }

    if (confirmed) {
      this.inProgress = true;
      this.dataService.deleteURL(`user/translations/${translation_id}/`).subscribe(() => {
        // keeping track of deleted one
        const current_index = this.translations.findIndex(e => e.id == translation_id);
        let newLanguage = this.translations[current_index].language;
        this.translations.splice(current_index, 1);
        this.updateLangDict();
        this.inProgress = false;
        if (update_existing) {
          // window.confirm is already called
          this.newLanguage = newLanguage;
          this.newLang();
        } else {
          // message will be displayed in new lang tab
          this.snackBar.open(this.translateService.instant('Erfolgreich'), '', {duration: 2500});
        }
      }, (err) => {
        window.alert(err.error);
        this.inProgress = false;
      });
    }
  }

  getStartOfScript(transcript) {
    return transcript && transcript.length >= 6 && transcript.substring(0, 6) != 'WEBVTT';
  }

  updateLangDict() {
    let add_languages_dict = new Map(this.languages);
    add_languages_dict.delete('');
    add_languages_dict.delete(this.data.originalLanguage);
    for (let trans of this.translations) {
      add_languages_dict.delete(trans.language);
    }
    this.add_languages_array = [];
    for (let i of add_languages_dict.entries()) {
      this.add_languages_array.push([i[0], i[1]]);
    }
  }

  updateAllTranslations() {
    const message = this.translateService.instant("Bist du sicher?");
    if (window.confirm(message)) {
      for (let t of this.translations) {
        this.delete_or_update(t.id,true, true);
      }
    }
  }
}
