import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {UtilityService} from 'src/app/services/utility.service';
import {VideoCard, ChecklistItem} from '../models/video/video.interface';
import {DataService} from '../services/data.service';
import {FormControl} from "@angular/forms";
import {Observable} from "rxjs";
import {map, startWith} from "rxjs/operators";
import {AuthService} from "../services/auth.service";

@Component({
  selector: 'app-select-video-popup',
  templateUrl: './select-video-popup.component.html',
  styleUrls: ['./select-video-popup.component.scss']
})
export class SelectVideoPopupComponent implements OnInit {
  search_videos: VideoCard[] = [];
  showSpinner: boolean = false;

  // improve search input and provide users
  myControl = new FormControl('');
  options: ChecklistItem[] = [];  // stores all users
  filteredOptions: Observable<ChecklistItem[]>;  // show filter based on user search
  apiURL: string = "";
  block_regular_search: boolean = false;  // this variable is used to control loading search results when pressing enter
  current_user_id: number = 0;

  constructor(private dataService: DataService,
              private authService: AuthService,
              public dialogRef: MatDialogRef<SelectVideoPopupComponent>,
              public utilityService: UtilityService) {
    this.current_user_id = this.authService.userDetails.user;
  }

  ngOnInit(): void {
    // this will load my newest 10 published videos
    this.showSearchResults();

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter((value || ''))),
    );
  }

  // internal method to provide filter results
  private _filter(value: string): ChecklistItem[] {
    const filterValue = value.toLowerCase().trim();
    if(filterValue == '') {
      // return nothing if user does not type anything
      return [];
    }

    // search in users only
    return this.authService.checklist_data
      .filter(item => item.name.toLowerCase().includes(filterValue) && item.type == 'user');
  }

  // when a user is selected from dropdown, update data url and load videos
  showUserVideos(item: ChecklistItem) {
    this.apiURL = `user/${item.id}/content/`;
    // block the regular search
    this.block_regular_search = true;
    this._loadResults();
  }

  showSearchResults(){
    if (this.block_regular_search) {
      // user pressed enter on user selection
      return;
    }
    this.apiURL = `user/videos/?search_query=${this.myControl.value}&page=1`;
    this._loadResults();
  }

  _loadResults() {
    // else, search for data
    this.showSpinner = true;
    this.dataService.getURL(this.apiURL).subscribe((resp: any) => {
      this.showSpinner = false;
      if (resp['data']) {
        this.search_videos = resp['data'];
      } else {
        this.search_videos = resp['videos']
      }
    }, (err) => {
      console.error(err);
      this.showSpinner = false;
    }, () => {
      // unblock search
      this.block_regular_search = false;
    });
  }

  // open edit page in a new tab
  editVideo(video_id: string) {
    window.open(`/create-video/${video_id}/review`, '_blank');
  }
}
