import {Component, Input, OnInit, OnDestroy, AfterViewInit} from '@angular/core';
import {DataService} from "../services/data.service";
import {Media, VideoProfile, VideoView} from "../models/video/video.interface";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {delay} from "../quickwin-creation/webcam-creation/webcam-creation.component";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {MyVideosVideoPopupComponent} from "../my-videos-video-popup/my-videos-video-popup.component";
import {MatDialog} from "@angular/material/dialog";
import {AuthService} from "../services/auth.service";
import {HttpErrorResponse, HttpEventType} from '@angular/common/http';
import {MatSnackBar} from "@angular/material/snack-bar";
import {inIframe} from "../login/login.component";
import {urlToBlob} from "../quickwin-view/quickwin-view.component";
// import * as BodyPix from '@tensorflow-models/body-pix';
// import '@tensorflow/tfjs-backend-cpu';
// import '@tensorflow/tfjs-backend-webgl';
// import {PersonInferenceConfig} from "@tensorflow-models/body-pix/dist/body_pix_model";
import {
    VideoProfileComponent
} from "../dashboard/global-administration/company-management/video-profile/video-profile.component";
import {Results, SelfieSegmentation} from '@mediapipe/selfie_segmentation';
import {Camera} from '@mediapipe/camera_utils';
import {CompletionPopupComponent} from "../completion-popup/completion-popup.component";
import {file} from "jszip";


@Component({
    selector: 'app-sc-record-dialog',
    templateUrl: './sc-record-dialog.component.html',
    styleUrls: ['./sc-record-dialog.component.scss'],
    // encapsulation: ViewEncapsulation.None
})
export class ScRecordDialogComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() videoObj: VideoView = null;

    video: MediaStream = undefined;
    audio: MediaStream = undefined;
    overlay: MediaStream = undefined;
    canvasStream: MediaStream = undefined;  // for recording blurred videos

    videoMediaRecorder: MediaRecorder = undefined;
    audioMediaRecorder: MediaRecorder = undefined;
    overlayMediaRecorder: MediaRecorder = undefined;

    videoRecordedBlobs: Array<Blob> = [];
    audioRecordedBlobs: Array<Blob> = [];
    overlayRecordedBlobs: Array<Blob> = [];

    videoProfileOptions: VideoProfile[] = [];

    message: string = "";
    count_down_number: number = 0;

    cameras: MediaDeviceInfo[] = [];
    mics: MediaDeviceInfo[] = [];
    video_source: string = 'upload';
    camera_source: string = '';  // default camera
    mic_source: string = '';  // default mic
    webcam_position: string = 'br'; // default webcam position
    webcam_overlay_style: string = 'circle'; // rectangle, circle, cutout (for both videos)
    webcam_style: string = 'rectangle'; // rectangle, cutout, blur (for webcam videos)

    disable_medium_quality: boolean = false;
    disable_high_quality: boolean = false;

    medium_quality_tooltip: string = '';
    high_quality_tooltip: string = '';
    disabled_quality_tooltip: string = "";

    show_reset_button: boolean = false;
    show_pause_button: boolean = false;
    show_record_button: boolean = false;
    show_upload_button: boolean = false;
    show_finish_button: boolean = false;
    show_mic_selection: boolean = false;
    show_cam_selection: boolean = false;
    show_type_selection: boolean = false;
    show_media_table: boolean = true;

    // all the sources are disabled by default
    disable_screen_source: boolean = true;
    disable_mic_source: boolean = true;
    disable_camera_source: boolean = true;

    isTeamsBrowser: boolean = false;
    isTrelloDesktop: boolean = false;
    isSafariBrowser: boolean = false;

    inProgress: boolean = false;
    progress: number = 0;
    in_progress_text: string = 'Joining sequences';

    formData: FormData = null;
    is_long_video: boolean = false;  // true when timer == 180 seconds or media took 600 seconds to process
    is_entire_screen_shared: boolean = false;
    long_video_title: string = '';
    long_video_subtitle: string = '';
    show_media_progress: boolean = false;

    video_media_recorder_options: MediaRecorderOptions = {};
    audio_media_recorder_options: MediaRecorderOptions = {};
    overlay_media_recorder_options: MediaRecorderOptions = {};

    // https://w3c.github.io/mediacapture-main/getusermedia.html#media-track-constraints
    sc_constraints: DisplayMediaStreamConstraints = {};
    sc_constraints_safari: DisplayMediaStreamConstraints = {};
    video_constraints: MediaStreamConstraints = {};
    audio_constraints: MediaStreamConstraints = {};
    overlay_constraints: MediaStreamConstraints = {};

    medias: Media[] = [];
    pending_media_count: number = 0;

    maxMinutesLabel: HTMLElement;
    maxSecondsLabel: HTMLElement;
    currentMinutesLabel: HTMLElement;
    currentSecondsLabel: HTMLElement;

    recordedSeconds: number = 0;  // to keep track of currently recorded seconds
    max_video_duration_in_seconds: number = 0;  // to stop the timer after this time
    max_video_size_bytes: number = 0;
    timerIntervalId: NodeJS.Timeout;  // to keep track of timers
    mediaTimeoutId = null;  // to keep track if any media is still being processed

    video_notes: string = "";
    old_video_notes: string = "";

    video_title: string = "";
    old_video_title: string = "";

    total_columns = 4;
    video_col_span = 2;
    video_row_span = 5; // desktop mode
    notes_row_span = 4; // desktop mode only, used to expand collapse notes
    record_button_row_span = 4; // desktop mode 4, mobile mode 3
    mode_selector_col_span = 1;
    show_notes = true;
    text_area_rows = 7; // change when notes are expanded

    audioHigh = new Audio();
    audioLow = new Audio();

    custom_bg_url: string = "https://dev.zesavi.com/api/media/default_bg.png";
    overlay_logo: string = "https://dev.zesavi.com/api/media/default_overlay.png";
    overlay_code: string = 'br';
    fileDragged: boolean = false;

    firstHTMLVideoElement: HTMLVideoElement = null;  // base video
    secondHTMLVideoElement: HTMLVideoElement = null;  // overlay video
    firstHTMLVideoElementHeight: number = 0;  // used to load the canvas and the video player
    firstHTMLVideoElementWidth: number = 0;
    firstHTMLVideoElementClientHeight: number = 0;  // used to calculate canvas scaling
    performBlurInference: boolean = false;
    canvasOutput: HTMLCanvasElement = null;
    recordingDivWidth: number = 100;  // this is used to ensure title is as wide as recording-div

    initial_value_for_ci_selector: number | null = 0;  // 0 is for default
    selfieSegmentation: SelfieSegmentation = null;
    camera_object: Camera = null;

    constructor(public dataService: DataService,
                private router: Router,
                public translateService: TranslateService,
                private dialog: MatDialog,
                public authService: AuthService,
                private snackBar: MatSnackBar) {

        this.max_video_duration_in_seconds = this.authService.company.max_video_duration_in_seconds;
        this.max_video_size_bytes = this.authService.company.max_video_size_bytes;

        this.video_constraints = {video: {height: 1080, width: 1920, frameRate: 60}, audio: false};
        this.audio_constraints = {
            video: false, audio: {
                echoCancellation: true, noiseSuppression: true, autoGainControl: true, channelCount: 1,
                sampleRate: 48000, // CD quality is 44100
                sampleSize: 16
            }
        };
        // aspectRatio is not working in this case, it is 4:3 for the given data
        this.overlay_constraints = {video: {height: 240, width: 320, frameRate: 30, aspectRatio: 1}, audio: false};

        this.sc_constraints = {
            audio: true,
            video: {
                height: 1080,
                frameRate: 30,
                suppressLocalAudioPlayback: true,
            }
        }
        this.sc_constraints.video['displaySurface'] = 'browser';

        // we need to create simple constrains, because safari does not support height width framerate etc.
        // audio is off in safari, making it true does not record anything anyway
        this.sc_constraints_safari = {audio: false, video: true};

        this.video_media_recorder_options = {
            videoBitsPerSecond: 10000000,  // 5 mbps, medium quality
            // mimeType: 'video/webm;codecs=vp9'
        }

        this.audio_media_recorder_options = {
            audioBitsPerSecond: 128000,  // 128 kbps
            // mimeType: 'audio/aac'
        }

        this.overlay_media_recorder_options = {
            videoBitsPerSecond: 2000000,  // 2 mbps, low quality
            // mimeType: 'video/webm;codecs=vp9'
        }

        this.disabled_quality_tooltip = this.translateService.instant('You must delete all medias before changing the recording quality');

        this.in_progress_text = this.translateService.instant("Joining sequences");

        if (localStorage.getItem('video_source')) {
            this.video_source = localStorage.getItem('video_source');
        }

        // since BG image & logo may expire, we should reload company data
        this.authService.updateCompanyData();
    }


    // https://stackoverflow.com/questions/5517597/plain-count-up-timer-in-javascript
    // method to update time
    updateTime() {
        // method to maintain timer
        this.recordedSeconds += 1;
        this.currentMinutesLabel.innerHTML = this.pad(this.recordedSeconds / 60);
        this.currentSecondsLabel.innerHTML = this.pad(this.recordedSeconds % 60);

        // hard limit: pause the recording after this time
        if (this.recordedSeconds > this.max_video_duration_in_seconds) {
            this.pause();
        }

        // 2 minutes timer
        if (this.recordedSeconds == 120) {
            this.currentMinutesLabel.style.color = 'orange';
            this.currentSecondsLabel.style.color = 'orange';
            document.getElementById('colon').style.color = 'orange';
        }

        // 3 minutes notification
        if (this.recordedSeconds == 180) {
            this.is_long_video = true;
            this.long_video_title = this.translateService.instant("Please keep it short");
            this.long_video_subtitle = this.translateService.instant("Viewers are less likely to watch a video all way through if it's long");
            this.currentMinutesLabel.style.color = 'red';
            this.currentSecondsLabel.style.color = 'red';
            document.getElementById('colon').style.color = 'red';
        }
    }

    // method to maintain timer
    pad(value) {
        value = Math.floor(value);
        let valString = value + "";
        if (valString.length < 2) {
            return "0" + valString;
        } else {
            return valString;
        }
    }

    ngOnDestroy() {
        clearTimeout(this.mediaTimeoutId);
        this.releaseSC();
    }

    qualityChanged(quality: string) {
        if (quality == '720') {
            this.overlay_media_recorder_options.videoBitsPerSecond = 1000000;
            this.audio_media_recorder_options.audioBitsPerSecond = 128000;
            this.video_media_recorder_options.videoBitsPerSecond = 2000000;
            this.sc_constraints.video['height'] = 720;
            this.sc_constraints.video['frameRate'] = 30;
            this.video_constraints.video['height'] = 720;
            this.video_constraints.video['width'] = 1280;
            this.video_constraints.video['frameRate'] = 30;
            this.overlay_constraints.video['height'] = 120;
            this.overlay_constraints.video['width'] = 160;
            this.overlay_constraints.video['frameRate'] = 30;
        } else if (quality == '1080') {
            this.overlay_media_recorder_options.videoBitsPerSecond = 2000000;
            this.audio_media_recorder_options.audioBitsPerSecond = 256000;
            this.video_media_recorder_options.videoBitsPerSecond = 5000000;
            this.sc_constraints.video['height'] = 1080;
            this.sc_constraints.video['frameRate'] = 30;
            // todo: test resolution settings
            this.video_constraints.video['height'] = 1080;
            this.video_constraints.video['width'] = 1920;
            this.video_constraints.video['frameRate'] = 30;
            this.overlay_constraints.video['height'] = 240;
            this.overlay_constraints.video['width'] = 320;
            this.overlay_constraints.video['frameRate'] = 30;
            // switch webcam mode to normal
            this.resetWebcamStyle();
        } else {
            // quality = 1440
            this.overlay_media_recorder_options.videoBitsPerSecond = 3000000;
            this.audio_media_recorder_options.audioBitsPerSecond = 320000;
            this.video_media_recorder_options.videoBitsPerSecond = 10000000;
            this.sc_constraints.video['height'] = 1440;
            this.sc_constraints.video['frameRate'] = 60;
            // todo: test
            this.video_constraints.video['height'] = 1440;
            this.video_constraints.video['width'] = 2560;
            this.video_constraints.video['frameRate'] = 60;
            this.overlay_constraints.video['height'] = 480;
            this.overlay_constraints.video['width'] = 640;
            this.overlay_constraints.video['frameRate'] = 60;
            // switch webcam mode to normal
            this.resetWebcamStyle();
        }
    }

    sourceChanged(source, mic_device_id, camera_device_id) {
        this.video_source = source;
        // save it for prev settings
        localStorage.setItem('video_source', this.video_source);

        if (this.disable_screen_source && this.disable_mic_source && source != 'upload') {
            // since both sources are not possible, move to upload mode
            this.sourceChanged('upload', mic_device_id, camera_device_id);
            return;
        }

        if (source == 'upload') {
            this.show_record_button = false;
            this.show_upload_button = true;
            this.show_cam_selection = false;
            this.show_mic_selection = false;
            this.message = this.translateService.instant("Click on the record button to upload or drag & drop files here");
        } else {
            this.show_record_button = true;
            this.show_upload_button = false;
            this.show_cam_selection = true;
            this.show_mic_selection = true;
            this.message = this.translateService.instant("Make yourself comfortable and start recording");
        }

        if (source == 'mic' || source == 'screen') {
            this.show_cam_selection = false;
        }

        this.mic_source = mic_device_id;
        this.camera_source = camera_device_id;

        if (mic_device_id) {
            this.audio_constraints.audio['deviceId'] = mic_device_id;
            // this.audio_constraints = {audio: {deviceId: mic_device_id}, video: false};
        }

        if (camera_device_id) {
            this.video_constraints.video['deviceId'] = camera_device_id;
            this.overlay_constraints.video['deviceId'] = camera_device_id;
            // this.video_constraints = {video: {deviceId: camera_device_id}, audio: false};
        }
    }

    // make the webcam style default
    resetWebcamStyle() {
        if (this.webcam_style != 'rectangle') {
            this.webcam_style = 'rectangle';
            this.updateWebcamStyle();
        }
    }

    ngOnInit(): void {
        if (navigator.userAgent.indexOf('Teams') > -1) {
            this.isTeamsBrowser = true;
        }

        if (navigator.userAgent.indexOf('TrelloDesktop') > -1) {
            this.isTrelloDesktop = true;
        }

        if (navigator.userAgent.indexOf('Chrome') == -1 && navigator.userAgent.indexOf('Firefox') == -1) {
            // safari_browser
            this.isSafariBrowser = true;
        }

        this.audioHigh.src = '../../assets/Mini_Button_01.wav';
        this.audioHigh.load();
        this.audioLow.src = '../../assets/Mini_Button_02.wav';
        this.audioLow.load();

        this.gotDevices();  // check for devices in async manner

        // in case user connects a new device, subscribe to an event
        navigator.mediaDevices.ondevicechange = (event) => {
            this.gotDevices();  // do not wait for this too
        };

        this.show_record_button = true;  // we allow user to record media, even if there is a video file
        this.show_cam_selection = true;
        this.show_mic_selection = true;
        this.show_type_selection = true;

        // load all previous medias
        this.loadMedias();

        // we do not need to worry about source changed function calls here
        // previous value from local storage is respected in desktop mode
        // previous value is however not respected in other cases like iframe, Trello, mobile phone, etc.
        if ((navigator.mediaDevices && 'getDisplayMedia' in navigator.mediaDevices)) {
            // there is a screen access: desktop mode
            this.disable_screen_source = false;
            // this line is needed to make sure that at source-changed function is called at least once
            this.sourceChanged(this.video_source, this.mic_source, this.camera_source);
            // above value is taken from local storage, if any, else 'upload'
        } else {
            // there is no screen access, but maybe a camera access: smartphone mode
            this.sourceChanged('upload', this.mic_source, this.camera_source);
            this.message = this.translateService.instant("Screen sharing is not supported on this device.");
        }

        if (this.isTrelloDesktop) {
            // in case of trello app, screen permission is always blocked
            this.disable_screen_source = true;
            this.sourceChanged('upload', this.mic_source, this.camera_source);
            this.message = this.translateService.instant("Screen sharing is not supported in Trello desktop.");
            this.message += " " + this.translateService.instant("Visit https://clypp.app/ to record your screen or choose a different recording mode.")
        }

        if (inIframe()) {
            // in case of any iframe, screen sharing is not supported, unless: allow="camera; microphone; display-capture"
            this.disable_screen_source = true;
            this.sourceChanged('upload', this.mic_source, this.camera_source);
            this.message = this.translateService.instant("Screen recordings are not supported in iFrame.");
            this.message += " " + this.translateService.instant("Visit https://clypp.app/ to record your screen or choose a different recording mode.")
        }

        this.qualityChanged(this.videoObj.project_settings);

        switch (this.authService.company.max_upload_video_height) {
            case '720':
                // disable medium and high quality
                this.disable_high_quality = true;
                this.disable_medium_quality = true;
                this.medium_quality_tooltip = this.translateService.instant('Upgrade to use this feature');
                this.high_quality_tooltip = this.translateService.instant('Upgrade to use this feature');
                break;
            case '1080':
                // disable high quality
                this.disable_high_quality = true;
                this.high_quality_tooltip = this.translateService.instant('Upgrade to use this feature');
                break;
            case '1440':
                // do nothing, all qualities are enabled
                break;
            default:
                // quality is probably 4k or 480p
                break;
        }

        // initialise the timer
        this.currentMinutesLabel = document.getElementById('currentMinutes');
        this.currentSecondsLabel = document.getElementById('currentSeconds');
        this.maxMinutesLabel = document.getElementById('maxMinutes');
        this.maxSecondsLabel = document.getElementById('maxSeconds');
        this.maxMinutesLabel.innerHTML = this.pad(this.max_video_duration_in_seconds / 60);
        this.maxSecondsLabel.innerHTML = this.pad(this.max_video_duration_in_seconds % 60);

        this.old_video_notes = this.videoObj['script'];
        this.video_notes = this.old_video_notes;

        this.old_video_title = this.videoObj['title'];
        this.video_title = this.old_video_title;

        // add an event listener to make sure that uploading or recording is not interrupted
        window.addEventListener('beforeunload', (event) => {
            this.saveNotes();  // save notes each time user reloads
            clearTimeout(this.mediaTimeoutId);
            if (this.show_pause_button || this.pending_media_count) {
                event.returnValue = "Sure?";
            }
        });

        this.loadVideoProfiles();
    }

    ngAfterViewInit(): void {
        this.windowResized();
        this.resetWebcamStyle();
    }

    // on init, load all the medias for this video
    loadMedias() {
      this.show_media_progress = true;
      this.dataService.getURL<Media[]>(`user/videos/${this.videoObj.id}/medias/`).subscribe((res) => {
        this.show_media_progress = false;
        this.medias = res;
        if (this.medias.length) {
          this.showFinishButton();
          this.show_reset_button = true;
          // todo: if there is any media which is broken, initiate an auto refresher.
          const any_media_without_processed_file = this.medias.findIndex(media => media.processed_file == null);
          if (any_media_without_processed_file > -1) {
            // start an interval of 1 minute
            this.mediaTimeoutId = setTimeout(() => this.loadMedias(), 60 * 1000);
          }
        }
      }, (err) => {
        window.alert(err.error);
        this.show_media_progress = false;
      });
    }

    loadVideoProfiles() {
        // todo: only load it for pro+ users
        this.dataService.getURL('video/profiles/').subscribe((res: VideoProfile[]) => {
            this.videoProfileOptions = res;
            // after loading data, update the preview window to existing profile
            this.updatePreviewImages();
            // update the first value of mat-select
            if (this.videoObj.ci_profile) {
                // not null, change default value
                this.initial_value_for_ci_selector = this.videoObj.ci_profile;
            }
        }, (err) => {
            this.handleError(err);
            this.snackBar.open("Failed to load video profiles", '', {duration: 2500});
        });
    }

    reset() {
        // on confirm
        let message1 = this.translateService.instant('This will delete all the recorded sequences.');
        let message2 = this.translateService.instant('Bist du sicher?');
        if (window.confirm(message1 + '\n' + message2)) {
            this.progress = 0;
            // send reset call
            this.dataService.postURL(`user/videos/${this.videoObj.id}/`, {'action': 'reset'}).subscribe(
                (res) => {
                    this.show_reset_button = false;
                    this.show_pause_button = false;
                    this.show_cam_selection = true;
                    this.show_mic_selection = true;
                    this.show_type_selection = true;
                    this.show_finish_button = false;
                    this.message = this.translateService.instant("All recording sequences are deleted successfully");
                    this.medias = [];
                    this.sourceChanged(this.video_source, this.mic_source, this.camera_source);
                },
                (err) => {
                    window.alert(err.error);
                }
            );

            // clear the timer
            this.recordedSeconds = -1;
            this.updateTime();
        }
    }

    // called when file is selected from the <input>
    fileSelected(event) {
        // accept=".mp4,.mov,.m4v,.webm,.jpg,.png,.gif,.webp,.pdf,.mkv,.mts,.mp3,.m4a,.wav"
        let video_file: File = event;
        if (event.name === undefined) {
            video_file = document.querySelector('input').files[0];
            event.target.value = '';
        }

        if (video_file.size > this.max_video_size_bytes) {
            this.message = this.translateService.instant("Limit Exceeded: ") +
                `${Math.floor(video_file.size / 1000000)} MB / ${this.max_video_size_bytes / 1000000} MB`;
            return;
        }

        // check for file ext
        const video_file_type = video_file.type;
        if (video_file_type.includes('image')) {
          // it must be jpg, png, or gif
          if (video_file.name.endsWith('jpg') || video_file.name.endsWith('png') || video_file.name.endsWith('gif') || video_file.name.endsWith('webp')) {
            // ok
          } else {
            this.snackBar.open(this.translateService.instant('File not supported'), '', {duration: 2000});
            return;
          }
        }

        let image_duration: string = '30';
        let formData = new FormData();
        // take the last 99 characters of file name, because backend limit is 100 characters
        let file_name = video_file.name.slice(-99);
        // add the data to the form
        formData.append("video_file", video_file, file_name);
        formData.append("name", file_name.slice(-49));  // send the file name for improved experience

        if (video_file_type.includes('pdf') || video_file_type.includes('image')) {
          // if user uploads an image or pdf, first ask if they want to use AI services, then ask for media duration
          if (this.authService.company.is_transcription_service_enabled) {
            // ask if they want a summary
            let message = this.translateService.instant("Would you like to automatically create a voice over?")
            if (window.confirm(message)) {
              // summarize
              this.attachFileForScriptPrediction(video_file);
              return;
            } // else, let user continue
          }
          if (!(inIframe() || this.isTeamsBrowser)) {
            // user is neither in iframe, nor in teams browser
            let message = this.translateService.instant("Select the duration of your image (1 - 60 seconds):");
            if (video_file_type.includes('pdf')) {
              message = this.translateService.instant("Select the duration per page (1 - 60 seconds):");
            }
            image_duration = window.prompt(message, "30");
            if (image_duration == null) {
              // user pressed cancel
              return;
            }
          } // else, user is in an iframe
        } else if (video_file_type.includes('video')) {
          // ok
        } else if (video_file.name.toLowerCase().endsWith('.mkv') || video_file.name.toLowerCase().endsWith('.mts')) {
          // ok, mts and mkv videos are supported
        } else if (video_file_type.includes('audio')) {
          // append as audio_file
          formData.append("audio_file", video_file, file_name);
          // remove video_file
          formData.delete('video_file');
        } else {
          // only videos, images and pdfs are supported
          this.snackBar.open(this.translateService.instant('File not supported'), '', {duration: 2000});
          return;
        }

        if (parseInt(image_duration)) {
          // user entered a number
          formData.append("image_duration", parseInt(image_duration).toString());
        } // other default will be taken from BE

        // upload recorded data
        let current_progress = 0;  // to keep track of local progress
        let prev_progress = 0;
        this.pending_media_count += 1;
        // start a timer of 10 minutes, if processing does not finish in 10 minutes, then show overlay.
        const myInterval = this.startMediaProcessingTimeout();
        this.dataService.postURL<Media>(`user/videos/${this.videoObj.id}/`, formData, {
            responseType: 'json',
            reportProgress: true,
            observe: 'events'
        }).subscribe(
            (response) => {
                switch (response['type']) {
                    case HttpEventType.UploadProgress:
                        current_progress = Math.round(response['loaded'] / response['total'] * 100);
                        this.progress += current_progress - prev_progress;
                        prev_progress = current_progress;
                        break;
                  case HttpEventType.Response:
                        this.pending_media_count -= 1;
                        this.progress -= 100;
                        // this.message = this.translateService.instant("Uploaded");
                        if (video_file_type.includes('pdf')) {
                          // check in case of pdf, reload medias table
                          this.loadMedias();
                        } else {
                          // in case of image or video, push response
                          this.medias.push(response['body']);
                          this.showFinishButton();
                          this.show_reset_button = true;
                        }
                        clearInterval(myInterval);  // clear the interval for long media processing
                        this.dismissMessage();  // also dismiss message if user has not already dismissed it
                        break;
                }
            },
            (err) => {
                this.pending_media_count -= 1;
                this.progress -= 100;
                this.message = this.translateService.instant("Failed");
            }
        );
    }

    async pause() {
        // stop the timer
        clearTimeout(this.timerIntervalId);

        // disable the button
        let pause_button = document.getElementById('pause-button');
        pause_button.setAttribute('disabled', '');

        // free up media
        if (this.audioMediaRecorder) {
            if (this.audioMediaRecorder.state == 'recording') {
                this.audioMediaRecorder.stop();
            }
        }

        if (this.videoMediaRecorder) {
            if (this.videoMediaRecorder.state == 'recording') {
                this.videoMediaRecorder.stop();
            }
        }

        if (this.overlayMediaRecorder) {
            if (this.overlayMediaRecorder.state == 'recording') {
                this.overlayMediaRecorder.stop();
            }
        }

        this.stopTracks();
        this.audioLow.play();

        // clear the timer
        this.recordedSeconds = -1;
        this.updateTime();

        this.is_long_video = false;
        this.is_entire_screen_shared = false;

        this.currentMinutesLabel.style.color = 'gray';
        this.currentSecondsLabel.style.color = 'gray';
        document.getElementById('colon').style.color = 'gray';
        // upload recorded data
        let current_progress = 0;  // to keep track of local progress
        let prev_progress = 0;
        this.pending_media_count += 1;
        const myInterval = this.startMediaProcessingTimeout();
        // wait for a second for the form data to be ready
        await delay(1000);

        // create a copy of form data
        let localFormData: FormData = this.formData;
        // append the webcam position (ignored if not needed)
        localFormData.append("wc_pos", this.webcam_position);
        // append the webcam overlay style (ignored if not needed)
        localFormData.append("webcam_overlay_style", this.webcam_overlay_style);
        // append the webcam style (ignored if not needed)
        // todo: remove from FE and BE
        // localFormData.append("webcam_style", this.webcam_style);
        this.releaseSC();
        this.dataService.postURL<Media>(`user/videos/${this.videoObj.id}/`, localFormData,
            {
                responseType: 'json',
                reportProgress: true,
                observe: 'events'
            }
        ).subscribe((response) => {
                switch (response['type']) {
                    case HttpEventType.UploadProgress:
                        current_progress = Math.round(response['loaded'] / response['total'] * 100);
                        this.progress += current_progress - prev_progress;
                        prev_progress = current_progress;
                        break;
                    case HttpEventType.Response:
                        // console.log("response",response)
                        this.pending_media_count -= 1;
                        this.progress -= 100;
                        // this.message = this.translateService.instant("Uploaded");
                        this.medias.push(response['body']);
                        this.showFinishButton();
                        clearInterval(myInterval);  // clear the interval for long media processing
                        this.dismissMessage();  // also dismiss message if user has not already dismissed it
                        break;
                }
            },
            (err) => {
                this.handleError(err);
                this.pending_media_count -= 1;
                this.progress -= 100;
                this.download(localFormData);
                this.message = this.translateService.instant("Failed");
            }
        );

        this.show_record_button = true;
        this.show_reset_button = true;
        this.show_pause_button = false;
        this.show_cam_selection = true;
        this.show_mic_selection = true;
        this.show_type_selection = true;
        this.showFinishButton();
        // enable pause button
        pause_button.removeAttribute('disabled');
    }

    // this method keeps track of when the media processing started
    // if it takes over 10 minutes, then an overlay is shown
    startMediaProcessingTimeout(): NodeJS.Timeout {
        const myInterval = setInterval(() => {
            // show message
            this.is_long_video = true;
            this.long_video_title = this.translateService.instant("Your file is taking longer than expected.");
            this.long_video_subtitle = this.translateService.instant("We will inform you via email once processing has finished.");
            clearInterval(myInterval);  // clear it, so that it won't show again
        }, 600000); // check every 600 seconds
        return myInterval;
    }

    async record() {
        // ask for screen or camera access and mic access
        // start recording upon access is given
        // start timer
        this.message = "";
        this.formData = new FormData();
        // this.progress = 0;
        await this.onSelectScreen().catch((e) => {
            // user denied camera or mic
            console.error(e);
            this.message = this.translateService.instant("Access denied");
        });
        if (this.video || this.audio) {
            // video, overlay and audio must be present in BOTH mode
            if (this.video_source == 'both') {
                if (!(this.overlay && this.audio && this.video)) {
                    // case if webcam or mic are blocked but the user selected BOTH mode
                    this.stopTracks();
                    this.message = this.translateService.instant("Access denied");
                    return;
                }
            }
            // both video and audio must be present in case of webcam
            else if (this.video_source == 'camera') {
                if (!(this.video && this.audio)) {
                    // case if user allows mic but blocks webcam or vice versa
                    this.stopTracks();
                    return;
                }
            }
            // at least video must be present in case of screencast
            else if (this.video_source == 'screen') {
                if (!this.video) {
                    // case if user allows mic but cancels screen selection
                    this.stopTracks();
                    return;
                }
            }
            this.show_record_button = false;
            this.show_reset_button = false;
            this.show_cam_selection = false;
            this.show_mic_selection = false;
            this.show_type_selection = false;
            this.show_finish_button = false;
            await this.onStartRecording();
            this.show_pause_button = true;
            // start the timer
            this.recordedSeconds = 0;
            this.timerIntervalId = setInterval(() => this.updateTime(), 1000);
        } else {
            // user denied screen access
            this.message = this.translateService.instant("Access denied");
        }
    }

    needHelp() {
        window.open('https://clypp.notion.site/Clypp-Help-Center-f62c4f8fd6cc42638677998a2e18913d', '_blank');
    }

    finish(skip_editing = false) {
        // send the finish call
        this.inProgress = true;
        this.in_progress_text = this.translateService.instant("Joining sequences");
        this.progress = 0;

        this.saveNotes();
        this.dataService.postURL(`user/videos/${this.videoObj.id}/`, {'action': 'end'}).subscribe(
            (res: VideoView) => {
                this.inProgress = false;

                if (skip_editing) {
                    // post process video and navigate to review page
                    this.dataService.postURL(`user/videos/${this.videoObj.id}/medias/`, res.edit_parameters)
                        .subscribe((res) => {
                            // processing will only start if edit parameters are changed or video is not in CO state
                            this.router.navigate(['create-video', this.videoObj.id, 'review']);
                        }, (err) => {
                            // failed to send post call, possible options could be 401, 404 or 500. All comes with a message.
                            this.handleError(err);
                            // take user to edit page
                            this.router.navigate(['create-video', this.videoObj.id, 'trim']);
                        });
                } else {
                    // navigate to edit page
                    this.router.navigate(['create-video', this.videoObj.id, 'trim']);
                }
            },
            (err) => {
                this.inProgress = false;
                window.alert(err.error);
            });
        // need not update the buttons
    }

    // forcefully delete the video upon user confirmation and go back
    deleteVideo() {
      // button is disabled if video is being recorded
      // do not ask for confirmation, if there is no media and none processing
      let confirmed: boolean = (this.medias.length == 0) && (this.pending_media_count == 0);
      if (!confirmed){
        // there is data which would be deleted
        const message = this.translateService.instant('Dein Video wird unwiederbringlich gelöscht. Fortfahren?');
        confirmed = window.confirm(message);
      }
      // now delete video
      if (confirmed) {
        this.dataService.deleteURL(`user/videos/${this.videoObj.id}/`).subscribe((res) => {
          // go back to correct page
          this.snackBar.open(this.translateService.instant('Erfolgreich gelöscht'), '', {duration: 2000});
          this.goBack();
        });
      }
    }

    // save notes, delete video, go back
    back() {
        // check if recording or uploading
        let go_back: boolean = true;

        if (this.show_pause_button || this.pending_media_count) {
            const message = this.translateService.instant("Bist du sicher?");
            if (window.confirm(message)) {
                // now user can go back
            } else {
                go_back = false;
            }
        }

        if (go_back) {
            // send delete action after saving notes
            // because, presence of notes would not delete a video
            this.dataService.postURL(`user/videos/${this.videoObj.id}/`, {'action': 'delete'}).subscribe(() => {
                // redirect to homepage
                this.goBack();
            });
        }
    }

    goBack() {
      if (this.isTeamsBrowser) {
        // we ignore the Teams case when opened in browser, just like we ignore it for my profile
        this.router.navigate(['internal']);
      } else {
        // go to start page if normal browser or iframe
        this.router.navigate(['start']);
      }
    }

    handleError(error) {
        console.error(error);
        // this.message = error.name;
    }

    // this function is called when user allows the cam and mic permission popup
    // this function is also called when user connects a new mic or camera
    async gotDevices() {
        navigator.mediaDevices.enumerateDevices().then((deviceInfos) => {
            // clear the array, because this function may be called again
            this.mics = [];
            this.cameras = [];

            for (let device of deviceInfos) {
                if (device['kind'] == 'audioinput') {
                    this.mics.push(device);
                } else if (device['kind'] == 'videoinput') {
                    this.cameras.push(device);
                }
            }

            // initiate the first variable
            if (this.cameras.length) {
                this.camera_source = this.cameras[0]['deviceId'];
                this.disable_camera_source = false;
            } else {
                // may come here when last remaining camera is removed
                this.disable_camera_source = true;
            }

            // initiate the first variable
            if (this.mics.length) {
                this.mic_source = this.mics[0]['deviceId'];
                this.disable_mic_source = false;
            } else {
                // if there is no mic, then there is definitely no camera
                // may also come here if last remaining mic is removed
                // change the source to upload
                this.disable_mic_source = true;
            }

            // no need to change source if this method is called for the first time
            // do not change source here when a new device is connected
            // if the active camera is removed while recording, then it stops the recording
            // if the active mic is removed, then it does not stop: fixed below
            if (this.audio) {
                // pause recording, because audio a device is added/removed
                const currentMicId = this.audio_constraints.audio['deviceId'];
                const currentMicIndex = this.mics.findIndex(el => el.deviceId == currentMicId);
                if (currentMicIndex == -1) {
                    // the mic being used for recording is now removed
                    this.pause();
                }
            }
        }, (err) => {
            // failed to enumerate device
            console.log(err);
        });
    }

    onSelectScreen = async () => {
        // default: use chrome/firefox options
        let display_media_options: DisplayMediaStreamConstraints = this.sc_constraints;
        if (this.isSafariBrowser) {
            // safari browser, use simple constraints
            display_media_options = this.sc_constraints_safari;
        }

        // update order for safari browser: mic must come last, otherwise, screen request is not called from user
        if (!this.isSafariBrowser) {
            // get mic access first when not in safari browser
            await navigator.mediaDevices.getUserMedia(this.audio_constraints).then((audioStream) => {
                this.audio = audioStream;
            }, (error) => {
                this.audio = null;
            });
        }

        try {
            if (this.video_source == 'screen') {
                this.video = await navigator.mediaDevices.getDisplayMedia(display_media_options);
            } else if (this.video_source == 'camera') {
                this.video = await navigator.mediaDevices.getUserMedia(this.video_constraints);
            } else if (this.video_source == 'both') {
                // get screen first in Safari browsers
                if (this.isSafariBrowser) {
                    this.video = await navigator.mediaDevices.getDisplayMedia(display_media_options);
                    this.overlay = await navigator.mediaDevices.getUserMedia(this.overlay_constraints);
                } else {
                    // get camera first, otherwise user may forget to allow webcam when accessing first time
                    this.overlay = await navigator.mediaDevices.getUserMedia(this.overlay_constraints);
                    this.video = await navigator.mediaDevices.getDisplayMedia(display_media_options);
                }
            } else if (this.video_source == 'mic') {
                // ignore, the mic access is given further
            } else {
                // upload case, no check needed
                return;
            }
        } catch (e) {
            throw (e);
        }

        if (this.isSafariBrowser) {
            // get mic access last when in safari browser
            await navigator.mediaDevices.getUserMedia(this.audio_constraints).then((audioStream) => {
                this.audio = audioStream;
            }, (error) => {
                this.audio = null;
            });
        }

        // display on video element
        if (this.video) {
            this.firstHTMLVideoElement = document.getElementsByTagName('video')[0];
            this.firstHTMLVideoElement.srcObject = this.video;
            this.video.getVideoTracks()[0].addEventListener('ended', () => {
                if (this.videoMediaRecorder.state == 'recording') {
                    this.pause();
                }
            });
            // check if entire screen is shared
            if (this.video.getVideoTracks()[0].getSettings()['displaySurface'] == 'monitor') {
                this.is_entire_screen_shared = true;
            }
            // after the source is set, load the bodyPix model on canvas in case of camera
            if (this.video_source == 'camera' && this.webcam_style != 'rectangle') {
                // wait for the video to play first
                await this.firstHTMLVideoElement.play();
                this.firstHTMLVideoElementHeight = this.firstHTMLVideoElement.videoHeight;
                this.firstHTMLVideoElementWidth = this.firstHTMLVideoElement.videoWidth;
                this.firstHTMLVideoElementClientHeight = this.firstHTMLVideoElement.clientHeight;
                // then initiate blur/cutout, because we need the video's height and width
                // if (this.webcam_style.includes('mediapipe')) {
                //     this.loadMediaPipe();
                // } else {
                this.loadMediaPipe();
                // }
            }
        }
        if (this.overlay) {
            this.secondHTMLVideoElement = document.getElementsByTagName('video')[1];
            this.secondHTMLVideoElement.srcObject = this.overlay;
        }
    }

    async onStartRecording() {
        this.audioRecordedBlobs = [];
        this.videoRecordedBlobs = [];
        this.overlayRecordedBlobs = [];

        let audioMediaStream: MediaStream = null;
        let videoMediaStream: MediaStream = null;
        let overlayMediaStream: MediaStream = null;

        if (this.audio) {
            audioMediaStream = new MediaStream([...this.audio.getTracks()]);
            this.audioMediaRecorder = new MediaRecorder(audioMediaStream, this.audio_media_recorder_options);
            this.audioMediaRecorder.addEventListener('dataavailable', event => {
                if (event.data && event.data.size > 0) {
                    this.audioRecordedBlobs.push(event.data);
                    let blob = new Blob(this.audioRecordedBlobs, {type: 'audio/opus'});
                    this.formData.append('audio_file', blob, `${new Date().getTime()}.opus`);
                } else {
                    this.message = this.translateService.instant("Failed");
                }
            });
        }

        if (this.video) {

            // in case of blur/cutout, record from canvas
            if (this.webcam_style != 'rectangle') {
                // blur/cutout case, record the canvas
                videoMediaStream = new MediaStream([...this.canvasStream.getTracks()]);
                this.videoMediaRecorder = new MediaRecorder(videoMediaStream, this.video_media_recorder_options);
            } else {
                // not a blur webcam case, record from video element
                videoMediaStream = new MediaStream([...this.video.getTracks()]);
                this.videoMediaRecorder = new MediaRecorder(videoMediaStream, this.video_media_recorder_options);
            }

            this.videoMediaRecorder.addEventListener('dataavailable', event => {
                if (event.data && event.data.size > 0) {
                    this.videoRecordedBlobs.push(event.data);
                    let blob = new Blob(this.videoRecordedBlobs, {type: 'video/webm'});
                    this.formData.append('video_file', blob, `${new Date().getTime()}.webm`);
                } else {
                    this.message = this.translateService.instant("Es wurde kein Video aufgenommen");
                }
            });
        }

        if (this.overlay) {
            overlayMediaStream = new MediaStream([...this.overlay.getTracks()]);
            this.overlayMediaRecorder = new MediaRecorder(overlayMediaStream, this.overlay_media_recorder_options);
            this.overlayMediaRecorder.addEventListener('dataavailable', event => {
                if (event.data && event.data.size > 0) {
                    this.overlayRecordedBlobs.push(event.data);
                    let blob = new Blob(this.overlayRecordedBlobs, {type: 'video/webm'});
                    this.formData.append('overlay_video', blob, `${new Date().getTime()}.webm`);
                } else {
                    this.message = this.translateService.instant("Es wurde kein Video aufgenommen");
                }
            });
        }

        await this.playDelay();

        // start recording
        if (this.audioMediaRecorder) {
            this.audioMediaRecorder.start();
        }
        if (this.videoMediaRecorder) {
            this.videoMediaRecorder.start();
        }
        if (this.overlayMediaRecorder) {
            this.overlayMediaRecorder.start();
        }
    }

    playDelay = async () => {
        this.count_down_number = 3;
        await delay(900);
        this.count_down_number = 2;
        await delay(800);
        this.count_down_number = 1;
        this.audioHigh.play();
        await delay(800);
        this.count_down_number = 0;
    }

    async stopTracks() {
        if (this.video) {
            this.video.getTracks().forEach(track => track.stop());
            this.video = undefined;
        }
        if (this.audio) {
            this.audio.getTracks().forEach(track => track.stop());
            this.audio = undefined;
        }
        if (this.overlay) {
            this.overlay.getTracks().forEach(track => track.stop());
            this.overlay = undefined;
        }
        if (this.canvasStream) {
            this.canvasStream.getTracks().forEach(track => track.stop());
            this.canvasStream = undefined;
        }

        // free the blur processing if activated
        if (this.performBlurInference) {
            this.performBlurInference = false;
            // todo: check how to offload/free/release BodyPix model
        }

        // offload selfie model
        if (this.selfieSegmentation) {
            this.performBlurInference = false;
            this.selfieSegmentation.close().then();
            delete this.selfieSegmentation;
            this.selfieSegmentation = null;
            this.camera_object.stop().then();
            delete this.camera_object;
            this.camera_object = null;
        }
    }

    releaseSC() {
        this.audioMediaRecorder = undefined;
        this.videoMediaRecorder = undefined;
        this.overlayMediaRecorder = undefined;

        this.overlayRecordedBlobs = [];
        this.audioRecordedBlobs = [];
        this.videoRecordedBlobs = [];

        this.stopTracks();

        delete this.formData;
    }

    download(localFormData) {
        if (localFormData.has('audio_file')) {
            const blob = localFormData.get('audio_file');
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = `${this.videoObj.id}_audio_file.opus`;
            document.body.appendChild(a);
            a.click();
            setTimeout(() => {
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
            }, 100);
        }

        if (localFormData.has('video_file')) {
            const blob = localFormData.get('video_file');
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = `${this.videoObj.id}_video_file.webm`;
            document.body.appendChild(a);
            a.click();
            setTimeout(() => {
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
            }, 100);
        }

        if (localFormData.has('overlay_video')) {
            const blob = localFormData.get('overlay_video');
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = `${this.videoObj.id}_overlay_video.webm`;
            document.body.appendChild(a);
            a.click();
            setTimeout(() => {
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
            }, 100);
        }
        // const blob = new Blob(this.videoRecordedBlobs, {type: 'video/webm'});
        // const url = window.URL.createObjectURL(blob);
        // const a = document.createElement('a');
        // a.style.display = 'none';
        // a.href = url;
        // a.download = 'test.webm';
        // document.body.appendChild(a);
        // a.click();
        // setTimeout(() => {
        //   document.body.removeChild(a);
        //   window.URL.revokeObjectURL(url);
        // }, 100);
    }

    playMedia(media) {
        let videoRes = {
            'processed_file': media.processed_file,
            'title': media.name,
            'vtt_file': null,
            'audio_language': '',
            'translations': []
        };

        this.dialog.open(MyVideosVideoPopupComponent, {
            // width: '1120px',
            height: '60%',
            disableClose: false,
            data: videoRes,
            panelClass: 'my-panel',
        });
    }

    deleteMedia(media: Media) {
        let message = this.translateService.instant("Deleting a recording sequence will reset all changes in edit page");
        if (window.confirm(message)) {
            // delete call
            this.dataService.deleteURL(`user/videos/${this.videoObj.id}/medias/?id=${media.id}`)
                .subscribe((res) => {
                    // remove from array
                    const index = this.medias.indexOf(media, 0);
                    if (index > -1) {
                        this.medias.splice(index, 1);
                    }
                    this.snackBar.open(this.translateService.instant('Erfolgreich'), '', {duration: 2500});
                    if (this.medias.length) {
                        // there are medias
                        this.showFinishButton();
                    } else {
                        // there is no media
                        this.show_finish_button = false;
                        this.show_reset_button = false;
                    }
                }, (err) => {
                    window.alert(err.error);
                });
        }
    }

    mediaSwapped(event: CdkDragDrop<Media[]>) {
        if (event.previousIndex == event.currentIndex) {
            return;
        }
        let message = this.translateService.instant("Switching the sequence order will reset all changes in edit page");
        if (window.confirm(message)) {
            let body = {
                action: 'move',
                previousIndex: event.previousIndex,
                currentIndex: event.currentIndex
            }
            moveItemInArray(this.medias, event.previousIndex, event.currentIndex);
            this.dataService.postURL(`user/videos/${this.videoObj.id}/`, body,
                {
                    observe: 'body',
                    responseType: 'text'
                }).subscribe(
                (res) => {
                    this.snackBar.open(this.translateService.instant('Erfolgreich'), '', {duration: 2500});
                },
                (err) => {
                    window.alert(err.error);
                }
            );
        }
    }

    // if media is processed, download the processed file, else, raw files
    downloadMediaFiles(media: Media) {
        if (media.processed_file) {
            downloadFromUrl(media.processed_file);
        } else {
            downloadFromUrl(media.video_file);
            downloadFromUrl(media.audio_file);
            downloadFromUrl(media.overlay_video);
        }
    }

    renameMedia(media: Media) {
        let message = this.translateService.instant("Please enter the new sequence name");
        let new_name = window.prompt(message, media.name);
        if (new_name) {
            new_name = new_name.substring(0, 49)
            let post_body = {
                action: 'rename',
                id: media.id,
                name: new_name
            }
            this.dataService.postURL(`user/videos/${this.videoObj.id}/`, post_body, {
                observe: 'body',
                responseType: 'text'
            }).subscribe(
                (res) => {
                    media.name = new_name;
                    this.snackBar.open(this.translateService.instant('Erfolgreich'), '', {duration: 2500});
                },
                (err) => {
                    window.alert(err.error);
                }
            );
        }
    }

    duplicateMedia(media_id: number) {
        // duplicates a particular media's processed file only.
        this.pending_media_count += 1;
        this.show_reset_button = false;
        this.progress += 100;
        const post_body = {
            action: 'duplicate',
            id: media_id,
        }
        this.dataService.postURL(`user/videos/${this.videoObj.id}/`, post_body, {
            observe: 'body',
            responseType: 'json'
        }).subscribe(
            (res: Media) => {
                // success
                this.pending_media_count -= 1;
                this.show_reset_button = true;
                this.progress -= 100;
                this.medias.push(res);
            },
            (err) => {
                this.pending_media_count -= 1;
                this.show_reset_button = true;
                this.progress -= 100;
                window.alert(err.error);
            }
        );

    }

    customMediaBG(media: Media, action: string = 'blur_bg') {
        // ask backend to blr the media's background
        // ask for confirmation first
        let message = '';

        let post_body = {
          action: action,
          id: media.id,
        }

        if (action == 're_process') {
          // show diff message in case of re-process
          // media must have at least video or audio file to be re-processed
          if (!(media.video_file || media.audio_file)) {
            // it has neither video nor audio file
            window.alert('A duplicated media can not be re-processed.');
            return;
          }
          // in case of images, ask for image duration
          if (media.video_file?.includes('.jpg') || media.video_file?.includes('.png')) {
            message = this.translateService.instant("Select the duration of your image (1 - 60 seconds):");
            const image_duration = window.prompt(message, "30");
            if (image_duration == null) {
              // user pressed cancel
              return;
            }
            if (isNaN(parseInt(image_duration))) {
              // user provided invalid input
              return;
            }
            post_body['image_duration'] = image_duration;
          }
          message = this.translateService.instant("This action will re-process the media with default " +
            "parameters and selected content branding.");
        } else {
          // blur_bg, custom_bg
          if (this.videoObj.project_settings == '1440') {
            // 4 times
            message = this.translateService.instant("This may take upto four times the media duration time.")
          } else {
            // 2 times
            message = this.translateService.instant("This may take upto twice the media duration time.")
          }
          message += '\n';
          message += this.translateService.instant("This action should only be performed with webcam videos.");
        }

        message += '\n\n';
        message += this.translateService.instant("Would you like to continue?");

        if (window.confirm(message)) {
            //
            this.pending_media_count += 1;
            const index = this.medias.indexOf(media, 0);
            this.show_reset_button = false;
            this.progress += 100;
            // hide medias, so that user could not make other changes
            this.show_media_table = false;
            this.dataService.postURL(`user/videos/${this.videoObj.id}/`, post_body, {
                observe: 'body',
                responseType: 'json'
            }).subscribe(
                (res: Media) => {
                    this.pending_media_count -= 1;
                    this.show_reset_button = true;
                    this.progress -= 100;
                    this.show_media_table = true;  // show medias
                    this.medias[index] = res;  // update media at that position
                },
                (err) => {
                    this.pending_media_count -= 1;
                    this.show_reset_button = true;
                    this.progress -= 100;
                    this.show_media_table = true;  // show medias
                    window.alert(err.error);
                }
            );
        }
    }

    saveNotes(send_delete_call: boolean = false) {
        // returns true if we must wait for a while
        // do not save notes if empty
        // title must not be empty => the call will return 400, hence we can safely ignore it
        if ((this.video_notes != this.old_video_notes) || (this.video_title != this.old_video_title)) {
          this.dataService.putURL(`user/videos/${this.videoObj.id}/`, {
            script: this.video_notes,
            title: this.video_title
          }).subscribe((res: VideoView) => {
            this.video_notes = this.old_video_notes = res.script;
            this.video_title = this.old_video_title = res.title;
          }, () => {
          }, () => {
            // user may want to go back
            if (send_delete_call) {
              this.back();
            }
          });
        } else {
          // user may want to go back
          if (send_delete_call) {
            this.back();
          }
        }
    }

    dismissMessage() {
        this.is_long_video = false;
    }


    // this method switches b/w different webcam styles
    updateWebcamStyle() {
        let image_element: HTMLImageElement = document.querySelector("#webcam-template-image");
        // change the image and local variable
        if (this.webcam_style == 'rectangle') {
            image_element.src = "assets/images/wc_template_rectangle.png";
        } else if (this.webcam_style.includes('blur')) {
            image_element.src = "assets/images/wc_template_blur.png";
        } else {
            this.webcam_style = 'cutout';
            image_element.src = "assets/images/wc_template_cutout_wide.png";
        }
    }


    // this method switches between different webcam overlay styles
    // todo: add 'cutout' later
    toggleWebcamOverlayStyle() {
      if (this.webcam_overlay_style == 'rectangle') {
        // if(this.webcam_position=='bl' || this.webcam_position=='br')
        this.webcam_overlay_style = 'circle';
        // document.getElementById('overlay-video').classList.add('circle');
      } else {
        this.webcam_overlay_style = 'rectangle';
        // document.getElementById('overlay-video').classList.remove('circle');
      }
    }


    // this method is to ensure that top-left and top-right webcam positions do not have cutout style
    webcamPositionChanged() {
        if (this.webcam_overlay_style == 'cutout') {
            if (this.webcam_position == 'tl' || this.webcam_position == 'tr') {
                this.webcam_overlay_style = 'rectangle';
            }
        }
    }


    // this method is used to show camera preview
    cameraSelectorMouseOver(cameraId) {
        console.log("over");
        navigator.mediaDevices.getUserMedia(this.video_constraints).then((webcamSteam) => {
            this.video = webcamSteam;
            document.querySelectorAll('video')[0].srcObject = this.video;
        });
    }

    // method bound to html input
    fileSelectedForVideoGeneration(event) {
      const file: File = (event.target as HTMLInputElement).files[0];
      event.target.value = '';
      this.attachFileForScriptPrediction(file);
    }

    // method to generate video from pdf/jpg
    attachFileForScriptPrediction(file: File) {
      const maxSize: number = 10000000; // 10 MB
      if (file.size > maxSize) {
        // check file
        let message = this.translateService.instant('Limit Exceeded: ');
        message += `${Math.floor(file.size / 1000000)} MB / ${maxSize / 1000000} MB`
        window.alert(message);
        return;
      }
      if (file.type.includes('pdf') || file.type.includes('image')) {
        // ok, show spinner
        this.inProgress = true;
        this.in_progress_text = this.translateService.instant("This may take up to 10 minutes");

        let user_prompt = this.translateService.instant("You will be provided with a presentation. Create a video script for every single slide, even if it is empty. ");
        user_prompt += this.translateService.instant("Only use the information that’s provided in the file. Separate each slide in triple dash ---. Do not mention file name and sources. ");
        user_prompt += this.translateService.instant("\n### Examples: ###\n--- Let me show you\n--- We see\n--- There is");
        const system_prompt = this.translateService.instant("Learn the language of the file content and perform the following task in that language.");
        const formData = new FormData();

        if (file.type.includes('image')) {
          formData.append('image_search', file, file.name);
        } else {
          formData.append('file_search', file, file.name);
        }

        formData.append('video_id', this.videoObj.id);
        formData.append('video_file', file, file.name);
        formData.append('system', system_prompt);
        formData.append('user', user_prompt);
        formData.append('assistant', '');
        this.dataService.putURL('user/clyppai/', formData, {
          observe: 'body',
          responseType: 'text'
        }).subscribe(() => {
          this.inProgress = false;
          // go to edit page, if there are no pending medias
          if (this.pending_media_count == 0) {
            this.finish(false);
          } else {
            this.loadMedias();
          }
        }, (err: HttpErrorResponse) => {
          console.error(err);
          this.inProgress = false;
          if (err.status == 502) {
            // gateway timed out
            window.alert('Your task is still running, please check back after some time.');
          } else {
            window.alert(this.translateService.instant('Ein Fehler ist aufgetreten'));
          }
        });
      }
      else {
        // other files are currently not allowed
        let message = this.translateService.instant('File not supported');
        window.alert(message);
        return;
      }
    }

    // this method predicts script from title and update the notes field
    predictScript(type: string) {
      let system_prompt: string = "";
      let user_prompt: string = '';
      let assistant_prompt: string = this.video_title;
      let auto_close: boolean = true;

      switch (type) {
        case 'predict':
          system_prompt = this.translateService.instant("Create a brief video tutorial as numbered list");
          user_prompt = this.translateService.instant("Create a video script without stage directions on ")
          user_prompt += this.video_title;
          auto_close = false;
          assistant_prompt = this.video_notes;
          break;
        case 'improve':
          system_prompt = this.translateService.instant("You will be provided with a text.");
          user_prompt = this.translateService.instant("Convert this input into language that is easy to understand in ");
          user_prompt += this.videoObj.audio_language;
          assistant_prompt = this.video_notes;
          break;
        case 'compact':
          system_prompt = this.translateService.instant("You will be provided with a text.");
          user_prompt = this.translateService.instant("Make this input more concise in ");
          user_prompt += this.videoObj.audio_language;
          assistant_prompt = this.video_notes;
          break;
        default:
          return;
      }
      // open AI popup
      this.dialog.open(CompletionPopupComponent, {
        width: '70vw',
        minWidth: '400px',
        maxWidth: '700px',
        maxHeight: '90vh',
        autoFocus: true,
        disableClose: true,
        hasBackdrop: true,
        data: {
          system: system_prompt,
          user: user_prompt,
          assistant: assistant_prompt,
          auto_close: auto_close,
          file_search: null,
        }
      }).afterClosed().subscribe(res => {
        // this boolean will tell if we need to reload the profiles
        if (res) {
          // ask user if they want to replace or append
          let replace = false;
          if (this.video_notes.length > 0) {
            const message = this.translateService.instant("Replace existing script?");
            replace = window.confirm(message);
          }
          if (replace) {
            // replace whole script
            this.video_notes = res;
          } else {
            // append
            this.video_notes += res;
          }
        }
      });
    }

    // expand notes from 3 rows to 7 rows or vice versa
    expandNotes() {
        if (this.notes_row_span == 4) {
            // expand
            this.notes_row_span = 9;  // increase notes height
            this.video_col_span = 0;  // hide video player
            this.text_area_rows = 24; // increase notes lines
        } else {
            // collapse
            this.notes_row_span = 4;  // decrease notes height
            this.video_col_span = 2;  // show video player
            this.text_area_rows = 7;  // decrease lines
        }
    }

    // send a put url to BE
    // no need to check media count because button is only enabled if no media
    // moreover, backend will reject the request too
    updateProjectSettings(event) {
        this.dataService.putURL(`user/videos/${this.videoObj.id}/`, {
            project_settings: event.value
        }).subscribe((res) => {
            this.snackBar.open(this.translateService.instant('Erfolgreich'), '', {duration: 1000});
            this.videoObj.project_settings = event.value;
            this.qualityChanged(event.value);
            // above method will take care to reset blur settings if quality is changed to 1440p
        });
    }

    // changes the ci_profile for a video object
    updateVideoProfile(event) {
        if (event.value == -1) {
            // user wants to add a new profile
            // the ci_profile is set to -1, bring it back to prev value
            if (this.videoObj.ci_profile) {
                // not null. Need to make sure that prev value was not null.
                event.source.value = this.videoObj.ci_profile;
            } else {
                // null
                event.source.value = 0;  // set it to 0, mat-select does not support null
            }

            // user want to add a new option
            this.dialog.open(VideoProfileComponent, {
                width: '90vw',
                minWidth: '400px',
                height: '90vh',
                autoFocus: false,
                disableClose: true,
                hasBackdrop: true,
            }).afterClosed().subscribe(is_profile_added_deleted => {
                // this boolean will tell if we need to reload the profiles
                if (is_profile_added_deleted) {
                    // we can't just reload the video profiles, as user may have deleted the current profile,
                    // so we also need to reload the video data
                    location.reload();
                }
            });
        } else {
            // user wants to change the profile, not add it
            let ci_id: number | null = 0;
            if (event.value == 0) {
                // user is setting it to default
                // make it null before sending it to backend
                ci_id = null;  // ci_profile should be null as backend does not accept 0
            } else {
                // ci_profile is >= 1
                ci_id = event.value;
            }
            this.dataService.putURL(`user/videos/${this.videoObj.id}/`, {
                ci_profile: ci_id
            }).subscribe((res) => {
                this.videoObj.ci_profile = ci_id;
                this.snackBar.open(this.translateService.instant('Erfolgreich'), '', {duration: 1000});
                // update preview window to changed profile
                this.updatePreviewImages();
            });
        }
    }

    // updates the preview window as per video profile
    updatePreviewImages() {
        const id: number | null = this.videoObj.ci_profile;
        if (id) {
            // not null, find object
            const videoProfile = this.videoProfileOptions.find(x => x.id == id);
            this.custom_bg_url = videoProfile.bg_image;
            this.overlay_logo = videoProfile.overlay_logo;
            this.overlay_code = videoProfile.overlay_code;
        } else {
            // default profile
            this.custom_bg_url = this.authService.company.bg_image;
            this.overlay_logo = this.authService.company.overlay_logo;
            this.overlay_code = this.authService.company.overlay_code;
        }
        this.convertImageToBase64(this.setCustomImageSource);
    }

    dropHandler(event) {
        event.preventDefault();
        // if some task is already happening, ignore the file drop
        if  (this.inProgress) {
            this.fileDragged = false;
            return;
        }
        let file = event.dataTransfer.files[0];
        if (file !== undefined) {
            // no need to confirm first
            this.fileSelected(file);
        }
        this.fileDragged = false;
    }

    // this method checks if there is at least one processed media and then only enables finish/next button
    showFinishButton() {
        let any_media_with_processed_file = this.medias.findIndex(media => media.processed_file != null);
        if (any_media_with_processed_file > -1) {
            if (!this.show_pause_button) {
                // case when a file is dropped while recording
                // pause button is visible while recording
                this.show_finish_button = true;
            }
        } else {
            this.show_finish_button = false;
        }
    }

    /*
    // load the bodyPix model for selfie segmentation, only called in case of webcam blur/cutout mode
    async loadBodyPix() {
        await BodyPix.load({
            // ModelConfig
            architecture: 'MobileNetV1',
            outputStride: 16,
            multiplier: 0.5,
            quantBytes: 2,
        })
            .then((net) => {
                this.performBlurInference = true;
                this.perform(net).catch(err => {
                    // failed to perform inference, canvas is not loaded, record from the video
                    this.resetWebcamStyle();
                    this.handleError(err);
                });
            })
            .catch(err => {
                // failed to load the model, reset the webcam style
                this.resetWebcamStyle();
                this.handleError(err);
            })
    }

    // perform the inference and draw the result on canvas
    async perform(net: BodyPix.BodyPix) {
        let cutoutBgImage: HTMLImageElement = <HTMLImageElement>document.getElementById('cutoutWebcamImage');

        this.canvasOutput = <HTMLCanvasElement>document.getElementById('videoCanvas');
        this.canvasOutput.height = this.firstHTMLVideoElementHeight;
        this.canvasOutput.width = this.firstHTMLVideoElementWidth;
        this.canvasOutput.hidden = false;
        this.canvasStream = this.canvasOutput.captureStream(this.video_constraints.video['frameRate']);

        // context to draw image on canvas, needed in case of cutout
        const canvasOutputCtx = this.canvasOutput?.getContext('2d');

        // blur config
        const backgroundBlurAmount = 5;
        const edgeBlurAmount = 10;
        const flipHorizontal = false;

        // inference config
        const personInferenceConfig: PersonInferenceConfig = {
            // InferenceConfig
            flipHorizontal: flipHorizontal,
            internalResolution: 'medium',
            segmentationThreshold: 0.6,  // lower => loose prediction, away from user
            // PersonInferenceConfig
            maxDetections: 1,
            scoreThreshold: 0.4,
            nmsRadius: 20,
        }

        while (this.performBlurInference) {
            const segmentation = await net.segmentPerson(this.firstHTMLVideoElement,
                personInferenceConfig);

            // update as per webcam_style
            if (this.webcam_style == 'blur') {
                // blur
                BodyPix.drawBokehEffect(this.canvasOutput, this.firstHTMLVideoElement, segmentation,
                    backgroundBlurAmount, edgeBlurAmount, flipHorizontal);
            } else {
                // cutout
                // find mask
                const backgroundDarkeningMask = BodyPix.toMask(segmentation);

                // first, draw custom mask
                canvasOutputCtx.putImageData(backgroundDarkeningMask, 0, 0);

                // second, draw user from video, use effect source-out
                // The new shape is drawn where it doesn't overlap the existing canvas content.
                canvasOutputCtx.globalCompositeOperation = "source-out";
                canvasOutputCtx.drawImage(this.firstHTMLVideoElement, 0, 0);

                // third, draw the image with destination-over effect
                // New shapes are drawn behind the existing canvas content.
                // H,W are needed as image may be in another format
                canvasOutputCtx.globalCompositeOperation = "destination-over";
                canvasOutputCtx.drawImage(cutoutBgImage, 0, 0, this.firstHTMLVideoElementWidth, this.firstHTMLVideoElementHeight);
            }
        }
    }
    */

    // load the model into memory
    loadMediaPipe() {
        this.selfieSegmentation = new SelfieSegmentation({
            locateFile: (file: string) => {
                return `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation/${file}`;
            }
        });
        this.selfieSegmentation.setOptions({
            modelSelection: 1,
            selfieMode: false,
        });
        this.selfieSegmentation.onResults(this.onMediaPipeResults);
        this.sendToMediapipe().then();
    }

    // start inference via mediapipe
    sendToMediapipe = async () => {
        this.performBlurInference = true;
        this.camera_object = new Camera(this.firstHTMLVideoElement, {
            onFrame: async () => {
                if (!this.selfieSegmentation) return;
                await this.selfieSegmentation.send({image: this.firstHTMLVideoElement});
            },
            width: this.firstHTMLVideoElementWidth,
            height: this.firstHTMLVideoElementHeight
        });
        this.camera_object.start().then();
        this.canvasOutput = <HTMLCanvasElement>document.getElementById('videoCanvas');
        this.canvasOutput.height = this.firstHTMLVideoElementHeight;
        this.canvasOutput.width = this.firstHTMLVideoElementWidth;
        this.canvasOutput.hidden = false;
        this.canvasStream = this.canvasOutput.captureStream(this.video_constraints.video['frameRate']);
    }

    // draw results on canvas
    onMediaPipeResults = async (results: Results) => {
        const canvasOutputCtx = this.canvasOutput?.getContext('2d');
        let cutoutBgImage: HTMLImageElement = <HTMLImageElement>document.getElementById('cutoutWebcamImage');
        canvasOutputCtx.save();
        canvasOutputCtx.clearRect(0, 0, this.firstHTMLVideoElementWidth, this.firstHTMLVideoElementHeight);

        // draw segmentation mask
        canvasOutputCtx.filter = "none";
        canvasOutputCtx.globalCompositeOperation = "source-over";
        canvasOutputCtx.drawImage(results.segmentationMask, 0, 0, this.firstHTMLVideoElementWidth, this.firstHTMLVideoElementHeight);

        // draw image frame on top
        canvasOutputCtx.globalCompositeOperation = "source-in";
        canvasOutputCtx.drawImage(results.image, 0, 0, this.firstHTMLVideoElementWidth, this.firstHTMLVideoElementHeight);

        if (this.webcam_style == 'cutout') {
            canvasOutputCtx.filter = 'none';
            canvasOutputCtx.globalCompositeOperation = "destination-over";
            canvasOutputCtx.drawImage(cutoutBgImage, 0, 0, this.firstHTMLVideoElementWidth, this.firstHTMLVideoElementHeight);
        } else {
            canvasOutputCtx.filter = 'blur(10px)';
            canvasOutputCtx.globalCompositeOperation = "destination-over";
            canvasOutputCtx.drawImage(results.image, 0, 0, this.firstHTMLVideoElementWidth, this.firstHTMLVideoElementHeight);
        }
        canvasOutputCtx.restore();
    }

    // method to load custom image as base64 image and send the response to callback
    convertImageToBase64(callback) {
        let xhr = new XMLHttpRequest();
        xhr.onload = function () {
            let reader = new FileReader();
            reader.onload = function () {
                callback(reader.result);
            }
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', this.custom_bg_url);
        xhr.responseType = 'blob';
        xhr.send();
    }

    // method used as callback to store the data into html image
    setCustomImageSource(base64ImageData: string) {
        let cutoutBgImage: HTMLImageElement = <HTMLImageElement>document.getElementById('cutoutWebcamImage');
        cutoutBgImage.src = base64ImageData;
    }

    // update the column structure
    windowResized() {
        // in case of adjustment, also update it in css
        if (window.innerWidth <= 1000) {
            // upto exactly 1000px, the layout is for small screens
            // this is to adjust for the smaller screens
            this.video_col_span = 1;
            this.video_row_span = 3;
            this.total_columns = 1;
            this.record_button_row_span = 3;
            // hide notes
            this.show_notes = false;

            // hide the big mode selector & the other 4 dropdowns
            this.mode_selector_col_span = 0;
        } else {
            // including and more than 1001px, the layout is for large screens
            // this is to adjust for the larger screens
            this.video_col_span = 2;
            this.video_row_span = 5;
            this.total_columns = 4;
            this.record_button_row_span = 4;
            // show notes
            this.show_notes = true;
            this.notes_row_span = 4;  // decrease notes height
            this.text_area_rows = 7;  // decrease lines

            // show the big mode selector
            this.mode_selector_col_span = 1;
        }

        // find recording-div's height after a while
        setTimeout(() => {
          const recordingDiv: Element = document.getElementsByClassName('recording-div')[0];
          this.recordingDivWidth = recordingDiv.clientWidth;
        }, 200);
    }
}

// this method downloads any file from the provided url
async function downloadFromUrl(url: string) {
    if (!url) {
        // url is empty, null or undefined
        return;
    }
    const videoFileBlob: Blob = await urlToBlob(url);
    const fileName: string = url.split("/").pop().split("?")[0];
    const fileObject: File = new File([videoFileBlob], fileName);
    const fileLink: HTMLAnchorElement = document.createElement('a');
    fileLink.href = window.URL.createObjectURL(fileObject);
    fileLink.download = fileName;
    fileLink.click();
}
