import {Component, Inject, OnInit} from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from "@angular/material/bottom-sheet";
import {MatSnackBar} from "@angular/material/snack-bar";
import {DataService} from "../../services/data.service";
import {TranslateService} from "@ngx-translate/core";
import {MatDialog} from "@angular/material/dialog";
import {ConfirmDialogComponent} from "../confirm-dialog/confirm-dialog.component";
import {HttpErrorResponse} from "@angular/common/http";

@Component({
  selector: 'app-topic-bottom-sheet',
  templateUrl: './topic-bottom-sheet.component.html',
  styleUrls: ['./topic-bottom-sheet.component.scss']
})
export class TopicBottomSheetComponent implements OnInit {

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<TopicBottomSheetComponent>,
    private snackBar: MatSnackBar,
    private dataService: DataService,
    private dialog: MatDialog,
    private translateService: TranslateService,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: {ids: string[], case: 'topics' | 'videos'}
  ) {

  }

  ngOnInit(): void {
  }

  performAction(action: string) {
    // confirm first
    const title: string = this.translateService.instant("Bist du sicher?");
    // for cases like approve, feature & unfeature, user based email conditions are applied
    let message: string = "";
    let email_toggle: boolean = true;
    if (this.data.ids.length == 1) {
      message = this.translateService.instant("Creator");
      message += " "
      message += this.translateService.instant("will also be notified about this.");
    } else {
      message = this.translateService.instant("Creators");
      message += " "
      message += this.translateService.instant("will be informed about this.");
    }

    switch (action) {
      case 'feature':
      case 'approve':
      case 'unfeature':
        email_toggle = false;  // email will be sent based on user's preference
        break
      default:
        // email will be sent based on manager's preference
        message = '';  // dont show extra message
        break
    }

    const toggle_message: string = this.translateService.instant("Send Email");
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: [title, message, email_toggle, toggle_message],
      maxWidth: 700,
      minWidth: 400
    });

    confirmDialog.afterClosed().subscribe((res: [boolean, boolean]) => {
      if (res == undefined) {
        // backdrop clicked
        return;
      } else if (res[0]) {
        // true, send put call
        const send_email: boolean = res[1];
        const body = {
          action: action,
          ids: this.data.ids,
          send_email: send_email
        }

        this.dataService.putURL(`manager/${this.data.case}/`, body, {
          observe: 'body',
          responseType: 'text'
        }).subscribe((res: string) => {
          this.snackBar.open(res, '', {duration: 2500});
          this._bottomSheetRef.dismiss(true);
        }, (err) => {
          console.error(err);
          this.snackBar.open(this.translateService.instant('Ein Fehler ist aufgetreten'), '', {duration: 2000});
          this._bottomSheetRef.dismiss(false);
        });
      }
    });
  }

}
