<mat-toolbar>
  <!--nav bar for authenticated screens-->
  <div *ngIf="authService.isAuthenticated();" class="w-100 d-flex justify-content-between inherit-height">
    <!--logo and side-button is only visible if not searching in phone-->
    <div class="d-flex inherit-height align-items-center justify-content-start" *ngIf="!showSearch">
      <button mat-icon-button *ngIf="authService.currentUser?.is_profile_completed" (click)="sideBarToggle();">
        <mat-icon>menu</mat-icon>
      </button>
      <a [href]="authService.currentUser?.is_profile_completed ? '/start' : '/user_profile'">
        <img [src]="authService.company?.company_logo" alt="company logo" class="inherit-height p-1"
          style="max-height: 60px; max-width: 200px;"
          onerror="this.onerror=null; this.src='assets/logo/clypp_logo_small.png'">
      </a>
    </div>

    <!--small screen search-->
    <div *ngIf="authService.currentUser?.is_profile_completed && showSearch" class="ml-2 mr-2" style="width: inherit;">
      <mat-form-field appearance="outline" class="w-100" floatLabel="never"
        style="font-size: initial; margin-top: -6px;">
        <button mat-icon-button matPrefix (click)="clearSearchAndGoBack();" class="ml-0">
          <mat-icon>arrow_back</mat-icon>
        </button>
        <input (keydown.enter)="getSearchResult()" [formControl]="myControl" [matAutocomplete]="auto" autocomplete="off"
          autofocus matInput placeholder="{{'Search for…'|translate}}" type="text">
        <button mat-icon-button matSuffix (click)="clearSearch();">
          <mat-icon>clear</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <!--big screen search-->
    <div *ngIf="authService.currentUser?.is_profile_completed && !smallSc" style="width: 30%;">
      <mat-form-field appearance="outline" class="w-100" floatLabel="never"
        style="font-size: initial; margin-top: -6px;">
        <input (keyup.enter)="getSearchResult()" [formControl]="myControl" aria-label="search input"
          [matAutocomplete]="auto" autocomplete="off" autofocus matInput
          placeholder="{{'Search for…'|translate}}" type="text">
        <button mat-icon-button matSuffix (click)="getSearchResult()">
          <mat-icon>search</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <mat-autocomplete #auto="matAutocomplete">
      <mat-option (onSelectionChange)="goToGroupsOrUser(option)" *ngFor="let option of filteredOptions | async"
        [title]="option.string" [value]="option.name">
        <mat-icon *ngIf="option.type == 'user'">person_outline</mat-icon>
        <mat-icon *ngIf="option.type == 'group'" class="material-icons-outlined">group</mat-icon>
        <mat-icon *ngIf="option.type == 'tag'">tag</mat-icon>
        <span *ngIf="option.type != 'tag'">{{option.name}}</span>
        <i *ngIf="option.type == 'tag'">{{option.name}}</i>
      </mat-option>
    </mat-autocomplete>

    <div class="d-flex justify-content-end inherit-height align-items-center">
      <!--search button for small screens-->
      <button mat-icon-button class="mr-2" (click)="showSearch = true;"
        *ngIf="authService.currentUser?.is_profile_completed && smallSc && !showSearch">
        <mat-icon>search</mat-icon>
      </button>

      <!-- Case: when profile is completed, show CREATE button -->
      <div *ngIf="authService.currentUser?.is_profile_completed" class="d-flex justify-content-end mr-2"
        style="height: fit-content;">

        <!--Show it only in large screen-->
        <button (click)='navbarService.createPRVideo();'
          [ngClass]="authService.currentUser?.is_creator ? 'mat-flat-button text-white' : 'mat-stroked-button'"
          class="borderLeft d-none d-md-flex align-items-center mr-1">
          <mat-icon class="mr-1">add</mat-icon>
          <span class="font-size-16" translate>New Clypp</span>
        </button>


        <!--Show it only in large screen-->
        <button [matMenuTriggerFor]="createMenu"
          [ngClass]="authService.currentUser?.is_creator ? 'mat-flat-button text-white' : 'mat-stroked-button'"
          class="borderRight d-none d-md-inline">
          <mat-icon class="pt-2">expand_more</mat-icon>
        </button>


        <!--Show it only in small screen when user is not a creator-->
        <button *ngIf="!authService.currentUser?.is_creator" (click)="navbarService.setCreatorPopup();"
          class="d-inline d-md-none border-radius-20 text-dark" color="primary" mat-stroked-button>
          <mat-icon>add</mat-icon>
        </button>

        <!--Show it only in small screen when user is a creator-->
        <button *ngIf="authService.currentUser?.is_creator" [matMenuTriggerFor]="createMenu"
          class="d-inline d-md-none border-radius-20 text-white" color="primary" mat-flat-button>
          <mat-icon>add</mat-icon>
        </button>

        <mat-menu #createMenu="matMenu">
          <button (click)="navbarService.createPRVideo();" class="d-inline d-md-none" mat-menu-item>
            <mat-icon class="material-icons-outlined">radio_button_checked</mat-icon>
            <span class="text-nunito font-size-16" translate>New Clypp</span>
          </button>
          <button (click)="navbarService.createTopic()" mat-menu-item>
            <mat-icon class="material-icons-outlined">post_add</mat-icon>
            <span class="text-nunito font-size-16" translate>New Page</span></button>
          <button (click)="navbarService.addPlaylist()" mat-menu-item>
            <mat-icon>playlist_add</mat-icon>
            <span class="text-nunito font-size-16" translate>Neue Playlist</span></button>
          <button (click)="navbarService.openVideoRequestDialog()" mat-menu-item>
            <mat-icon class="material-icons-outlined">add_comment</mat-icon>
            <span class="text-nunito font-size-16" translate>New Request</span></button>
        </mat-menu>
      </div>

      <!-- case: always show my PROFILE -->
      <div [matMenuTriggerFor]="menu" class="avatar-fab cursor-pointer mr-1" matTooltip="{{'Mein Profil' | translate}}">
        <img [src]="authService.currentUser?.profile_pic" alt="profile"
          onerror="this.onerror=null; this.src='https://avatar-service-platform.personio.de/'" />
      </div>
    </div>
  </div>

  <div *ngIf="!authService.isAuthenticated()" class="w-100 d-flex justify-content-between inherit-height">
    <!--company logo-->
    <a [href]="is_public_page ? '/public/'+authService.company?.id : '/login'">
      <!--on click of logo on login page, do nothing-->
      <!--on click of logo on public page, go to company's public videos-->
      <img alt="Clypp Logo" class="inherit-height p-1" [src]="company_logo_url_public"
        onerror="this.onerror=null; this.src='assets/logo/clypp_logo_small.png'"
        style="max-height: 60px; max-width: 200px;">
    </a>

    <!--button to change language-->
    <div class="d-flex align-items-center">
      <!--language button is always visible for not logged in user-->
      <button [matMenuTriggerFor]="languages" aria-label="language dropdown" class="text-nunito font-size-16" mat-button
        style="color: dimgray">
        <mat-icon class="mr-1">language</mat-icon>
        <span>{{lang_label}}</span>
      </button>

      <!--button to login page-->
      <button *ngIf="is_public_page" aria-label="sign in" class="text-nunito font-size-16" mat-button
        routerLink="/login" style="color: dimgray">
        <span translate>Anmelden</span>
      </button>

      <!--button to signup page-->
      <button *ngIf="is_public_page" aria-label="register"
        class="text-nunito font-size-16 m-3 border-radius-10 d-none d-md-inline" mat-stroked-button routerLink="/signUp"
        style="color: dimgray">
        <span translate>Try Clypp for free</span>
      </button>
    </div>
  </div>
</mat-toolbar>

<!--profile menu when logged in-->
<mat-menu #menu="matMenu">
  <button routerLink="user_profile" mat-menu-item>
    <mat-icon class="material-icons-outlined">manage_accounts</mat-icon>
    <span class="text-nunito font-size-16" translate>My Account</span>
  </button>
  <button (click)="openFeedbackDialog();" mat-menu-item>
    <mat-icon class="material-icons-outlined">feedback</mat-icon>
    <span class="text-nunito font-size-16" translate>Contact Support</span>
  </button>
  <button (click)="clyppPublicPage();" mat-menu-item>
    <mat-icon class="material-icons-outlined">dvr</mat-icon>
    <span class="text-nunito font-size-16" translate>Video Tutorials</span>
  </button>

  <mat-divider></mat-divider>

  <button (click)="openHelpPage();" mat-menu-item>
    <mat-icon class="material-icons-outlined">live_help</mat-icon>
    <span class="text-nunito font-size-16" translate>Help Center</span>
  </button>
  <button (click)="openNewsPage();" mat-menu-item>
    <mat-icon class="material-icons-outlined">new_releases</mat-icon>
    <span class="text-nunito font-size-16" translate>Neuigkeiten</span>
  </button>

  <mat-divider></mat-divider>

  <button (click)="logout()" mat-menu-item>
    <mat-icon>logout</mat-icon>
    <span class="text-nunito font-size-16" translate>Logout</span>
  </button>
</mat-menu>

<!--language ment when logged out-->
<mat-menu #languages="matMenu">
  <button (click)="setTransLanguage(lang)" *ngFor="let lang of transLang" mat-menu-item>{{lang.name}}</button>
</mat-menu>
