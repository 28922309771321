<div class="d-flex justify-content-between" mat-dialog-title>
  <h2 class="fontStyle">
    {{ data.answers.length }}&nbsp;{{ data.answers.length == 1 ? ('Response' | translate) : ('Responses' | translate) }}
  </h2>
  <div>
    <button (click)="deleteAllResponses();"
            class="border-radius-20 mr-3" mat-stroked-button>
      <mat-icon class="mr-2 material-icons-outlined">delete_sweep</mat-icon>
      <span translate>Delete all</span>
    </button>
    <button mat-dialog-close mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>

<!--name & next-->
<div class="m-2 d-flex justify-content-between flex-wrap align-items-center">
  <!--input-->
  <mat-form-field appearance="outline">
    <mat-select [(ngModel)]="step">
      <mat-option *ngFor="let answer of data.answers; let i = index;" [value]="i">
        {{ getUploaderName(answer.uploader) }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <!--text and page buttons-->
  <div class="d-flex align-items-center">
    <span class="text-nunito">{{ step + 1 }}&nbsp;{{ 'von'|translate }}&nbsp;{{ data.answers.length }}</span>
    <button (click)="prevStep();" [disabled]="step==0" mat-icon-button>
      <mat-icon>navigate_before</mat-icon>
    </button>
    <button (click)="nextStep();" [disabled]="step==data.answers.length - 1" mat-icon-button>
      <mat-icon>navigate_next</mat-icon>
    </button>
  </div>
</div>

<!--show question title,options-->
<mat-card class="border-radius-10 text-nunito bg-quiz m-2"
          style="border: 1px solid lightgray;">
  <mat-card-header>
    <mat-card-title>{{ data.question.title }}</mat-card-title>
    <mat-card-subtitle>{{ data.question.desc }}</mat-card-subtitle>
  </mat-card-header>

  <mat-card-content class="mt-3">
    <!--ss case-->
    <mat-radio-group *ngIf="data.question.type =='SS'" class="d-flex flex-column align-items-start" disabled>
      <mat-radio-button *ngFor="let option of data.options" [checked]="checkIfOptionIsSelected(option.id)"
                        class="mb-2"
                        [value]="option.id">
        <span class="text-dark">{{ option.title }}</span>
        <!--<br>
        <a *ngIf="option.supporting_file" [href]="option.supporting_file" target="_blank">
          {{ getAttachedFilename(option.supporting_file) }}
        </a>-->
      </mat-radio-button>
    </mat-radio-group>

    <!--ms case-->
    <div *ngIf="data.question.type =='MS'" class="d-flex flex-column">
      <mat-checkbox *ngFor="let option of data.options"
                    [checked]="checkIfOptionIsSelected(option.id)"
                    disabled class="mb-2">
        <span>{{ option.title }}</span>
        <!--<mat-icon *ngIf="is_quiz_mode && checkIfOptionIsSelected(option.id)"
                  [ngStyle]="option.is_correct ? {color: 'green'} : {color: 'red'}"
                  class="ml-2" mat-list-icon style="top: 0.2em;position: relative;">
          {{ option.is_correct ? 'check' : 'clear' }}
        </mat-icon>
        <br>
        <a *ngIf="option.supporting_file" [href]="option.supporting_file" target="_blank">
          {{ getAttachedFilename(option.supporting_file) }}
        </a>-->
      </mat-checkbox>
    </div>

    <!--ST LT case-->
    <div [innerHTML]="getAnswerHtml(data.answers[step])" class="mt-2 text-nunito">
    </div>

    <!--FU case-->
    <div *ngIf="data.answers[step].supporting_file" [ngSwitch]="getMimeType(data.answers[step].supporting_file)"
         mat-line>
      <!-- image & links-->
      <a [href]="data.answers[step].supporting_file" target="_blank">
        <span *ngSwitchCase="'other'">
          {{ getAttachedFilename(data.answers[step].supporting_file) }}
        </span>
        <img *ngSwitchCase="'image'" [src]="data.answers[step].supporting_file" alt="supporting file"
             class="border-radius-10"
             style="height: 200px; width: 200px; object-fit: contain; border: 1px solid lightgray; background-position: center center;">
      </a>
    </div>
  </mat-card-content>
</mat-card>

<!-- correct, incorrect, submit button & mailto, delete button -->
<mat-dialog-actions class="d-flex justify-content-between">
  <!--submitted button-->
  <button
    [ngStyle]="data.answers[step].is_correct ? {'color':'green','background-color':'#cbf2cf',border:'1px solid green'}:{'color':'red','background-color':'#FFD6D7',border:'1px solid red'}"
    class="border-radius-20 justify-content-start"
    style="min-width: 10em;"
    disabled mat-flat-button>
    <ng-container *ngIf="is_quiz_mode; else elseCase">
      <!--right-->
      <ng-container *ngIf="data.answers[step].is_correct; else elseCase">
        <mat-icon class="mr-2" style="color: green;">check</mat-icon>
        <span translate="Correct"></span>
      </ng-container>
      <!--wrong-->
      <ng-template #elseCase>
        <mat-icon class="mr-2" style="color: red;">close</mat-icon>
        <span translate="Incorrect"></span>
      </ng-template>
    </ng-container>
    <ng-template #elseCase>
      <mat-icon class="mr-2" style="color: green;">check</mat-icon>
      <span translate="Submitted"></span>
    </ng-template>
  </button>

  <!--send mail & delete button-->
  <div>
    <!--send mail-->
    <button (click)="mailToUser(data.answers[step].uploader);" mat-icon-button>
      <mat-icon class="material-icons-outlined" matTooltip="{{'Send Email' | translate}}">
        chat
      </mat-icon>
    </button>
    <!--delete answer-->
    <button (click)="deleteAnswer(data.answers[step]);" mat-icon-button>
      <mat-icon class="mr-2" matTooltip="{{'Löschen' | translate}}">
        delete_outline
      </mat-icon>
    </button>
  </div>
</mat-dialog-actions>
