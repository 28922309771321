import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input, OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {ActivatedRoute, Router,} from '@angular/router';
import {
  ChecklistItem,
  MiniDetails,
  TranslationDetails, UploaderDetails, UserProfileWithName,
  VideoCard,
  VideoView
} from '../models/video/video.interface';
import {AuthService, urlify} from '../services/auth.service';
import {DataService} from '../services/data.service';
import {NavbarService} from '../services/navbar.service';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ProgressSpinnerDialogComponent} from '../shared/progress-spinner-dialog/progress-spinner-dialog.component';
import JSZip from 'jszip';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {first} from 'rxjs/operators';
import {Image} from '../shared/interfaces/image.interface';
import {AddToPlaylistComponent} from '../playlists/add-to-playlist/add-to-playlist.component';
import {MatGridList} from '@angular/material/grid-list';
import {Title} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {UtilityService} from '../services/utility.service';
import {inIframe} from "../login/login.component";
import * as xml2js from 'xml2js';
import {MembersListPopupComponent} from "../shared/members-list-popup/members-list-popup.component";
import {Playlist, PlaylistCard} from "../models/playlist/playlist";
import {StillThereComponent} from "../shared/still-there/still-there.component";
import {convertBase64ToBlob, PopupData, SharePopupComponent} from "../shared/share-popup/share-popup.component";
import {MatBottomSheet} from "@angular/material/bottom-sheet";
import {TopicBottomSheetComponent} from "../shared/topic-bottom-sheet/topic-bottom-sheet.component";

interface Comment {
  commented_by: UploaderDetails;
  commented_on: string;
  content: string;
  created_on: string;
  id: number;
  is_private: boolean;
}

export interface PlaylistVideo {
  index: number;
  video: VideoCard;
}

@Component({
  selector: 'app-quickwin-view',
  templateUrl: './quickwin-view.component.html',
  styleUrls: ['./quickwin-view.component.scss'],
})
export class QuickwinViewComponent implements OnInit, OnDestroy {
  @ViewChild('grid') grid: MatGridList;
  @ViewChild('leftVideo') elementView: ElementRef;
  @ViewChild('scroll') scroll: ElementRef;

  @Input('playlistVideos') playlistVideos: PlaylistVideo[] = [];
  @Input('playlist_object') playlist_object: Playlist = null;
  @ViewChild('emojiFieldInput') emojiFieldInput: ElementRef;

  private_comment_toggle: boolean = false;
  isAutoplay: boolean = true;
  is_repeat_on: boolean = false;
  attachInfo = this.translateService.instant('Anhang');
  attachFileName: string = "";
  videoId: string;
  viewCount: number | string;
  related_videos: VideoCard[] = [];
  related_playlists: PlaylistCard[] = [];
  loading_related_content: boolean = false;
  display_comments: Comment[] = [];
  all_comments: Comment[] = [];
  environment = environment;
  images = Array<Image>();
  n_display_comments: number = 0;
  uploader_id: number = 0;
  is_user_creator: boolean = false;  // true if I am watching my own video
  is_user_manager: boolean = false;  // true if I can manage this video
  heightVal: any;
  imgPath: string;
  attachmentPath: string;
  vidPath: string;
  vidTitle: string;
  current_language: string = '';
  likeColor: boolean = false;
  likeCounter: number = 0;
  slider: any;
  comment_text: string = '';
  processingCall = false;
  videoRouteId: any;
  showPlayback: boolean = true;
  video_player: HTMLVideoElement = null;
  show = false;
  showEmojiPicker = false;
  formatted_video_desc: string = '';
  video_desc: string = '';
  closeGallery: boolean = false;
  src: string = "";
  srcs: any = [];
  index: number;
  tagsData: MiniDetails[];
  currentPlaylistIndex: number = 0;

  currentVideo: VideoView = null;
  translations: TranslationDetails[] = [];
  show_add_translation_button: boolean = false;  // enabled if language does not exist & internal video & AI services are enabled
  disable_save: boolean = true;
  match_two_step_timeStamp = /([0-5][0-9](:[0-5][0-9])) /g;                      // valid pattern : 00:01 introduction
  match_three_step_timeStamp = /([0-1]?[0-9]|2[0-3]):[0-5][0-9](:[0-5][0-9]) /g; // 00:59:59 introduction
  make_two_step_Url = /([0-5][0-9](:[0-5][0-9]))/g;                                // 00:00
  make_three_step_Url = /([0-1]?[0-9]|2[0-3]):[0-5][0-9](:[0-5][0-9])/g;           // 00:00:00

  isTeamsBrowser: boolean = false;
  isIframe: boolean = false;
  is_external_company_video: boolean = false;
  open_share_popup: boolean = false;

  observer: IntersectionObserver = null;  // needed to observe when video element is in sight
  update_comment_id: number = 0;  // id of the comment being updated: 0 for not editing mode
  timedOut: boolean = false;
  timeoutHandler: any = null;  // timeout
  playlist_view_link: string = '' ;
  video_view_link: string = '';
  current_user_dept: string = "";
  current_user_team: string = "";
  current_viewed_till: number = 0;  // keeps tracks of until when user viewed the video, to avoid duplicate calls

  constructor(
    private route: ActivatedRoute,
    public utilityService: UtilityService,
    private titleService: Title,
    public translateService: TranslateService,
    private router: Router,
    private snackBar: MatSnackBar,
    private dataService: DataService,
    public authService: AuthService,
    private bottomSheet: MatBottomSheet,
    private navbarService: NavbarService,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private httpClient: HttpClient
  ) {

    if (this.router.getCurrentNavigation()) {
      let state = this.router.getCurrentNavigation().extras.state;
      if (state) {
        if ('open_share_popup' in state) {
          this.open_share_popup = state['open_share_popup'];
        }
      }
    }

    if (navigator.userAgent.indexOf('Chrome') == -1) {
      this.showPlayback = false;
    }

    this.playlist_view_link = window.location.href;
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.closeGallery == true && this.move_img(event);
  }

  ngOnInit(): void {
    this.navbarService.getUserProfileDetails();
    this.navbarService.disableCreationMode();
    this.navbarService.showSideNav = true;
    this.cdr.detectChanges();
    // todo: revisit the logic below
    this.route.paramMap.subscribe((map) => {
      // this will return the last number of url
      this.videoRouteId = map.get('id');
      this.related_videos = [];
      this.related_playlists = [];
      if (this.videoRouteId == this.playlist_object?.id) {
        // route id is same as component opener
        // no need to fetch videos as it is passed to component
        this.videoId = this.playlistVideos[0].video.id;
        this.currentPlaylistIndex = 1;
        this.loadVideo();
      } else {
        // simple view video case
        this.videoId = this.videoRouteId;
        this.loadVideo();
      }
      this.video_view_link = environment.baseURL + "publicVideoView/" + this.videoId;
    });

    if (navigator.userAgent.indexOf('Teams') > -1) {
      this.isTeamsBrowser = true;
    }
    if (inIframe()) {
      this.isIframe = true;
    }
  }

  // function added for api in onInit
  loadVideo() {
    clearTimeout(this.timeoutHandler);  // clear existing timeouts while playing next video
    this.timedOut = false;  // for playlist case
    this.current_user_dept = "";  // clear, in case of friend video
    this.current_user_team = "";
    this.dataService.getURL<VideoView>(`user/videos/${this.videoId}/`,
      {observe: 'body', responseType: 'json'})
      .subscribe((res: VideoView) => {
        // this.timeoutDuration = +(new URL(res.processed_file)).searchParams.get("X-Amz-Expires");
        this.timeoutHandler = setTimeout(() => {
          this.timedOut = true;
          // open the still-there component
          clearTimeout(this.timeoutHandler);
          this.registerViewedTill();  // needed as refresh page does not perform this call
          this.pauseVideo();
          const stillThereDialogRef: MatDialogRef<StillThereComponent> =
            this.dialog.open(StillThereComponent, {
              panelClass: 'transparent',
              disableClose: true,
            });
          stillThereDialogRef.afterClosed().subscribe(() => {
            // reload the page
            location.reload();
          });
        }, this.environment.timeoutDuration * 1000);
        // *1000 into milliseconds

        this.currentVideo = res;
        this.current_viewed_till = 0;  // todo: reset
        this.titleService.setTitle(`${res.title} - Clypp`);
        this.viewCount = res.views;
        this.uploader_id = res.uploader.id;
        this.imgPath = res.thumbnail;
        this.vidPath = res.processed_file;
        this.vidTitle = res.title;
        this.likeCounter = res.up_votes;
        this.video_desc = res.desc;
        this.attachmentPath = res.attachment;

        this.is_user_creator = this.uploader_id == this.authService.userDetails.user;

        this.addOriginalLang();
        // now add remaining translation
        this.translations.push(...res.translations);
        // only load related videos if video is found
        this.relatedVideos();
        // only load comments if video is found
        this.loadComments();
        // load the tags
        this.tagsData = this.authService.tag_data.filter(obj => res.tags.includes(obj.id));

        // update labels
        this.is_external_company_video = this.authService.userDetails.company != res.company;
        // only change for internal video
        if (this.is_external_company_video) {
          // show external label
          this.current_user_team = "";
          this.current_user_dept = this.translateService.instant("External");
        } else {
          // only load related playlists if video is found and it belongs to our company
          this.relatedPlaylists();
          // find dept and team from checklist
          const dept: ChecklistItem = this.authService.checklist_data.find(e => e.type == 'dept' && e.id == res.uploader.userprofile.department);
          const team: ChecklistItem = this.authService.checklist_data.find(e => e.type == 'team' && e.id == res.uploader.userprofile.team);
          try {
            this.current_user_dept = dept.name;
            // team name contains dept name and colon+space
            this.current_user_team = team.name.slice(this.current_user_dept.length + 2);
          } catch {
            // pass
          }

          // check if user watching the video is also a manager
          if (this.authService.userDetails.is_company_manager || this.authService.userDetails.is_quality_manager) {
            this.is_user_manager = true;  // also could be a global admin
          } else if (this.authService.userDetails.is_department_manager) {
            // check dept
            if (res.uploader.userprofile.department == this.authService.userDetails.department) {
              this.is_user_manager = true;
            }
          } else if (this.authService.userDetails.is_team_manager) {
            // check team
            if (res.uploader.userprofile.team == this.authService.userDetails.team) {
              this.is_user_manager = true;
            }
          }
        }

        if (res['attachment'] != null || res['attachment'] != undefined) {
          this.attachFileName = res['attachment'].split('/').pop();
          this.attachFileName = this.attachFileName.split("?")[0];
          this.translateService.instant('Anhang');
        }

        // only load likes if video is found
        this.dataService
          .getURL<any>(`user/videos/${this.videoId}/like/`)
          .subscribe((res) => {
            if (res.state == 'liked') {
              this.likeColor = true;
            } else {
              this.likeColor = false;
            }
          });

        // on homepage, we get all the videos which are published, irrespective of the video state
        // check if the video is in processing state
        if (res.state == 'PR') {
          // if a video is currently in PR state, then, check back again after 20 seconds
          // todo: the lines below are called even if user leaves the page
          // setTimeout(() => {
          //   this.loadVideo();
          // }, 10000);
        }
      }, (err) => {
        window.alert(err.error);
        this.videoEnded();
      });

    this.getHeightOfDiv();
  }

  getHeightOfDiv() {
    if (this.currentVideo) {
      this.heightVal = document.getElementById('leftVideo').offsetHeight;
    } else {
      this.heightVal = '500';
    }
    // this.heightVal = this.elementView.nativeElement.offsetHeight;
  }

  onPlayerReady(): void {
    this.getHeightOfDiv();
    this.video_player = document.querySelector('media-player') as unknown as HTMLVideoElement;
    // this.video_player = document.querySelector('video') as HTMLVideoElement;
    this.video_player.focus();
    (this.isIframe || this.isTeamsBrowser) ? this.video_player.setAttribute('controlsList', "nodownload nofullscreen") : this.video_player.setAttribute('controlsList', "nodownload");

    if (this.authService.company.max_upload_video_height != '720') {
      this.disable_save = false;
    }

    if (this.open_share_popup) {
      // user is coming from meta page, open share popup
      this.sharePopup();
      this.open_share_popup = false;  // only run it once
    } else {
      this.video_player.autoplay = true;
    }

    this.initiateTranslations();
    this.loadViewedTill();

    this.video_player.textTracks.onchange = function (event) {
      console.log(event);
    }
  }

  initiateTranslations() {
    // initial language is always called after player is ready
    // load the user profile language here, instead in init, because the authService data may not be ready
    let user_profile_language = navigator.language;
    if (this.authService.userDetails) {
      user_profile_language = this.authService.userDetails.email_language;
    }
    // if my language is different from video language
    if (user_profile_language != this.current_language) {
      // check for exact match
      let translation_object_index = this.translations.findIndex(ele => ele.language == user_profile_language);
      if (translation_object_index < 0) {
        // exact match not found
        // check for base match, using first two characters of language
        translation_object_index = this.translations.findIndex(ele => ele.language.slice(0, 2) == user_profile_language.slice(0, 2));
        if (translation_object_index < 0) {
          // still not found, check for browser language
          let navigator_language = navigator.language.slice(0, 2);
          translation_object_index = this.translations.findIndex(ele => ele.language.slice(0, 2).toLowerCase() == navigator_language);
        }
      }

      if (translation_object_index > 0) {
        // the found element is not the first object, which is original language
        // something found, translate as needed
        this.changeLanguage(this.translations[translation_object_index]);
        this.snackBar.open(
          this.translateService.instant('Clypp translated'),
          this.translateService.instant('Undo'),
          {duration: 3500, horizontalPosition: 'left'}
        ).onAction().subscribe(() => {
          this.changeLanguage(this.translations[0]);
        });
      } else {
        // translation not found, set the original language
        this.changeLanguage(this.translations[0]);
        // enable the add translation button
        if (!this.is_external_company_video && this.authService.company.is_transcription_service_enabled) {
          const translation_index = this.translations.findIndex(e => e.language == user_profile_language);
          this.show_add_translation_button = translation_index == -1;  // true if not found
        }
      }
    } else {
      // user profile lang is same as video lang, set the original language
      this.changeLanguage(this.translations[0]);
    }

    if (this.show_add_translation_button) {
      // show snackbar
      this.snackBar.open(this.translateService.instant('Translate'),
        this.authService.languages.get(this.authService.userDetails.email_language),
        {duration: 3500, horizontalPosition: 'left'}
      ).onAction().subscribe(() => {
        this.translateToUserLanguage();
      });
    }
  }

  // this method sends a GET call so that backend would know a user is trying to download a video
  registerDownload(type: string = 'mp4') {
    this.dataService.postURL(`user/videos/${this.videoId}/download/`, {type}).subscribe();
  }

  // send a post to translate this video into user's language
  translateToUserLanguage() {
    this.pauseVideo();
    const dialogRef: MatDialogRef<ProgressSpinnerDialogComponent> = this.dialog.open(ProgressSpinnerDialogComponent,
      {
        panelClass: 'transparent',
        disableClose: true,
      }
    );

    this.dataService.postURL<any>(`user/videos/${this.videoId}/translations/`,
      {language: this.authService.userDetails.email_language}, {
        observe: 'body',
        responseType: 'json'
      }
    ).subscribe((res: TranslationDetails) => {
      dialogRef.close();
      this.translations.push(res);
      this.show_add_translation_button = false;
      // change to the last one after a short while, so that tracks are updated due to push operation above
      const _timeout = setTimeout(() => {
        this.changeLanguage(res);
      }, 200);
    }, (err: HttpErrorResponse) => {
      dialogRef.close();
      window.alert(err.error);
    });
  }

  async downloadMP4() {
    const dialogRef: MatDialogRef<ProgressSpinnerDialogComponent> =
      this.dialog.open(ProgressSpinnerDialogComponent, {
        panelClass: 'transparent',
        disableClose: true,
      });

    this.registerDownload('mp4');
    const videoFile = await urlToBlob(this.vidPath);
    const video = new File([videoFile], `${this.videoId}.mp4`);
    const fileLink = document.createElement('a');
    fileLink.href = window.URL.createObjectURL(video);
    fileLink.download = `${this.vidTitle}.mp4`;
    fileLink.click();

    dialogRef.close();
  }

  async downloadWAV() {
    const dialogRef: MatDialogRef<ProgressSpinnerDialogComponent> =
      this.dialog.open(ProgressSpinnerDialogComponent, {
        panelClass: 'transparent',
        disableClose: true,
      });

    this.registerDownload('wav');
    // we do not register a download in audio_file case
    const audio_file = await urlToBlob(this.currentVideo.audio_file);
    const audio = new File([audio_file], `${this.videoId}.wav`);
    const fileLink = document.createElement('a');
    fileLink.href = window.URL.createObjectURL(audio);
    fileLink.download = `${this.videoId}.wav`;
    fileLink.click();

    dialogRef.close();
  }

  async downloadAttachment(): Promise<void> {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', this.attachmentPath);
    link.setAttribute('download', this.attachFileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  async downloadZip(scorm = false): Promise<void> {
    this.pauseVideo();
    const dialogRef: MatDialogRef<ProgressSpinnerDialogComponent> =
      this.dialog.open(ProgressSpinnerDialogComponent, {
        panelClass: 'transparent',
        disableClose: true,
      });

    const zip = new JSZip();
    const videoFile = await urlToBlob(this.vidPath);
    if (scorm) {
      await this.scormFolder(zip, videoFile, this.vidTitle);
      this.registerDownload('scorm');
    } else {
      await this.outerFolder(zip, videoFile);
      this.registerDownload('zip');
    }

    const zipFile = await zip.generateAsync<'blob'>({type: 'blob'});
    // const scormFile = await scorm.generateAsync<'blob'>({type: 'blob'});

    const zipFileUrl = window.URL.createObjectURL(zipFile);

    dialogRef.close();
    const fileLink = document.createElement('a');
    fileLink.href = zipFileUrl;
    fileLink.download = `${this.vidTitle}.zip`;
    fileLink.click();
  }

  async outerFolder(zip, videoFile) {
    const image = new File([await urlToBlob(this.imgPath)], `${this.videoId}.png`);
    zip.file(image.name, image);

    const video = new File([videoFile], `${this.videoId}.mp4`);
    zip.file(video.name, video);

    // download each vtt_file from translations object, including original language
    for (let translation of this.translations) {
      if (translation.vtt_file) {
        const vtt_file = new File([await urlToBlob(translation.vtt_file)],
          `${translation.language}.vtt`);
        zip.file(vtt_file.name, vtt_file);
      }
    }

    // download offline html page
    const htmlContent = this.getOfflineHTML();
    const blob = new Blob([htmlContent], { type: 'text/html' });
    const html_file = new File([blob], `offline_${this.videoId}.html`);
    zip.file(html_file.name, html_file);

    // download embed html page
    const embed_link = environment.baseURL + "embed/video/" + this.videoId;
    const htmlContent2 = `
    <!DOCTYPE html><html><body>
      <iframe
        style='max-width: 100%; border-radius: 10px;'
        width="560" height="315"
        title="${encodeURIComponent(this.vidTitle)}"
        src='${embed_link}' allowfullscreen>
      </iframe>
    </body></html>`;
    const blob2 = new Blob([htmlContent2], { type: 'text/html' });
    const html_file2 = new File([blob2], `online_${this.videoId}.html`);
    zip.file(html_file2.name, html_file2);
  }

  async scormFolder(zip, videoFile, vidTitle) {
    const scormVideo = new File([videoFile], `video.mp4`);
    const scorm = zip;
    // scorm.folder('scorm');
    await this.httpClient.get('assets/scorm/imsmanifest.xml', {responseType: 'text'})
      .subscribe(res => {
        var parser = new xml2js.Parser(
          {
            trim: true,
            explicitArray: true
          });
        parser.parseString(res, function (err, result) {
            result.manifest.organizations[0].organization[0].title[0] = vidTitle;
            result.manifest.organizations[0].organization[0].item[0].title[0] = vidTitle;
            const builder = new xml2js.Builder();
            scorm.file(`imsmanifest.xml`, builder.buildObject(result));
          }
        )
      })

    const api = await this.httpClient
      .get('assets/scorm/SCORM_API_wrapper.js', {responseType: 'blob'})
      .pipe(first())
      .toPromise();

    const html = await this.httpClient
      .get('assets/scorm/video.html', {responseType: 'blob'})
      .pipe(first())
      .toPromise();

    const adlcp_xsd = await this.httpClient
      .get('assets/scorm/adlcp_rootv1p2.xsd', {responseType: 'blob'})
      .pipe(first())
      .toPromise();

    const ims_xsd = await this.httpClient
      .get('assets/scorm/ims_xml.xsd', {responseType: 'blob'})
      .pipe(first())
      .toPromise();

    const imscp_xsd = await this.httpClient
      .get('assets/scorm/imscp_rootv1p1p2.xsd', {responseType: 'blob'})
      .pipe(first())
      .toPromise();

    const imsmd_xsd = await this.httpClient
      .get('assets/scorm/imsmd_rootv1p2p1.xsd', {responseType: 'blob'})
      .pipe(first())
      .toPromise();

    scorm.file(`adlcp_rootv1p2.xsd`, adlcp_xsd);
    scorm.file(`ims_xml.xsd`, ims_xsd);
    scorm.file(`imscp_rootv1p1p2.xsd`, imscp_xsd);
    scorm.file(`imsmd_rootv1p2p1.xsd`, imsmd_xsd);
    scorm.file(`SCORM_API_wrapper.js`, api);
    scorm.file(`video.html`, html);
    scorm.file(`${scormVideo.name}`, scormVideo);
  }

  async downloadPDF(qr) {
    this.pauseVideo();
    const qrImageSrc = qr.qrcElement.nativeElement.querySelector("canvas").toDataURL("image/png");
    const printWindow = window.open('', '', 'width=800,height=600');
    printWindow.document.write(`
    <!DOCTYPE html>
    <html lang="${this.currentVideo.audio_language}">
      <head>
        <title>${this.vidTitle}</title>
        <style>
          body { font-family: 'Nunito', sans-serif; font-size: 20px; padding: 40px; }
           .video-desc img {
          max-width: 80vw; /* Limit the maximum width */
          max-height: 80vh; /* Limit the maximum height */
        }
        </style>
      </head>
      <body>
        <!-- first show title and qr code-->
        <div style="display: flex; justify-content: space-between;">
            <!-- half size for title-->
          <div style="width: 70%;word-wrap: break-word;">
            <h2>${this.currentVideo.title}</h2>
            <p>
                ${this.translateService.instant('Veröffentlicht am')}:&nbsp;
                ${new Date(this.currentVideo.added_on).toLocaleDateString(`${this.currentVideo.audio_language}`)}
            </p>
            <p>
                ${this.translateService.instant('Creator')}:&nbsp;
                ${this.currentVideo.uploader.first_name}&nbsp;${this.currentVideo.uploader.last_name}
            </p>
          </div>
          <div style="width: 30%;display: flex;justify-content: flex-end;align-items: center;">
            <div>
              <img src="${qrImageSrc}" style="max-width: 25vw; max-height: 25vw;" alt="QR Code" />
            </div>
          </div>
        </div>
        <!--desc-->
        <div class="video-desc">${this.formatted_video_desc || this.translateService.instant('Empty')}</div>
        <script type="text/javascript">
          function triggerPrint() {
            window.print();
            window.close();
          }
          window.onload = function() {
            setTimeout(triggerPrint, 50);
          }
        </script>
      </body>
    </html>
    `);
    printWindow.document.close();
  }


  getOfflineHTML(): string {
    let tracks_html: string = "";
    for (let translation of this.translations) {
      if (translation.vtt_file) {
        tracks_html += `<track kind="subtitles" src="${translation.language}.vtt" srclang="${translation.language}">`
      }
    }

    const htmlContent = `
      <!DOCTYPE html>
      <html lang="${this.currentVideo.audio_language}">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>${this.currentVideo.title}</title>
        <style>
          body {
            font-family: 'Nunito', sans-serif;
            font-size: 20px;
            padding: 0px 40px 0px 40px;
          }
          .descDiv {
            padding-top: 10px;
          }
          .descDiv img {
            max-width: 30vw;
            max-height: 30vw;
          }
          .videoView {
            max-height: 70vh;
            aspect-ratio: 16/9;
          }
          .videoView video {
            border: none;
            width: 100%;
            height: 100%;
            border-radius: 10px;
          }
        </style>
      </head>
      <body>
        <div>
          <h2>${this.currentVideo.title}</h2>
          <div class="videoView">
            <video controls autoplay>
              <source src="${this.videoId}.mp4" type="video/mp4">
              ${tracks_html}
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
        <div class="descDiv">
          ${this.formatted_video_desc}
        </div>
      </body>
      </html>
    `;
    return htmlContent;
  }

  likeVideo(): void {
    let like_button = document.getElementById('like-unlike-button') as HTMLButtonElement;
    like_button.disabled = true;  // disable button, as it may take some time
    this.dataService.patchURL<any>(`user/videos/${this.videoId}/like/`,
      {observe: 'body', responseType: 'json'}).subscribe((res) => {
      like_button.disabled = false;  // enable button
      this.likeCounter = res.likes;
      if (res.state == 'liked') {
        this.likeColor = true;
      } else {
        this.likeColor = false;
      }
    }, (err) => {
      window.alert(err.error);
    });
  }

  pauseVideo() {
    // need to check if player exists, otherwise lines after pause() are not executed.
    if (this.video_player) {
      this.video_player.pause();
    }
  }

  showLikes(): void {
    // motivate users
    if (this.likeCounter == 0) {
      let message = this.translateService.instant("Be the first person to like this Clypp");
      this.snackBar.open(message, '', {duration: 2000});
      return;
    }

    // fetch all the likes and show them in members popup
    this.pauseVideo();
    this.dataService.getURL<any>(`user/videos/${this.videoId}/liked-by/`)
      .subscribe((res: UserProfileWithName[]) => {
        const dialogRef = this.dialog.open(MembersListPopupComponent, {
          width: "40vw",
          minWidth: "350px",
          height: "80vh",
          data: {
            res: res,
            adminBadgeTooltip: '',
            title: this.translateService.instant("People who liked this Clypp"),
          }
        });
      }, (err) => {
        window.alert(err.error);
      });
  }

  updateDisplayComments(category = 'all') {
    switch (category) {
      case 'all':
        this.display_comments = this.all_comments;
        break;
      case 'private':
        this.display_comments = this.all_comments.filter(x => x.is_private == true);
        break;
      case 'public':
        this.display_comments = this.all_comments.filter(x => x.is_private == false);
        break;
      case 'mine':
        this.display_comments = this.all_comments.filter(x => x.commented_by.id == this.authService.userDetails.user);
        break;
    }
    this.n_display_comments = this.display_comments.length;
  }

  postComment() {
    this.showEmojiPicker = false;
    if (this.comment_text) {
      const post_body = {
        content: this.comment_text,
        is_private: this.private_comment_toggle
      };
      // send btn will be disabled until this boolean gets false (which is after the completion of http call)
      this.processingCall = true;

      this.dataService.postURL<any>(`user/videos/${this.videoId}/comments/`, post_body,
        {observe: 'body', responseType: 'json'}).subscribe(
        (res: Comment) => {
          this.snackBar.open(this.translateService.instant('Erfolgreich'), '', {duration: 2500});
          // urlify newly added comment
          let modified_res = {...res, content: urlify(this.replaceURLs(res.content))}
          this.all_comments.unshift(modified_res);
          this.updateDisplayComments();
          this.cancelComment();
          this.processingCall = false;
        },
        (err) => {
          window.alert(err.error);
          this.processingCall = false;
        }
      );
    } else {
      this.cancelComment();
    }
  }

  deleteComment(comment) {
    let message = this.translateService.instant("Bist du sicher?");
    if (window.confirm(message)) {
      this.dataService.deleteURL(`user/comments/${comment.id}/`).subscribe((res) => {
        if (res == null) {
          this.snackBar.open(this.translateService.instant('Erfolgreich'), '',
            {duration: 2500}
          );
          const index = this.all_comments.indexOf(comment);
          if (index > -1) { // only splice array when item is found
            this.all_comments.splice(index, 1); // 2nd parameter means remove one item only
          }
          this.updateDisplayComments();
        } else {
          this.snackBar.open(this.translateService.instant('Ein Fehler ist aufgetreten'),
            '', {duration: 2500});
        }
      });
    }
  }

  cancelComment() {
    this.showEmojiPicker = false;
    this.comment_text = '';
    this.private_comment_toggle = false;
    this.update_comment_id = 0;
  }

  editVideo(id) {
    this.router.navigate(['create-video', id, 'review']);
  }

  editComment(comment: Comment) {
    this.update_comment_id = comment.id;
    this.private_comment_toggle = comment.is_private;

    let tempDiv = document.createElement('div');
    // Set the HTML as the innerHTML of the temporary element
    tempDiv.innerHTML = comment.content;
    // Find all anchor tags within the temporary element
    let anchorTags = tempDiv.querySelectorAll('a');
    // Loop through each anchor tag and replace it with its text content
    // because there may be 00:00 like timestamps or urls
    anchorTags.forEach(function (tag) {
      let textContent = document.createTextNode(tag.innerText);
      tag.parentNode.replaceChild(textContent, tag);
    });

    // Get the modified HTML without anchor tags
    this.comment_text = tempDiv.innerHTML;

    // delete the temp element
    tempDiv.remove();
    // scroll up and autofocus on text area
    document.getElementById('comment-div').scrollIntoView();
    document.getElementById('comment-editor').focus();
  }

  // saves an existing comment using update_comment_id
  updateComment() {
    const post_body = {
      content: this.comment_text,
      is_private: this.private_comment_toggle
    };
    this.processingCall = true;
    this.dataService.patchURL<Comment>(`user/comments/${this.update_comment_id}/`, post_body,
      {observe: 'body', responseType: 'json'}).subscribe(
      (res: Comment) => {
        this.snackBar.open(this.translateService.instant('Erfolgreich'), '', {duration: 2500});
        // urlify newly added comment
        const updated_comment_index = this.all_comments.findIndex(el => el.id == this.update_comment_id);
        this.all_comments[updated_comment_index].is_private = res.is_private;
        this.all_comments[updated_comment_index].content = urlify(this.replaceURLs(res.content));
        this.updateDisplayComments();
        this.cancelComment();
        this.processingCall = false;
      },
      (err) => {
        this.processingCall = false;
        window.alert(err.error);
      }
    );
  }

  // a video quality manager can directly update the visibility of the video here
  reviewVideo() {
    this.pauseVideo();
    this.bottomSheet.open(TopicBottomSheetComponent, {
      data: {
        ids: [this.videoId],
        case: 'videos'
      }
    }).afterDismissed().subscribe(res => {
      if (res) {
        window.location.reload();
      }
    });
  }

  addToPlaylist(video): void {
    let playlistPopupWidth: string = '50%';
    if (window.innerWidth < 770) {
      playlistPopupWidth = '85%';
    }
    this.pauseVideo();
    this.dialog.open(AddToPlaylistComponent, {
      width: playlistPopupWidth,
      disableClose: false,
      data: {
        content: JSON.stringify(video),
      },
    });
  }

  copyPlaylistLink() {
    navigator.clipboard.writeText(this.playlist_view_link);
    this.snackBar.open(this.translateService.instant('Link kopiert'), '', {duration: 1000});
  }

  sharePopup() {
    this.pauseVideo();

    const dialogData: PopupData = {
      ...this.currentVideo,
      type: 'video',
    };

    const dialogRef = this.dialog.open(SharePopupComponent, {
      disableClose: true,
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((res: VideoView) => {
      // update current video's data if I am uploader;
      if (res) {
        // this.currentVideo = res;
        this.currentVideo.access_key = res.access_key;
        this.currentVideo.is_external = res.is_external;
        this.currentVideo.is_access_key_needed = res.is_access_key_needed;
        this.currentVideo.is_restricted = res.is_restricted;
        this.currentVideo.departments = res.departments;
        this.currentVideo.locations = res.locations;
        this.currentVideo.teams = res.teams;
        this.currentVideo.users = res.users;
        this.currentVideo.groups = res.groups;
      }
    });
  }

  relatedVideoCLicked(id: string) {
    // save the current playback time
    this.registerViewedTill();
    // go to new video
    this.router.navigate(['view', id]);
  }

  relatedPlaylistClicked(id: number) {
    // save the current playback time
    this.registerViewedTill();
    this.pauseVideo();
    // go to new video
    window.open(`playlistView/${id}`, '_blank');
  }

  playListVideoClick(index) {
    // if index is 1, then play array's 0th video
    this.registerViewedTill();
    this.videoId = this.playlistVideos[index - 1].video.id;
    this.currentPlaylistIndex = index;
    this.loadVideo();
  }

  relatedVideos() {
    this.loading_related_content = true;
    this.dataService.getURL(`user/videos/${this.videoId}/related/`, {observe: 'body', responseType: 'json'})
      .subscribe((data: VideoCard[]) => {
        this.loading_related_content = false;
        this.related_videos = data;
      }, (err) => {
        this.loading_related_content = false;
        console.log(err);
      });
  }

  relatedPlaylists() {
    this.dataService.getURL(`user/videos/${this.videoId}/playlists/`, {observe: 'body', responseType: 'json'})
      .subscribe((data: PlaylistCard[]) => {
        this.related_playlists = data;
      }, (err) => {
        console.log(err);
      });
  }

  videoEnded() {
    console.log('ended');
    this.registerViewedTill();
    if (this.is_repeat_on) {
      this.video_player.play();
    }
    else if (this.isAutoplay) {
      // play next video in the playlist
      if (this.playlistVideos.length) {
        // play next, circular list
        if (this.currentPlaylistIndex < this.playlistVideos.length) {
          // if length is 10 videos, then this condition is valid until 9th video
          this.playListVideoClick(this.currentPlaylistIndex + 1);
        } else {
          this.playListVideoClick(1);
        }
      }
    }
  }

  // change is_repeat_on & autoPlay
  toggleRepeat() {
    this.is_repeat_on = !this.is_repeat_on;
    if (this.is_repeat_on) {
      this.isAutoplay = false;
    }
  }

  loadComments() {
    this.dataService.getURL(`user/videos/${this.videoId}/comments/`, {observe: 'body', responseType: 'json'})
      .subscribe((data: Comment[]) => {

        // urlify comments
        this.all_comments = data.map((ele: Comment) => {
          return {...ele, content: urlify(this.replaceURLs(ele.content))}
        });

        this.display_comments = this.all_comments;
        this.n_display_comments = this.all_comments.length;
        // this.getHeightOfDiv();
      });
  }

  toggleEmojiPicker() {
    this.showEmojiPicker = !this.showEmojiPicker;
  }

  addEmoji(emoji: string) {
    let inputElement: HTMLTextAreaElement = this.emojiFieldInput.nativeElement as HTMLTextAreaElement;
    this.comment_text =
      this.comment_text.slice(0, inputElement.selectionStart) +
      emoji +
      this.comment_text.slice(inputElement.selectionEnd);
    this.showEmojiPicker = false;
    inputElement.focus();
  }

  mouseovered(video) {
    if (video.gif) {
      // swap them
      var temp = video.thumbnail;
      video.thumbnail = video.gif;
      video.gif = temp;
    }
  }

  mouseouted(video) {
    // swap them again
    this.mouseovered(video);
  }

  close_gallery() {
    this.closeGallery = false;
    document.getElementsByClassName('mat-sidenav')[0]['style'].visibility = 'visible';
  }

  move_img(event) {
    if (event.keyCode == 37)
      this.move_left();
    if (event.keyCode == 39)
      this.move_right();
  }

  move_left() {
    if (this.index > 0) {
      this.index -= 1;
      this.src = this.srcs[this.index]
    }
  }

  move_right() {
    if (this.index < this.srcs.length - 1) {
      this.index += 1;
      this.src = this.srcs[this.index]
    }
  }

  openLink(i) {
    this.closeGallery = true;
    this.index = i;
    this.src = this.srcs[i];
    document.getElementsByClassName('mat-sidenav')[0]['style'].visibility = 'hidden';
  }

  getImageUrls() {
    let m: RegExpExecArray | null;
    let rex: RegExp = /<img.*?src="([^">]*\/([^">]*?))".*?>/g;
    this.srcs = [];
    while (m = rex.exec(this.formatted_video_desc)) {
      this.srcs.push(m[1]);
    }
  }

  getClickEvent(event) {
    if (event.target.localName == 'img') {
      let index = this.srcs.findIndex(x => x == event.srcElement.attributes[0].nodeValue);
      this.openLink(index);
    } else if (event.target.localName == 'a') {
      this.changeCurrentVidTime(event.target.innerText);
    }
  }

  tagSelected(tags) {
    this.router.navigate(['tag', tags.id]);
  }

  changeLanguage(translation: TranslationDetails) {
    this.currentVideo.title = translation.title;
    this.formatted_video_desc = translation.desc;
    this.current_language = translation.language;
    this.getImageUrls();
    this.urlifyDescriptionTimestamps();

    // update track of player
    if (this.video_player != null) {
      // todo: for dev: _items, else A
      this.video_player.textTracks['A'].forEach((track: any) => {
        if (track.kind == 'subtitles') {
          if (track.label == this.current_language) {
            track.mode = "showing";
          } else {
            track.mode = "hidden";
          }
        }
      });
    }
  }

  // called after video is data is fetched
  // clear the existing translations and adds the new one
  addOriginalLang() {
    this.current_language = '';
    this.translations = []; // to clean the data after changing video
    this.show_add_translation_button = false;
    this.translations.push({
      audio_file: null,
      created_on: null,
      desc: this.currentVideo.desc,
      id: null,
      language: this.currentVideo.audio_language,
      title: this.currentVideo.title,
      video: this.currentVideo.id,
      vtt_file: this.currentVideo.vtt_file
    })
  }

  // on click of a timestamp, this method seeks to correct time!
  changeCurrentVidTime(innerText: string) {
    let cond1 = innerText.match(this.make_two_step_Url) && innerText.match(this.make_two_step_Url).length > 0;
    let cond2 = innerText.match(this.make_three_step_Url) && innerText.match(this.make_three_step_Url).length > 0
    if (cond1) {
      // condition 1 is always true for 00:00 or 00:00:00 like timestamps
      this.scroll.nativeElement.scrollIntoView({behavior: 'smooth'});
      const time_strings = innerText.split(':')
      let time_seconds: number = 0;
      try {
        // find seconds
        if (cond2) {
          // check this one first, as cond1 is always true
          time_seconds = parseInt(time_strings[0]) * 60 + parseInt(time_strings[1]) * 60 + parseInt(time_strings[2]);
        } else {
          // condition 1 applies
          time_seconds = parseInt(time_strings[0]) * 60 + parseInt(time_strings[1]);
        }
      } catch {
        // do nothing in case of error
      }

      if (time_seconds >= this.currentVideo.duration) {
        time_seconds = this.currentVideo.duration;
      }
      this.video_player.currentTime = time_seconds;
      this.video_player.play();
    }
  }

  urlifyDescriptionTimestamps() {
    // let text = urlify(this.formatted_video_desc);
    this.formatted_video_desc = this.replaceURLs(this.formatted_video_desc);
  }

  replaceURLs(message) {
    if (!message) return;
    let compared_with_first_reg = this.makeUrl(message, this.match_three_step_timeStamp, this.make_three_step_Url);
    return this.makeUrl(compared_with_first_reg, this.match_two_step_timeStamp, this.make_two_step_Url)
  }

  makeUrl(text, pattern1, pattern2) {
    return text.replace(pattern1, function (url) {
      let new_url = url.substring(0, url.indexOf(' '));
      if (new_url.match(pattern2) && new_url.match(pattern2).length > 0)
        return '<a>' + new_url + '</a> ' + url.substring(url.indexOf(' ') + 1)
      else
        return url
    });
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(e: KeyboardEvent) {
    if (e.key == ' ' || e.key == 'ArrowRight' || e.key == 'ArrowLeft') {
      if (e.target['nodeName'] != "INPUT" && e.target['nodeName'] != "TEXTAREA" && e.target['nodeName'] != "MAT-SELECT" && !this.closeGallery) {
        e.preventDefault();
        e.stopPropagation();
        switch (e.key) {
          case 'ArrowRight':
            this.video_player.currentTime += 5;
            this.scroll.nativeElement.scrollIntoView({behavior: 'smooth'});
            break;
          case 'ArrowLeft':
            this.video_player.currentTime -= 5;
            this.scroll.nativeElement.scrollIntoView({behavior: 'smooth'});
            break;
        }
      } else if (this.closeGallery) {
        switch (e.key) {
          case 'ArrowRight':
            this.move_right();
            break;
          case 'ArrowLeft':
            this.move_left();
            break;
        }
      }
    }
  }

  ngOnDestroy(): void {
    try {
      // this try catch block is necessary as firefox does not support exitPictureInPicture
      // this.observer.disconnect();
      document.exitPictureInPicture().catch((e) => {
      });
      clearTimeout(this.timeoutHandler);
      this.timedOut = false;
    } catch (e) {
      console.log(e);
    }
    this.registerViewedTill();
  }

  // this method loads the video_till from video history
  loadViewedTill(): void {
    this.dataService.getURL(`user/videos/${this.videoId}/download/`, {
      observe: 'body',
      responseType: 'text'
    }).subscribe((res: string) => {
      const viewed_till: number = parseFloat(res);
      this.current_viewed_till = viewed_till;
      // seek video, if there is still 10 seconds left
      if (this.video_player.duration - this.current_viewed_till > 10) {
        this.video_player.currentTime = this.current_viewed_till;
      }
    });
  }

  @HostListener('window:beforeunload')
  registerViewedTill(): void {
    // if the video player is ready, then, also save how much video has been viewed
    if (this.video_player) {
      // make sure that player is not null
      if (this.video_player.currentTime > this.current_viewed_till) {
        this.current_viewed_till = this.video_player.currentTime;
        const patch_body = {
          viewed_till: this.current_viewed_till
        };
        this.dataService.patchURL(`user/videos/${this.videoId}/download/`, patch_body).subscribe();
      }
    }
  }

  // redirect to edit playlist
  editPlaylist() {
    this.router.navigate(['createPlaylistMyVideos', this.playlist_object.id])
  }

  // this method avoid repetition of put call for playlist
  managerPlaylistAction(action = 'draft') {
    let message = `${this.playlist_object.uploader.first_name} ${this.playlist_object.uploader.last_name} `;
    message += this.translateService.instant("will also be notified about this.");
    message += '\n\n';
    message += this.translateService.instant("Would you like to continue?");

    if (window.confirm(message)) {
      this.dataService.putURL(`manager/playlists/?pl_id=${this.playlist_object.id}`,
        {action: action, uploader: 0},
        {observe: 'body', responseType: 'json'}).subscribe((res) => {
        // show snackbar
        this.snackBar.open(this.translateService.instant('Erfolgreich'), '', {duration: 2000});

        // go back if drafting a video
        if (action == 'draft') {
          // go to dashboard
          this.router.navigate(['dashboard', 'content', 'playlists']);
        } else if (action == 'feature') {
          this.playlist_object.is_featured = true;
        } else if (action == 'unfeature') {
          this.playlist_object.is_featured = false;
        }
        // no else case yet
      }, (err) => {
        window.alert(err.error);
      });
    }
  }

  navigateUserPage(user_id) {
    this.router.navigate(['user', user_id]);
  }

  // used to download a playlist's qr code
  downloadQR(parent: any) {
    let parentElement = parent.qrcElement.nativeElement.querySelector("canvas").toDataURL("image/png");
    // converts base 64 encoded image to blobData
    let blobData = convertBase64ToBlob(parentElement);
    // saves as image
    const blob = new Blob([blobData], {type: "image/png"})
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement("a")
    link.href = url
    // name of the file
    link.download = `${this.playlist_object.title}.png`;
    link.click();
  }
}

export function urlToBlob(url: string): Promise<Blob> {
  return fetch(url, {
    method: 'GET',
    headers: new Headers({}),
  }).then((res) => {
    return res.blob();
  });
}
