<div class="row m-0 p-xl-4 p-2" style="overflow-x: hidden">

  <div class="col-lg-6 col-md-12 col-sm-12 col-12 d-inline-block" style="min-width: 300px">
    <div class="d-flex justify-content-between flex-wrap mb-3 mt-2">
      <h2 class="fontStyle d-flex align-items-center" translate>Workspace statistics</h2>
    </div>
    <div class="d-flex justify-content-around" style="flex-wrap: wrap;">
      <div class="simple-card-analytics">
        <h4 class="text-nunito m-0" translate>
          <mat-icon class="material-icons-outlined vertical-align-text-bottom">
            play_circle
          </mat-icon>
          Total Clypps
        </h4>
        <h3 class="text-nunito m-0 font-weight-bolder text-color">
          {{ n_videos }}
        </h3>
      </div>

      <div class="simple-card-analytics">
        <h4 class="text-nunito m-0" translate>
          <mat-icon class="material-icons-outlined vertical-align-text-bottom">
            person
          </mat-icon>
          Total users
        </h4>
        <h3 class="text-nunito m-0 font-weight-bolder text-color">
          {{ n_users }}
        </h3>
      </div>

      <div class="simple-card-analytics">
        <h4 class="text-nunito m-0" translate>
          <mat-icon class="material-icons-outlined vertical-align-text-bottom">
            group
          </mat-icon>
          Total Creators
        </h4>
        <h3 class="text-nunito m-0 font-weight-bolder text-color">
          {{ n_creators }}
        </h3>
      </div>
    </div>

    <!--activity chart-->
    <div class="mt-4">
      <div class="d-flex justify-content-between mx-2">
        <!--select parameter-->
        <mat-form-field appearance="outline">
          <mat-select (selectionChange)="activitySelect($event)" [(ngModel)]="parameter">
            <mat-option value="activities">{{ 'Activities'|translate }}</mat-option>
            <mat-option value="users">{{ 'Active users'|translate }}</mat-option>
            <mat-option value="views">{{ 'Video views'|translate }}</mat-option>
            <mat-option value="videos">{{ 'Videos created'|translate }}</mat-option>
            <mat-option value="duration">{{ 'Video duration sum'|translate }}</mat-option>
          </mat-select>
        </mat-form-field>

        <!--duration window select-->
        <mat-form-field appearance="outline">
          <mat-select (selectionChange)="durationSelect($event)" [(ngModel)]="window">
            <mat-option value="7D">{{ '7 days' | translate }}</mat-option>
            <mat-option value="30D">{{ '30 days' | translate }}</mat-option>
            <mat-option value="90D">{{ '90 days' | translate }}</mat-option>
            <mat-option value="YTD">{{ 'Year to date' | translate }}</mat-option>
            <mat-option value="1Y">{{ '1 year' | translate }}</mat-option>
            <mat-option value="MAX">{{ 'Max' | translate }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!--chart-->
      <span *ngIf="!is_authorised" translate>
        You need global or company management rights
      </span>
      <div *ngIf="is_authorised" class="d-flex">
        <canvas class="p-2" id="canvas" style="width: 100%; height: 375px;"></canvas>
      </div>
    </div>
  </div>

  <!-- Recent activities-->
  <div *ngIf="is_authorised" class="col-lg-6 col-md-12 col-sm-12 col-12 d-inline-block">
    <app-activities-table></app-activities-table>
  </div>
</div>
