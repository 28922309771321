import {Component, Input, OnInit} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {Activity, ActivityDisplayData, ActivityMap} from "../../models/activity.interface";
import {ChecklistItem} from "../../models/video/video.interface";
import {FormControl} from "@angular/forms";
import {Observable} from "rxjs";
import {DataService} from "../../services/data.service";
import {TranslateService} from "@ngx-translate/core";
import {UtilityService} from "../../services/utility.service";
import {map, startWith} from 'rxjs/operators';
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {AuthService} from "../../services/auth.service";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-activities-table',
  templateUrl: './activities-table.component.html',
  styleUrls: ['./activities-table.component.scss']
})
export class ActivitiesTableComponent implements OnInit {
  @Input('uuid_link') uuid_link: string = '';  // optional input for a particular video or topic

  inProgress: boolean = false;
  german_labels: boolean = false;  // used for activities
  displayedColumns: string[] = ['user', 'time'];
  currentPage: number = 0;
  resultsLength: number = 0;
  dataSource: MatTableDataSource<ActivityDisplayData>;
  user_list: ChecklistItem[] = [];  // to store all users
  filter_user_id: number = 0;  // user filter
  filter_activity_code: string = "";  // activity filter
  activityFormControl = new FormControl();
  user_name_form_control = new FormControl();
  filteredOptions: Observable<ChecklistItem[]>;
  filteredActivities: Observable<ActivityMap[]>;
  activityJSONResp: ActivityMap[] = [];
  displayActivityJsonMap: ActivityMap[] = [];

  constructor(private dataService: DataService,
              private authService: AuthService,
              private translateService: TranslateService,
              protected utilityService: UtilityService
  ) {
    if (this.translateService.currentLang == 'de') {
      this.german_labels = true;
    }
  }

  ngOnInit(): void {
    this.loadChecklistUserData();
    this.loadAllActivities();
    this.recentActivities();
  }

  // to get the activity code JSON from assets
  // async method
  loadAllActivities() {
    // load from json
    this.dataService.getActivityJson().subscribe((res: ActivityMap[]) => {
      this.activityJSONResp = res;
    });
  }

  // synchronous method
  loadChecklistUserData() {
    // first, add default value
    this.user_list.push({
      id: 0,
      name: this.translateService.instant('All users'),
      string: this.translateService.instant('All users'),
      type: 'user'
    });
    // filter users from loaded data
    if (this.authService.checklist_data.length) {
      // data is already loaded
      this.user_list.push(...this.authService.checklist_data.filter((item) => item.type == 'user'));
    }
    // todo: this does not load all names if user refreshes on the activities page,
    //  because checklist above may not be ready

    // it might be possible that some users do not have FN LN
    // replace the name with email
    this.user_list.map(element => {
      if (element.name == "") {
        element.name = element.string;  // this performs in-place editing
      }
    });
  }

  onActivitySelected(event: MatAutocompleteSelectedEvent) {
    this.filter_activity_code = event.option.value;
    this.recentActivities();
  }

  onUserSelected(event, userId) {
    if (event.isUserInput) {
      this.filter_user_id = userId;
      this.recentActivities();
    }
  }

  // displayFn method to return the value to display
  activityDisplayFn(activityCode: string): string {
    const selectedObject = this.activityJSONResp.find(obj => obj.activity_code === activityCode);
    if (selectedObject) {
      // return the display value based on the object properties
      return this.german_labels ? `${selectedObject.prefix_de} ${selectedObject.link_de} ${selectedObject.suffix_de}` :
        `${selectedObject.prefix_en} ${selectedObject.link_en} ${selectedObject.suffix_en}`;
    }
  }

  // activity table API call
  recentActivities(page: number = 1) {
    let query: string = `manager/activities/?page=${page}`;
    if (this.uuid_link) {
      query += `&uuid_link=${this.uuid_link}`;
    }
    // append user filter
    if (this.filter_user_id) {
      query += `&user_id=${this.filter_user_id}`;
    }
    // append activity filter
    if (this.filter_activity_code) {
      query += `&activity_code=${this.filter_activity_code}`;
    }

    this.inProgress = true;
    this.dataService.getURL<any>(query).subscribe((response) => {
      // mapping the recent activities response with the activity code JSON
      const data: Activity[] = response.data;

      if (page == 1) {
        const distinct_activity_codes_set: string[] = response.distinct_activity_codes_set;
        let distinct_users_set: number[] = response.distinct_users_set;
        distinct_users_set.push(0);  // for all users case
        if (this.filter_user_id == 0 && this.filter_activity_code == '') {
          // if both form controls are empty, then update both
          this.displayActivityJsonMap = this.activityJSONResp.filter(e => distinct_activity_codes_set.includes(e.activity_code));
          // attach filter
          this.filteredActivities = this.activityFormControl.valueChanges.pipe(
            startWith(''),
            map(value => this._filterActivity(value || ''))
          );

          this.user_list = this.user_list.filter(e => distinct_users_set.includes(e.id));
          // attach filter
          this.filteredOptions = this.user_name_form_control.valueChanges.pipe(
            startWith(''),
            map(value => this._filterUser(value || ''))
          );
        } else if (this.filter_user_id == 0) {
          // if the activityFormControl is filled and user_name_form_control is empty, then update users
          this.user_list = this.user_list.filter(e => distinct_users_set.includes(e.id));
          // attach filter
          this.filteredOptions = this.user_name_form_control.valueChanges.pipe(
            startWith(''),
            map(value => this._filterUser(value || ''))
          );
        } else if (this.filter_activity_code == '') {
          // if the user_name_form_control is filled and activityFormControl is empty, then update activities
          this.displayActivityJsonMap = this.activityJSONResp.filter(e => distinct_activity_codes_set.includes(e.activity_code));
          // attach filter
          this.filteredActivities = this.activityFormControl.valueChanges.pipe(
            startWith(''),
            map(value => this._filterActivity(value || ''))
          );
        } else {
          // else both are filled, clear both
          // this.displayActivityJsonMap = this.activityJSONResp.filter(e => true);
        }
      }

      this.currentPage = response.pageIndex - 1;
      const mapResult: ActivityDisplayData[] = data.map(el => {
        // create a basic structure
        let temp: ActivityDisplayData = {
          first_name: el.user.first_name,
          last_name: el.user.last_name,
          email: el.user.email,
          prefix: '',
          link_text: el.activity_code,
          suffix: '',
          supporting_text: '',
          href: '',
          created_on: el.created_on
        };

        // find the correct map
        const map = this.activityJSONResp.find(act => act.activity_code == el.activity_code);
        // if map is found, use data as per language
        if (map) {
          if (this.german_labels) {
            temp.prefix = map.prefix_de;
            temp.link_text = map.link_de;
            temp.suffix = map.suffix_de;
          } else {
            temp.prefix = map.prefix_en;
            temp.link_text = map.link_en;
            temp.suffix = map.suffix_en;
          }
          temp.supporting_text = map.hover ? el.supporting_text : "";
          if (map.link) {
            // not empty
            temp.href = `${environment.baseURL}${map.link_to}`;
            temp.href += map.link == 'id' ? `${el.id_link}` : el.uuid_link;
          }
        }
        return temp;
      });
      this.dataSource = new MatTableDataSource(mapResult);
      // this.dataSource.connect().next(mapResult);
      this.resultsLength = response.length;
      this.inProgress = false;
    }, (err) => {
      this.inProgress = false;
      window.alert(err.error.detail);
    });
  }

  // pagination event
  updatePage(event): void {
    const new_page = event.pageIndex + 1;  // because it starts from 0
    this.recentActivities(new_page);
  }

  private _filterUser(name: string): ChecklistItem[] {
    const filterValue = name.toLowerCase().trim();
    return this.user_list.filter(
      option => option.name.toLowerCase().indexOf(filterValue) === 0
    );
  }

  private _filterActivity(name: string): ActivityMap[] {
    const filterValue = name.toLowerCase().trim();
    return this.displayActivityJsonMap.filter(option => {
      const spanValue = this.getSpanValue(option); // Define a method to get the span value
      return spanValue.toLowerCase().includes(filterValue);
    });
  }

  private getSpanValue(option: ActivityMap): string {
    if (this.german_labels) {
      return `${option.prefix_de} ${option.link_de} ${option.suffix_de}`;
    } else {
      return `${option.prefix_en} ${option.link_en} ${option.suffix_en}`;
    }
  }
}
