import {Component, OnInit, OnDestroy} from '@angular/core';
import { environment } from '../../environments/environment';
import {MiniDetails, VideoCard} from '../models/video/video.interface';
import { AuthService } from '../services/auth.service';
import { DataService } from '../services/data.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import {Location} from '@angular/common';
import {Company} from "../models/interfaces/auth-response";
import {HttpErrorResponse} from "@angular/common/http";

@Component({
  selector: 'company-wise-video',
  templateUrl: './company-wise-video.component.html',
  styleUrls: ['./company-wise-video.component.scss']
})

export class CompanyWiseVideoComponent implements OnInit, OnDestroy {
  videos: VideoCard[] = [];
  backendUrl: string;
  company_id: string;
  tag_id: number = -1;
  showSpinner: boolean = false;
  page: number = 1;
  maxPages: number = 0;
  featured_tags: MiniDetails[] = [];
  video_case: boolean = true;  // tag case if false
  sort_option: string = 'added_on';
  search_text: string = "";
  query_param_subscription: any;
  language_ch_subscription: any;

  constructor(public dataService: DataService, private translateService: TranslateService,
    public route: ActivatedRoute, private router: Router,
    private location: Location,
    public authService: AuthService) {
    this.company_id = this.route.snapshot.paramMap.get('company_id');
  }

  ngOnInit(): void {

    // this.location.replaceState("/some/newstate/");
    // on init, load the featured tags first
    this.getFeaturedTags();

    // this subscription is needed, as navbar service will just change the query parameters
    this.query_param_subscription = this.route.queryParams.subscribe(params => {
      //setting the search and sort params from route
      if (params.search_text !== undefined) {
        this.search_text = params.search_text;
      }

      if (params.sort !== undefined) {
        this.sort_option = params.sort;
        // todo: make sure that the option is valid
      }

      if (params.tag !== undefined) {
        // tag case
        // todo: in this case, company name would not load
        // this.authService.company.id = parseInt(this.company_id);
        this.getTagVideos(parseInt(params.tag));
      }
      else{
        // all videos case
        this.getPublicVideos(1);
      }
    });

    this.backendUrl = environment.backendURL;

    const node = document.getElementById("search_input");
    node.addEventListener("keyup", function(event) {
      if (event.key === "Enter") {
        document.getElementById("search_icon").click();
      }
    });

    this.language_ch_subscription = this.translateService.onLangChange.subscribe(() => {
      if(this.video_case){
        this.getPublicVideos(1);
      }
      else {
        this.getTagVideos(this.tag_id);
      }
    });
  }

  getTagVideos(tag_id){
    this.tag_id = tag_id;
    this.video_case = false;
    this.search_text = "";
    this.showSpinner = false;
    // we can send language in the header so that the video title would be in desired language
    this.dataService.getURL(`public/tags/${tag_id}/`, {
      observe: 'body', responseType: 'json', headers: {
        'Accept-Language': this.translateService.currentLang
      }
    }).subscribe((res: { name: string, company: Company, data: VideoCard[] }) => {
      this.authService.company = res.company;
      this.videos = res.data;
      // update the address
      this.location.replaceState(`/public/${this.company_id}?tag=${tag_id}`);
      this.showSpinner = false;
    }, (error)=>{
      window.alert(error.error);
      this.showSpinner = false;
      // redirect to login page, if ext sharing is disabled
      this.router.navigate(['login']);
    });
  }

  getPublicVideos(page): void {
    this.video_case = true;
    this.tag_id = -1; // to make sure no tag is selected
    this.location.replaceState(`/public/${this.company_id}?sort=${this.sort_option}&search_text=${encodeURIComponent(this.search_text)}`);
    let queryConstruct = `public/videos/${this.company_id}/?sort_by=${this.sort_option}&page=${page}&search_query=${this.search_text}`;
    this.showSpinner = true;
    this.dataService.getURL(queryConstruct, {
      observe: 'body', responseType: 'json', headers: {
        'Accept-Language': this.translateService.currentLang
      }
    }).subscribe((resp: any) => {
      this.showSpinner = false;
      if (resp['response'] == 'failure') {
        window.alert(resp['reason']);
        // redirect to login page, if ext sharing is disabled
        this.router.navigate(['login']);
      } else {
        this.authService.company = resp['company'];
        if (page == 1) {
          this.videos = resp['data'];
          this.page = resp.pageIndex;
          this.maxPages = Math.ceil(resp.length / resp.pageSize);
          if (this.maxPages > 1) {
            this.onScrollDown();
            // this condition is to avoid not loading more videos, when screen is too big and user can't scroll down
          }
        } else {
          this.page = resp.pageIndex;
          this.videos.push(...resp['data']);
        }
      }
    }, (err: HttpErrorResponse) => {
      if (err.status == 429) {
        window.alert(err.error.detail);
      } else {
        window.alert(err);
      }
      this.showSpinner = false;
    });
  }

  // tags for public videos
  getFeaturedTags(): void {
    this.dataService.getURL(`public/featured-tags/${this.company_id}/`, {
      observe: 'body', responseType: 'json'
    }).subscribe((data: MiniDetails[]) => {
      this.featured_tags = data;
    }, (error) => {
      console.error(error.error);
      this.showSpinner = false;
    });
  }

  onScrollDown() {
    if (this.video_case) {
      this.page += 1;
      if (this.page <= this.maxPages) {
        this.getPublicVideos(this.page);
      }
    }
  }

  ngOnDestroy(): void {
    this.query_param_subscription.unsubscribe();
    this.language_ch_subscription.unsubscribe();
  }
}
