<div class="mx-5 mt-2">
  <div class="py-3 d-flex align-items-end flex-wrap">
    <mat-card-title class="fontStyle mr-4">
      {{ 'Keyword' | translate }}:&nbsp;{{ tagName }}
    </mat-card-title>

    <button (click)="backTo();" *ngIf="mode == 'view'" class="border-radius-20" mat-stroked-button>
      <mat-icon class="mr-1">
        chevron_left
      </mat-icon>
      {{ "Back" | translate }}
    </button>
  </div>

  <mat-card-subtitle class="text-nunito mt-1">
    {{ 'n_items under tag_name' | translate:{'n_items': all_tag_content.length, 'tag_name': tagName} }}
  </mat-card-subtitle>

  <!--newest content of selected tag-->
  <div *ngIf="newest_tag_content.length" class="p-2 bg-quiz border-radius-10 my-3">
    <div class="d-flex justify-content-between align-items-center py-1">
      <h2 class="d-flex align-items-center fontStyle m-0">
        <mat-icon class="material-icons-outlined mr-2" color="primary">grade</mat-icon>
        {{ 'Newly added'|translate }}&nbsp;{{ 'in the last 30 days'|translate }}
      </h2>
    </div>

    <!--newest content-->
    <div class="d-flex justify-content-start left-right-div" id="mixed-tag">
      <ng-container *ngFor="let card of newest_tag_content">
        <app-video-card *ngIf="card.type == 'video'" [mode]="mode" [videoObject]="card.data"
                        style="margin-right: 20px; max-width: 320px;">
        </app-video-card>

        <app-topic-card *ngIf="card.type == 'topic'" [mode]="mode" [topic_object]="card.data"
                        style="max-width: 320px; margin-right: 20px;">
        </app-topic-card>
      </ng-container>

      <!--button 1 with absolute position to the left-->
      <button (click)="scrollToLeftRight('mixed-tag',true);" *ngIf="newest_tag_content.length >1"
              class="left-right-button left-scroll-button" mat-button>
        <mat-icon class="text-white">
          chevron_left
        </mat-icon>
      </button>
      <!--button 2 with absolute position to the left-->
      <button (click)="scrollToLeftRight('mixed-tag');" *ngIf="newest_tag_content.length >1"
              class="left-right-button right-scroll-button" mat-button>
        <mat-icon class="text-white">
          chevron_right
        </mat-icon>
      </button>
    </div>
  </div>

  <!-- all content filter -->
  <div class="d-flex justify-content-between px-3 flex-wrap bg-white position-sticky" id="discover-tags"
       style="top: 0; z-index: 11;">
    <div class="d-flex justify-content-start align-items-center mr-3">
      <mat-icon class="material-icons-outlined mr-2" color="primary">video_library</mat-icon>
      <h2 class="fontStyle m-0">
        {{ 'All content from' | translate }}&nbsp;{{ tagName }}
      </h2>
    </div>

    <div class="d-flex justify-content-start flex-wrap">
      <!--search-->
      <mat-form-field appearance="outline" class="mr-2">
        <mat-label translate>Search for…</mat-label>
        <input (keyup)="applySearchFilter();" [(ngModel)]="searchText" autocomplete="off" matInput
               placeholder="{{'Titel' | translate}}">
      </mat-form-field>
      <!--sort-->
      <mat-form-field appearance="outline" class="mr-2">
        <mat-select (selectionChange)="applySearchFilter();" [(ngModel)]="sort_option">
          <mat-option value="added_on">{{ 'Newest'|translate }}</mat-option>
          <mat-option value="last_edited_on">{{ 'Last edited'|translate }}</mat-option>
          <mat-option value="views">{{ 'Most viewed'|translate }}</mat-option>
        </mat-select>
      </mat-form-field>
      <!--filter-->
      <mat-form-field appearance="outline">
        <mat-select (selectionChange)="applySearchFilter();" [(ngModel)]="filter_option">
          <mat-option value="all">{{ 'Alle'|translate }}</mat-option>
          <mat-option value="featured">{{ 'Hervorgehoben'|translate }}</mat-option>
          <mat-option value="external">{{ 'Public'|translate }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <!-- all pages & videos of tag -->
  <div *ngIf="filtered_tag_content.length" class="d-flex justify-content-start flex-wrap mb-3 pl-2"
       id="filtered_tag_content">
    <ng-container *ngFor="let card of filtered_tag_content">
      <app-video-card *ngIf="card.type == 'video'" [mode]="mode" [videoObject]="card.data"
                      style="margin-right: 20px; max-width: 320px;">
      </app-video-card>
      <app-topic-card *ngIf="card.type == 'topic'" [mode]="mode" [topic_object]="card.data"
                      style="margin-right: 20px; max-width: 320px;">
      </app-topic-card>
    </ng-container>
  </div>

  <!-- No videos/pages found -->
  <div *ngIf="filtered_tag_content.length == 0" style="display: flex;
    align-items: center;
    height: 200px;
    position: relative;
    justify-content: center;">
    <h3>
      {{ 'No results. Search in other categories or'|translate }}
      <u (click)="navbarService.openVideoRequestDialog(searchText);" class="pointer">
        {{ 'ask your colleagues'|translate }}
      </u>
    </h3>
  </div>
</div>
