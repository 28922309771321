import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {TranslateService} from '@ngx-translate/core';
import {TopicCard} from 'src/app/models/video/video.interface';
import {AuthService} from 'src/app/services/auth.service';
import {DataService} from 'src/app/services/data.service';
import {NavbarService} from 'src/app/services/navbar.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatSort} from '@angular/material/sort';
import {MatBottomSheet} from "@angular/material/bottom-sheet";
import {TopicBottomSheetComponent} from "../../shared/topic-bottom-sheet/topic-bottom-sheet.component";
import {UploaderChangeDialogComponent} from "../uploader-change-dialog/uploader-change-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {HttpErrorResponse} from "@angular/common/http";
import {BreakpointObserver} from '@angular/cdk/layout';


@Component({
  selector: 'app-topic-page-management',
  templateUrl: './topic-page-management.component.html',
  styleUrls: ['./topic-page-management.component.scss']
})
export class TopicPageManagementComponent implements OnInit,AfterViewInit {
  authorised: boolean = false; // profile.is_global_manager or profile.is_company_manager or profile.is_quality_manager
  searchQuery: string = "";
  filterValue: string = 'published';
  showSpinner: boolean = false;
  topics: TopicCard[] = [];
  filteredTopics: TopicCard[] = [];
  options = {
    observe: 'body', responseType: 'json'
  };
  snackbarConfig = {duration: 2500};
  dataSource: MatTableDataSource<TopicCard>;
  selection = new SelectionModel<any>(true, []);
  currentlyDisplayedColumns: string[] = [];
  displayedColumnsLargeScreen: string[] = [
    'select',
    'title',
    'added_on',
    'full_name',
    'views',
    'restrictions',
    'is_held_for_review',
  ];

  displayedColumnsSmallScr: string[] = ['select', 'title', 'full_name', 'is_held_for_review'];

  @ViewChild(MatSort) sort: MatSort;

  constructor(private translateService: TranslateService,
              public navbarService: NavbarService,
              private snackBar: MatSnackBar,
              private dialog: MatDialog,
              private authService: AuthService,
              private bottomSheet: MatBottomSheet,
              private dataService: DataService,
              private breakpointObserver: BreakpointObserver) {
  }

  ngOnInit(): void {
    this.authorised = this.authService.userDetails.is_team_manager || this.authService.userDetails.is_quality_manager;
    // global manager is also a company manager
    if (this.authorised) {
      this.getTopics();
    }
  }

  getTopics() {
    this.showSpinner = true;
    this.dataService.getURL('manager/topics/', this.options).subscribe((res: TopicCard[]) => {
        this.topics = res;
        this.updateFilter();  // this will load the table data source, default to published
        this.dataSource.sort = this.sort;
        this.showSpinner = false;
      },
      (err) => {
        this.handleError(err);
      }
    )
  }

  ngAfterViewInit(): void {
    this.breakpointObserver.observe(['(max-width: 700px)']).subscribe((res) => {
      if (res.matches) {
        //hide views & likes for small screens
        this.currentlyDisplayedColumns = this.displayedColumnsSmallScr;
      } else {
        this.currentlyDisplayedColumns = this.displayedColumnsLargeScreen;
      }
    });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.dataSource.data);
  }

  applyFilter(): void {
    this.dataSource.filter = this.searchQuery.trim().toLowerCase();
  }

  updateFilter() {
    switch (this.filterValue) {
      case 'published':
        this.filteredTopics = this.topics.filter(r => !r.is_draft);
        break;
      case 'featured':
        this.filteredTopics = this.topics.filter(r => r.is_featured);
        break;
      case 'external':
        this.filteredTopics = this.topics.filter(r => r.is_external);
        break;
      case 'restricted':
        this.filteredTopics = this.topics.filter(r => r.is_restricted);
        break;
      case 'drafts':
        this.filteredTopics = this.topics.filter(r => r.is_draft);
        break;
      default:
        // all case is already taken care of
        break;
    }

    this.dataSource = new MatTableDataSource(this.filteredTopics);
    this.dataSource.filterPredicate = this.customFilterPredicate();
    this.dataSource.sort = this.sort;
    this.applyFilter();  // keep prev search
  }

  // filter function to search in video title
  customFilterPredicate() {
    const myFilterPredicate = (data: TopicCard, filter: string): boolean => {
      let searchString = filter.trim().toLowerCase();
      // search in title or email
      return data.title.toLowerCase().indexOf(searchString) != -1 ||
        data.uploader.email.toLowerCase().indexOf(searchString) != -1;
    }
    return myFilterPredicate;
  }

  // change the uploader of a playlist
  // opens a dialog where checklist data is fetched
  // user must be a quality admin
  changeUploader(topic: TopicCard) {
    if (!this.authService.userDetails.is_quality_manager) {
      const message = this.translateService.instant("You need Video Management rights to edit this");
      this.snackBar.open(message, '', this.snackbarConfig);
      return;
    }
    const dialogRef = this.dialog.open(UploaderChangeDialogComponent, {
      minWidth: '400px',
      width: '50vw',
      maxWidth: '600px',
      data: topic,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // confirm and save
        if (result != topic.uploader.id) {
          // confirm first
          const user_object = this.authService.checklist_data.find(e => e.id == result && e.type == 'user');
          let message = user_object.name;
          message += " "
          message += this.translateService.instant("will also be notified about this.");
          message += "\n\n";
          message += this.translateService.instant("Would you like to continue?");
          if (window.confirm(message)) {
            // send call, same as bulk actions
            const body: any = {action: 'reassign', ids: [topic.id], uploader: result, send_email: true};
            this.dataService.putURL(`manager/topics/`, body, {
              observe: 'body', responseType: 'text'
            }).subscribe((res: string) => {
              this.snackBar.open(res, '', this.snackbarConfig);
              this.getTopics();
            }, (err: HttpErrorResponse) => {
              this.handleError(err);
            });
          }
        }
      }
    });
  }


  applyTopicAction(id: string = null) {
    let data: string[] = [];
    if (id) {
      // individual action
      data = [id];
    } else {
      // bulk action
      data = this.selection.selected.map(obj => obj.id);
    }
    this.bottomSheet.open(TopicBottomSheetComponent, {
      data: {
        ids: data,
        case: 'topics'
      }
    }).afterDismissed().subscribe(res => {
      if (res) {
        this.selection.clear();
        this.getTopics();
      }
    });
  }

  viewTopic(id) {
    window.open(`pages/view/${id}`, '_blank');
  }

  handleError(err) {
    this.showSpinner = false;
    console.error(err);
    this.snackBar.open(this.translateService.instant('Ein Fehler ist aufgetreten'), '', this.snackbarConfig);
  }
}
