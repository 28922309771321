import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {NavbarService} from '../../services/navbar.service';
import {DataService} from '../../services/data.service';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {MatSnackBar} from '@angular/material/snack-bar';
import {VideoCard} from '../../models/video/video.interface';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {TranslateService} from '@ngx-translate/core';
import {UtilityService} from 'src/app/services/utility.service';
import {PlaylistVideo} from "../../quickwin-view/quickwin-view.component";
import {Playlist} from "../../models/playlist/playlist";

@Component({
  selector: 'app-create-playlist-my-vidoes',
  templateUrl: './create-playlist-my-vidoes.component.html',
  styleUrls: ['./create-playlist-my-vidoes.component.scss']
})
export class CreatePlaylistMyVidoesComponent implements OnInit {

  environment = environment;
  title_changed: boolean = false;
  readonly maxSize = 5970000;
  playlistForm = this.formBuilder.group({
    title: ['', Validators.required],
    is_draft: [false, Validators.required],
    searchVal: ['']
  });
  is_Draft = true;
  search_videos: VideoCard[] = [];
  videosInPlaylist: PlaylistVideo[] = [];
  backendUrl: string = "";
  playlistId: string = "";
  companyID: number = 0;  // to fetch the company id from playlist, instead of using auth service

  constructor(private formBuilder: UntypedFormBuilder,
              public utilityService: UtilityService,
              private translateService: TranslateService,
              private snackBar: MatSnackBar,
              private navbarService: NavbarService,
              private dataService: DataService,
              private router: Router,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.navbarService.disableCreationMode();
    this.navbarService.getUserProfileDetails();
    this.backendUrl = environment.backendURL;

    this.route.paramMap.subscribe(map => {
      this.playlistId = map.get('param');
      this.getPlaylist();
    });

    this.dataService.getSearchURL('', '1').subscribe((data: any) => {
      this.search_videos = data.data;
    });
  }

  getPlaylist(): void {
    this.dataService.getURL(`user/playlists/${this.playlistId}/`).subscribe((res: Playlist) => {
      this.playlistForm.get('title').setValue(res.title);
      this.playlistForm.get('is_draft').setValue(res.is_draft);
      this.is_Draft = res.is_draft;
      this.companyID = res.uploader.userprofile.company;
      this.getVideosPlaylist();
    });
  }


  getVideosPlaylist() {
    this.videosInPlaylist = [];
    this.dataService.getURL(`user/playlists/${this.playlistId}/videos/`).subscribe((resp: PlaylistVideo[]) => {
      this.videosInPlaylist = resp;
    })
  }

  backToMyVideos() {
    const navigationExtras: NavigationExtras = {
      state: {
        index: 2,
      }
    };
    this.router.navigate(['library', 'playlists'], navigationExtras);
  }

  getSearchResult(): void {
    let search = this.playlistForm.get('searchVal').value;
    // only load first page data
    this.dataService.getSearchURL(search, '1').subscribe((data: any) => {
      this.search_videos = data.data;
    });
  }

  deleteVideo(id): void {
    this.dataService.getURL(`user/playlists/${this.playlistId}/${id}/remove/`).subscribe((res: any) => {
      if (res.response == 'success') {
        this.snackBar.open(this.translateService.instant('Video aus der Playlist entfernt'), '', {
          duration: 2500,
          horizontalPosition: 'right'
        });
        this.getVideosPlaylist();
      }
    });
  }

  deletePlaylist(): void {
    let message = this.translateService.instant("Your playlist will be deleted forever.");
    message += '\n\n';
    message += this.translateService.instant("Would you like to continue?");
    if (window.confirm(message)) {
      this.dataService.deleteURL(`user/playlists/${this.playlistId}/`).subscribe((res: any) => {
        this.router.navigate(['library', 'playlists']);
        this.snackBar.open(this.translateService.instant('Erfolgreich gelöscht'), '', {duration: 2500});
      });
    };
  }

  saveTitle(): void {
    if (this.title_changed) {
      let title_input: string = this.playlistForm.get('title').value;
      title_input = title_input.trim();
      if (title_input.length == 0) {
        window.alert(this.translateService.instant("Please provide a title"));
        document.getElementById('title-input').focus();
        return;
      }
      const body = {
        title: title_input
      };
      this.dataService.putURL(`user/playlists/${this.playlistId}/`, body).subscribe((res: any) => {
        this.snackBar.open(this.translateService.instant('Gespeichert'), '', {
          duration: 2500,
        });
        this.title_changed = false;
      });
    }
  }

  addVideo(videoObj: VideoCard): void {
    this.dataService.getURL(`user/playlists/${this.playlistId}/${videoObj.id}/add/`).subscribe((res: any) => {
      if (res.response == 'success') {
        this.getVideosPlaylist();
        // if video is restricted, show other snackbar
        if (videoObj.is_restricted) {
          this.snackBar.open(
            this.translateService.instant("Restricted visibility inside the workspace"),
            this.translateService.instant('Undo'),
            {duration: 5000, horizontalPosition: 'right'}).onAction().subscribe(() => {
            // delete the section
            this.deleteVideo(videoObj.id);
          });
        } else {
          this.snackBar.open(this.translateService.instant('Video erfolgreich hinzugefügt'), '', {
            duration: 3000,
            horizontalPosition: 'right'
          });
        }
      }
    });
  }

  setPrivate(value: boolean): void {
    this.dataService.putURL(`user/playlists/${this.playlistId}/`, {is_draft: value}).subscribe((res: any) => {
      if (res['reason']) {
        window.alert(res['reason']);
      } else {
        this.is_Draft = value;
        this.snackBar.open(this.translateService.instant('Erfolgreich'), '', {duration: 2500});
      }
    }, (err) => {
      console.error(err);
      this.snackBar.open(this.translateService.instant('Ein Fehler ist aufgetreten'), '', {duration: 2000});
    })
  }

  savePlaylistURL() {
    let copyText = this.environment.baseURL + "playlistView/" + this.playlistId;
    navigator.clipboard.writeText(copyText);
    this.snackBar.open(this.translateService.instant('Link kopiert'), '', {duration: 2500});
  }

  recentVideoClick(video_id: string) {
    window.open(`/view/${video_id}`, '_blank');
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.videosInPlaylist, event.previousIndex, event.currentIndex);
    let previousIndex = event.previousIndex + 1;
    let currentIndex = event.currentIndex + 1;
    let changeval = {
      old_index: previousIndex,
      new_index: currentIndex
    }
    this.dataService.patchURL(`user/playlists/${this.playlistId}/videos/`, changeval).subscribe((res: any) => {
    });
  }

  // returns if that video is in list or not
  findVideoInList(id: string): boolean {
    if(this.videosInPlaylist.find(ele => ele.video.id == id)){
      return true;
    }
    return false;
  }
}

