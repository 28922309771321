<div (click)="closeGallery();" *ngIf="currently_displayed_image_src"
     [style.background-image]="'url(' + currently_displayed_image_src + ')'"
     class="gallery spinner-overlay">
  <button (click)="closeGallery();" class="position-absolute"
          mat-icon-button
          style="color: white; top: 15px; right: 20px; scale: 1.5;">
    <mat-icon>close</mat-icon>
  </button>
</div>

<!--show absolute image if there is a topic and we're not in iframe-->
<img *ngIf="topic && !isIframe" [src]="topic?.thumbnail ? topic.thumbnail : 'assets/images/Topics_Cover_Image.png'"
     [style.object-position]="topic ? topic.object_position : '50% 50%'"
     class="w-100"
     style="object-fit: cover; aspect-ratio: 50/9;">

<div *ngIf="display_message.length"
     class="d-flex w-100 h-100 align-items-center justify-content-center flex-column">
  <mat-card-title class="text-nunito pb-4">
    {{ display_message }}
  </mat-card-title>
  <a *ngIf="is_login_needed" class="border-radius-20" href="/login" mat-stroked-button target="_blank">
    Login
  </a>
  <!--  this input + button is visible when video needs an access key, display message is always there -->
  <div *ngIf="is_access_key_needed" class="text-center">
    <mat-form-field appearance="outline">
      <mat-label translate>Zugangscode eingeben</mat-label>
      <input (keydown.enter)="loadTopic();" [(ngModel)]="access_key" [type]="is_access_key_hidden ? 'password' : 'text'" autocomplete="off"
             autofocus matInput
             onfocus="this.removeAttribute('readonly');">
      <button (click)="is_access_key_hidden = !is_access_key_hidden" mat-icon-button matSuffix>
        <mat-icon>{{ is_access_key_hidden ? 'visibility_off' : 'visibility' }}</mat-icon>
      </button>
    </mat-form-field>
    <br>
    <button (click)="loadTopic()" mat-stroked-button>
      {{ 'Check'|translate }}
    </button>
  </div>
</div>

<div *ngIf="topic && !isIframe" class="w-100 d-flex justify-content-end row mx-0">
  <!-- title, section content-->
  <div class="col-lg-7 col-md-7 col-sm-12 col-12 pt-4">
    <div class="d-flex flex-column mb-5">
      <!-- title, section content-->
      <div class="d-flex align-items-center">
        <button (click)="goBack();" class="mr-2 d-none d-md-inline" mat-icon-button style="margin-left: -45px;">
          <mat-icon>arrow_back</mat-icon>
        </button>
        <button class="mr-3" disabled mat-icon-button style="font-size: xx-large;">
          {{ topic.emoji }}
        </button>
        <mat-card-title class="fontStyle line-height-initial" style="font-size: 28px;">
          {{ topic.title }}
        </mat-card-title>
      </div>

      <!--last updated row-->
      <mat-card-subtitle class="text-nunito my-2 font-medium" style="color:#8E8E93">
        <span *ngIf="is_external_views_shown">
          {{ topic.views }}&nbsp;{{ topic.views == 1 ? ('Aufruf'|translate) : ('Aufrufe'|translate) }}&nbsp;•
        </span>
        {{ 'Updated by' | translate }}
        <b [title]="topic.uploader.email">
          {{ topic.uploader.first_name }}&nbsp;{{ topic.uploader.last_name }}
        </b>
        <span title="{{'Erstellt am'|translate}}&nbsp;{{topic.added_on | date:'dd.MM.y'}}">
          {{ utilityService.timeSince(topic.last_edited_on, "ago", false) |async }}
        </span>
      </mat-card-subtitle>

      <!--done, share Button row-->
      <div class="d-flex align-items-center mb-3" style="flex-flow: wrap;">

        <!--edit button: visible for creator only, hidden in iframes-->
        <button *ngIf="isTopicCreator" [routerLink]="['/pages/edit/', topic.id]" class="font-size-initial"
                color="primary" mat-button matTooltip="{{'Bearbeiten'|translate}}" matTooltipPosition="above">
          <mat-icon class="mr-1 material-icons-outlined">edit</mat-icon>
          <span translate>Bearbeiten</span>
        </button>

        <button [disabled]="translations.length == 1" [matMenuTriggerFor]="menu_trans"
                aria-label="{{ 'Change language' | translate }}" class="font-size-initial mr-1" mat-button
                matTooltip="{{ 'Change language' | translate }}" matTooltipPosition="above">
          <mat-icon class="mr-1">language</mat-icon>
          <span>{{ topic.language }}</span>
        </button>
        <mat-menu #menu_trans="matMenu">
          <button (click)="changeLanguage(translation);" *ngFor="let translation of translations" mat-menu-item>
            <span>{{ translation.language }}</span>
          </button>
        </mat-menu>

        <!--share button: hidden in iframe-->
        <button (click)="shareTopic();" [disabled]="topic.is_draft" class="ml-1" mat-button>
          <mat-icon class="mr-1">share</mat-icon>
          <span translate>Teilen</span>
        </button>

        <!-- review: bulk actions button-->
        <button (click)="applyBulkAction()" *ngIf="isTopicManager" [disabled]="topic.is_draft" mat-button>
          <mat-icon class="mr-1">check</mat-icon>
          <span translate>Admin Actions</span>
        </button>

        <!--Badges-->
        <span *ngIf="topic.is_featured" class="badge mx-2 text-nunito font-medium"
              style="background-color: lightgoldenrodyellow;" translate>
          Hervorgehoben
        </span>
        <span *ngIf="topic.is_held_for_review" class="badge mx-2 text-nunito font-medium"
              matTooltip="{{'Content under review is not visible to the other users and outside the organization until approved by a manager.'|translate}}"
              matTooltipPosition="above" style="background-color: #E9E9E9; color: #24513E;" translate>
          Wird überprüft
        </span>
        <span *ngIf="topic.is_draft" class="badge mx-2 text-nunito font-medium"
              style="background-color: #FEE2E2; color: darkred;" translate>
          Draft
        </span>
      </div>

      <!--sections of topic-->
      <div *ngFor="let sec of sections_to_display">
        <!--important: adding this topic id into section id, to avoid fetching blocks from somewhere else-->
        <section #section_block class="my-3 w-100 mat-elevation-z2 border-radius-15 p-3"
                 id="section-{{topic_id}}-{{sec.id}}">
          <!-- content -->
          <div [innerHTML]="sec.content" class="text-nunito w-100 section-div ql-editor">
            <!--
            (click)="sec.content.changingThisBreaksApplicationSecurity.includes('<img')? getClickEvent($event, sec) : null"
             *ngIf="sec.content"
            -->
          </div>

          <!--There are 2 ways to display an attachment: video or image-->
          <div *ngIf="sec.attachment" class="mt-4">
            <!-- Always show attachment link, because user may want to download it, except in case of pdf -->
            <a *ngIf="getMimeType(sec.attachment) !== 'pdf'" [href]="sec.attachment" target="_blank">
              {{ getAttachedFilename(sec.attachment) }}
            </a>
            <!-- pdf attachment -->
            <a (click)="openPdfPopup(sec.attachment)" *ngIf="getMimeType(sec.attachment) == 'pdf'"
               [title]="sec.attachment" class="cursor-pointer">
              {{ getAttachedFilename(sec.attachment) }}
            </a>
            <!-- image attachment -->
            <img (click)="openImageOverlay(sec.attachment);" *ngIf="getMimeType(sec.attachment) == 'image'"
                 [src]="sec.attachment" class="w-100 border-radius-10 max-height-75 mt-2"
                 style="object-fit: contain; cursor: zoom-in;">
            <!-- video attachment -->
            <video *ngIf="getMimeType(sec.attachment) == 'video'" [src]="sec.attachment" class="show-border w-100 max-height-75 mt-2"
                   controls controlsList="nodownload" crossorigin="anonymous"
                   preload="metadata" type="video/mp4">
              This browser does not support video files
            </video>
            <!-- audio attachment -->
            <audio *ngIf="getMimeType(sec.attachment) == 'audio'" class="w-100 mt-2" controls controlsList="nodownload"
                   preload="metadata">
              <source [src]="sec.attachment">
              This browser does not support audio files
            </audio>
          </div>

          <!-- finally after the section attachment, show the question -->
          <div *ngIf="getQuestion(sec.id) as ques" class="mt-2">
            <view-question [isTopicCreator]="isTopicCreator" [ques]="ques"></view-question>
          </div>
        </section>
      </div>

      <!--tags row-->
      <div *ngIf="tagsData.length" class="mb-5 mt-4">
        <mat-chip-list aria-label="tags">
          <mat-chip (click)="tagSelected(tag.id)" *ngFor="let tag of tagsData" color="#F1F1F1">{{ tag.name }}</mat-chip>
        </mat-chip-list>
      </div>
    </div>
  </div>

  <!--nav bar for scroll-->
  <div class="scroll-content mt-3 d-none d-md-inline col-3 pt-4">
    <div *ngIf="section_links.length" class="text-nunito p-3" style="font-size: 18px;" translate>
      <b>{{ 'In this page' | translate }}</b>
    </div>
    <mat-selection-list multiple="false">
      <mat-list-option (click)="scrollTo(section.id);" *ngFor="let section of section_links" [title]="section.title"
                       class="text-nunito">
        {{ section.name }}
      </mat-list-option>
    </mat-selection-list>
  </div>
</div>

<!--show content on iframe-->
<div *ngIf="topic && isIframe"
     [style.aspect-ratio]='aspectRatio' class="d-flex flex-column h-100 position-relative w-100 justify-content-center">
  <!--0th one: cover page-->
  <div *ngIf="currentSectionIndex == 0" class="overflow-auto mb-5 position-absolute" style="top: 0;">
    <img *ngIf="topic" [src]="topic?.thumbnail ? topic.thumbnail : 'assets/images/Topics_Cover_Image.png'"
         [style.object-position]="topic ? topic.object_position : '50% 50%'" class="w-100"
         style="object-fit: cover; aspect-ratio: 50/9;">

    <!--emoji & title-->
    <div class="d-flex align-items-center p-3">
      <button class="mr-3" disabled mat-icon-button style="font-size: xx-large;">
        {{ topic.emoji }}
      </button>
      <mat-card-title class="fontStyle line-height-initial" style="font-size: 28px;">
        {{ topic.title }}
      </mat-card-title>
    </div>

    <mat-card-subtitle class="text-nunito my-1 p-3 font-medium" style="color:#8E8E93">
      {{ topic.uploader.first_name }}&nbsp;{{ topic.uploader.last_name }}&nbsp;•
      <!-- show views-->
      <ng-container *ngIf="is_external_views_shown">
        {{ topic.views }}&nbsp;{{ topic.views == 1 ? ('Aufruf'|translate) : ('Aufrufe'|translate) }}&nbsp;•
      </ng-container>
      {{ "Veröffentlicht am" | translate }}:&nbsp;{{ topic.added_on | date: "dd.MM.y" }}
    </mat-card-subtitle>

    <!--change language and badges-->
    <div class="d-flex align-items-center flex-wrap py-3 pr-3">
      <button [disabled]="translations.length == 1" [matMenuTriggerFor]="menu_trans"
              aria-label="{{ 'Change language' | translate }}" class="font-size-initial mr-1" mat-button
              matTooltip="{{ 'Change language' | translate }}" matTooltipPosition="above">
        <mat-icon class="mr-1">language</mat-icon>
        <span>{{ topic.language }}</span>
      </button>
      <mat-menu #menu_trans="matMenu">
        <button (click)="changeLanguage(translation);" *ngFor="let translation of translations" mat-menu-item>
          <span>{{ translation.language }}</span>
        </button>
      </mat-menu>

      <!--Badges-->
      <!--featured is necessary to tell viewer about its importance-->
      <span class="badge mr-2 text-nunito font-medium"
            style="background-color: #F8F8F8; color: #7A7A7D; border: 1px solid #e0dfdf;">
        {{ sections_to_display.length }}
        {{ sections_to_display.length == 1 ? ('section' | translate) : ('sections' | translate) }}
      </span>
      <span *ngIf="topic.is_featured" class="badge mr-2 text-nunito font-medium"
            style="background-color: lightgoldenrodyellow;" translate>
        Hervorgehoben
      </span>
      <!--held of review; so that creator knows that no one would be able to see it-->
      <span *ngIf="topic.is_held_for_review" class="badge mr-2 text-nunito font-medium"
            matTooltip="{{'Content under review is not visible to the other users and outside the organization until approved by a manager.'|translate}}"
            matTooltipPosition="above" style="background-color: #E9E9E9; color: #24513E;" translate>
        Wird überprüft
      </span>
      <!--draft is necessary when creator is testing-->
      <span *ngIf="topic.is_draft" class="badge mr-2 text-nunito font-medium"
            style="background-color: #FEE2E2; color: darkred;" translate>
        Draft
      </span>
    </div>
  </div>

  <!-- display current section and its content -->
  <section *ngIf="currentSection" class="overflow-auto px-3 pt-3 pb-5 mb-0">
    <div [innerHTML]="currentSection.content" class="text-nunito w-100 section-div ql-editor"></div>
    <!--show attachment-->
    <ng-container *ngIf="currentSection.attachment" class="m-3">
      <a *ngIf="getMimeType(currentSection.attachment) !== 'pdf'" [href]="currentSection.attachment" target="_blank">
        {{ getAttachedFilename(currentSection.attachment) }}
      </a>
      <a (click)="openPdfPopup(currentSection.attachment)" *ngIf="getMimeType(currentSection.attachment) === 'pdf'"
         class="cursor-pointer">
        {{ getAttachedFilename(currentSection.attachment) }}
      </a>
      <img (click)="openImageOverlay(currentSection.attachment)"
           *ngIf="getMimeType(currentSection.attachment) === 'image'" [src]="currentSection.attachment"
           class="w-100 border-radius-10 max-height-75 mt-2" style="object-fit: contain; cursor: zoom-in;">
      <video *ngIf="getMimeType(currentSection.attachment) === 'video'" [src]="currentSection.attachment"
             class="show-border w-100 max-height-75 mt-2" controls controlsList="nodownload" preload="metadata"
             type="video/mp4">
        This browser does not support video files
      </video>
      <audio *ngIf="getMimeType(currentSection.attachment) === 'audio'" class="w-100 mt-2" controls
             controlsList="nodownload" preload="metadata">
        <source [src]="currentSection.attachment">
        This browser does not support audio files
      </audio>
    </ng-container>

    <!-- finally after the section attachment, show the question -->
    <div *ngIf="getQuestion(currentSection.id) as ques" class="my-2">
      <view-question [isTopicCreator]="isTopicCreator" [ques]="ques"></view-question>
    </div>
  </section>

  <!-- last one-->
  <div *ngIf="currentSectionIndex == sections_to_display.length + 1"
       class="h-100 w-100 d-flex justify-content-center align-items-center overflow-auto flex-column pb-5">

    <h3 translate>Congratulations! You successfully completed this page.</h3>

    <!--go to public page button-->
    <a href="/public/{{company_id}}" target="_blank">
      <img [src]="company_logo" alt="logo" style="height: 80px; margin-bottom: 1rem;">
    </a>

    <h3 translate>Discover more content or create your own tutorial with Clypp.</h3>

    <div class="d-flex">
      <a *ngIf="!isAuthenticated" class="border-radius-20 mr-3" href="{{ 'WEB_URL' | translate }}" mat-stroked-button
         style="text-decoration: none" target="_blank">
        <span translate>Try Clypp for free</span>
      </a>
      <button (click)="updateCurrentSection(0);" class="border-radius-20" mat-stroked-button>
        <mat-icon class="mr-1">replay</mat-icon>
        <span translate>Restart</span>
      </button>
    </div>
  </div>

  <!-- bottom slider -->
  <div [ngStyle]="topicIdInput ? {'border-bottom-left-radius':'1em','border-bottom-right-radius':'1em'} : {}"
       [style.opacity]="currentSectionIndex == 0 ? 1 : 0"
       class="bottom-slider d-flex align-items-center mat-elevation-z3 p-3 w-100 position-absolute">
    <!-- this component has full opacity for 0th section, otherwise visbible on hover-->
    <img (click)="updateCurrentSection(0);" [src]="overlay_logo" alt="logo" class="cursor-pointer"
         style="height: 30px;">
    <mat-slider (valueChange)="updateCurrentSection($event);" [(ngModel)]="currentSectionIndex"
                [max]="sections_to_display.length + 1" min="0" step="1" tickInterval="1"
                class="mx-2 w-fill-available" color="primary">
      <input matSliderThumb>
    </mat-slider>
    <button (click)="updateCurrentSection(currentSectionIndex-1);" [disabled]="currentSectionIndex == 0"
            mat-icon-button>
      <mat-icon>chevron_left</mat-icon>
    </button>
    <button (click)="updateCurrentSection(currentSectionIndex+1);"
            [disabled]="currentSectionIndex == sections_to_display.length + 1" mat-icon-button>
      <mat-icon>chevron_right</mat-icon>
    </button>
    <a [href]="current_url" mat-icon-button target="_blank">
      <mat-icon style="color: black;">open_in_new</mat-icon>
    </a>
  </div>
</div>
