<mat-drawer-container class="bg-transparent">
  <!--sidebar content for hide/change order of blocks-->
  <mat-drawer #drawer class="position-fixed p-3" mode="over"
              position="end"
              style="margin-top: 4rem;">
    <div class="d-flex flex-column h-100">
      <!--drawer heading-->
      <div class="d-flex justify-content-between align-items-center mb-3">
        <h3 class="fontStyle mb-0" translate>
          Customize
        </h3>
        <button (click)="drawer.close();" mat-icon-button>
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <!--list of items-->
      <div (cdkDropListDropped)="sectionDropped($event)" cdkDropList style="flex: 1;">
        <div *ngFor="let pageItem of currentOrder | keyvalue; let i = index" cdkDrag class="drag-box-homepage">
          <div class="d-flex align-items-center">
            <mat-icon cdkDragHandle class="mr-2" style="cursor: move;">
              drag_indicator
            </mat-icon>
            <mat-icon [style.color]="pageItem.value[1] == 1 ? 'black' : 'gray'" class="material-icons-outlined mr-2">
              {{homePageLabelIconMap[pageItem.value[0]][1]}}
            </mat-icon>
            <span [style.color]="pageItem.value[1] == 1 ? 'black' : 'gray'" class="fontNormal">
              {{homePageLabelIconMap[pageItem.value[0]][0] }}
            </span>
          </div>
          <button (click)="toggleVisibility(i);" mat-icon-button>
            <mat-icon class="material-icons-outlined">
              {{ (pageItem.value[1] == 1) ? 'visibility' : 'visibility_off' }}
            </mat-icon>
          </button>
        </div>
      </div>
      <!--drawer actions-->
      <div class="d-flex justify-content-between align-self-end w-100">
        <button (click)="resetHomePageOrder();" class="border-radius-20" mat-stroked-button>
          <span class="fontNormal" translate>Reset</span>
        </button>
        <button (click)="saveCurrentOrder(); drawer.close();" class="border-radius-20" mat-stroked-button>
          <span class="fontNormal" translate>Done</span>
        </button>
      </div>
    </div>
  </mat-drawer>

  <!--page content with all the blocks-->
  <div class="no-scroll">
    <!--  company specific site notice-->
    <div *ngIf="formatted_site_notice" [hidden]="is_site_notice_closed" class="mb-1"
         style="background-color: blanchedalmond; display: flex;">
      <!--hidden does not work with d-flex-->
      <div class="d-flex justify-content-center head">
        <span [innerHTML]="formatted_site_notice" style="padding-top: 0.25rem;" translate></span>
      </div>
      <div class="d-flex justify-content-end" style="width: 5%; margin-top: -0.25rem">
        <span style="display: flex; justify-content: end">
          <button (click)="siteNoticeClose();" mat-icon-button style="background: none !important">
            <mat-icon class="cancel-icon">close</mat-icon>
          </button>
        </span>
      </div>
    </div>

    <!-- Set by Zesavi admins -->
    <app-fe-banners></app-fe-banners>

    <div class="container-fluid">
      <div class="d-flex justify-content-between align-items-center mt-3">
        <h2 [title]="authService.currentUser.email" class="fontStyle d-flex align-items-center mb-0">
          {{greetings_text}},&nbsp;{{authService.currentUser?.firstName}}&nbsp;👋
        </h2>

        <button (click)="drawer.open();" class="border-radius-20" mat-stroked-button>
          <mat-icon class="material-icons-outlined mr-2">edit</mat-icon>
          <span translate>Customize</span>
        </button>
      </div>

      <ng-container *ngFor="let item of currentOrder">
        <ng-container *ngIf="item[1] == 1" [ngSwitch]="item[0]">
          <!--your activities-->
          <ng-container *ngSwitchCase="'userBlock'">
            <div *ngIf="userActivityResp" class="d-flex flex-wrap justify-content-around my-3">
              <div class="d-flex flex-wrap justify-content-around" style="min-width: 50%;">
                <div class="simple-card">
                  <p class="text-nunito mt-4" style="font-size:large;" translate>
                    Your activities
                  </p>
                  <div class="d-flex mt-4">
                    <mat-icon class="material-symbols-outlined" color="primary"
                              style="font-size: 35px;width: 35px;height: 35px;">
                      sentiment_very_satisfied
                    </mat-icon>
                    <div class="fontStyle ml-2 align-self-center" style="font-size: xx-large;">
                      {{ userActivityResp.n_7_days }}
                    </div>
                  </div>
                  <div *ngIf="increasePercentage" class="mt-4">
                    <!--hide in case of 0-->
                    <div *ngIf="increasePercentage > 0" class="d-flex align-items-center" style="font-size:large;">
                      <mat-icon class="mr-1" style="color: mediumseagreen;">arrow_upward</mat-icon>
                      <span style="color:mediumseagreen;">{{ increasePercentage }}%</span>
                      <span class="fontNormal ml-2" translate>more than last 7 days</span>
                    </div>
                    <div *ngIf="increasePercentage < 0" class="d-flex align-items-center" style="font-size:large;">
                      <mat-icon class="mr-1" color="warn">arrow_downward</mat-icon>
                      <!--todo: show absolute value-->
                      <span style="color:red;">{{ increasePercentage }}%</span>
                      <span class="fontNormal ml-2" translate>less than last 7 days</span>
                    </div>
                  </div>
                </div>

                <!--active days-->
                <div class="simple-card">
                  <p class="text-nunito mt-4" style="font-size:large;" translate>
                    Days active
                  </p>
                  <span class="text-nunito mt-4 font-medium">
                  <mat-icon color="primary" style="vertical-align: bottom;">
                    event_available
                  </mat-icon>
                    {{ active_days_label.length }}&nbsp;{{ 'of the last 7 days'|translate }}
                </span>
                  <div class="d-flex mt-4">
                    <div *ngFor="let i of seven_days_label; let index = index;"
                         class="d-flex flex-column align-items-center mx-1">
                      <span class="text-nunito">{{ i|translate }}</span>
                      <mat-icon *ngIf="!active_days_label.includes(i)" color="primary">radio_button_unchecked</mat-icon>
                      <mat-icon *ngIf="active_days_label.includes(i) && index != 6" color="primary">check_circle
                      </mat-icon>
                      <mat-icon *ngIf="index == 6" style="color: goldenrod;">check_circle</mat-icon>
                    </div>
                  </div>
                </div>
              </div>

              <div class="d-flex flex-wrap justify-content-around" style="min-width: 50%;">
                <!--Min width 50% is needed to ensure proper width on a large screen-->
                <!--workspace chart-->
                <div class="simple-card">
                  <mat-button-toggle-group (change)="onChartChange();" [(ngModel)]="chart_button_toggle"
                                           aria-label="button toggle" class="w-100"
                                           style="border-radius: 10px 10px 0px 0px;">
                    <!--if user is a creator, then show 50%, else 100% width of first button-->
                    <mat-button-toggle [ngClass]="authService.currentUser.is_creator ? 'w-fill-available' : 'w-100 bg-transparent'"
                                       value="all">
                      <span class="text-nunito font-medium" translate>Your Organization</span>
                    </mat-button-toggle>
                    <!--hidden for non creators-->
                    <mat-button-toggle *ngIf="authService.currentUser.is_creator" class="w-fill-available" value="mine">
                      <span class="text-nunito font-medium" translate>Your content</span>
                    </mat-button-toggle>
                  </mat-button-toggle-group>

                  <div class="bg-white d-flex" style="height: 160px;">
                    <div class="text-center position-absolute" style="width: 205px; margin-top: 63px;">
                      <h2 *ngIf="chart_button_toggle=='all'" class="text-nunito m-0">
                        {{
                          userActivityResp.n_all_topics + userActivityResp.n_all_playlists +
                          userActivityResp.n_all_videos
                        }}
                      </h2>
                      <h2 *ngIf="chart_button_toggle=='mine'" class="text-nunito m-0">
                        {{
                          userActivityResp.n_my_topics + userActivityResp.n_my_videos +
                          userActivityResp.n_my_playlists
                        }}
                      </h2>
                    </div>
                    <canvas [ngClass]="chart_button_toggle=='all' ? 'd-block' : 'd-none'" class="p-2" id="canvas1"
                            style="z-index: 1;"></canvas>
                    <canvas [ngClass]="chart_button_toggle=='mine' ? 'd-block' : 'd-none'" class="p-2" id="canvas2"
                            style="z-index: 1;"></canvas>
                  </div>
                </div>

                <!--new users-->
                <div class="simple-card">
                  <p class="text-nunito mt-4" style="font-size:large;" translate>
                    Explore new content
                  </p>
                  <div class="w-100" style="overflow-x: hidden;">
                    <button (click)="navigateUserPage(card.id);" *ngFor="let card of recent_users"
                            class="my-2 d-flex align-items-center" mat-menu-item>
                      <img [alt]="card.email" [src]="card.userprofile.profile_pic" aria-label="view clypps by this user"
                           class="cursor-pointer mr-3" mat-card-avatar>
                      <div class="d-flex flex-column line-height-initial">
                        <mat-card-title [title]="card.email" class="fontNormal"
                                        style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;">
                          {{ card.first_name }}&nbsp;{{ card.last_name }}
                        </mat-card-title>
                        <mat-card-subtitle class="text-nunito mb-1" title="{{'in the last 30 days'|translate}}">
                          <span *ngIf="card.n_videos_30_days">
                            {{ card.n_videos_30_days }}
                            {{ card.n_videos_30_days == 1 ? ('new Clypp' | translate) : ('new Clypps' | translate) }}
                          </span>
                          <!--pages-->
                          <span *ngIf="card.n_topics_30_days">
                            {{ card.n_videos_30_days ? '& ':'' }}
                            {{ card.n_topics_30_days }}
                            {{ card.n_topics_30_days == 1 ? ('new Page'|translate) : ('new Pages' | translate) }}
                          </span>
                        </mat-card-subtitle>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <!--  Chips  -->
          <div *ngSwitchCase="'tags'" class="row mt-3 sticky-top">
            <div #mat_chips class="my-2 chip-list d-flex col-md-10 col-sm-6">
              <mat-chip-list aria-label="tags">
                <!--partnerships-->
                <mat-chip (click)="partnerSelected(partner.id);" *ngFor="let partner of partner_companies">
                  {{partner.sharing_company.name}}
                </mat-chip>

                <ng-container *ngIf="!showMore">
                  <!--featured tags-->
                  <mat-chip (click)="tagSelected(tag.id)" *ngFor="let tag of firstRowTags">
                    {{tag.name}}
                  </mat-chip>
                </ng-container>
                <ng-container *ngIf="showMore">
                  <mat-chip (click)="tagSelected(tag.id)" *ngFor="let tag of moreTags">
                    {{tag.name}}
                  </mat-chip>
                </ng-container>
                <button (click)="showMoreFn()" *ngIf="moreTags.length > 0" class="ml-1 show-more-button" color="primary"
                        mat-stroked-button>
                  {{(showMore ? 'See less' : 'View more') | translate}}
                  <mat-icon>{{showMore ? 'expand_less' : 'expand_more'}}</mat-icon>
                </button>
              </mat-chip-list>
            </div>
          </div>

          <!--Continue editing-->
          <ng-container *ngSwitchCase="'continueEditing'">
            <div *ngIf="userActivityResp && authService.currentUser.is_creator"
                 class="border-radius-10 my-3 p-3 bg-primary-555">
              <h2 class="fontStyle d-flex align-items-center border-radius-10" mat-menu-item
                  routerLink="/library/clypps" style="width: fit-content;">
                <mat-icon class="material-icons-outlined mr-2" color="primary">movie_edit</mat-icon>
                {{'Continue editing'|translate}}
              </h2>

              <div class="left-right-div" id="continue-editing">
                <!--card to show if user has no videos-->
                <div *ngIf="mixed_cards.length == 0" class="simple-card bg-white justify-content-around"
                     style="max-height: 180px;">
                  <h2 class="m-0 text-nunito d-flex align-items-center" translate>
                    <mat-icon class="mr-1" color="primary">
                      post_add
                    </mat-icon>
                    Your content
                  </h2>
                  <span class="fontNormal text-center" translate>
                    Nothing to see yet
                  </span>
                  <!--motivate users to create videos-->
                  <button [matMenuTriggerFor]="createMenu" class="border-radius-20" mat-stroked-button>
                    <mat-icon class="mr-1">
                      add
                    </mat-icon>
                    {{'Erstellen'|translate}}
                  </button>

                  <mat-menu #createMenu="matMenu">
                    <button (click)="navbarService.createPRVideo();" mat-menu-item>
                      <mat-icon class="material-icons-outlined">radio_button_checked</mat-icon>
                      <span class="text-nunito font-size-16" translate>New Clypp</span>
                    </button>
                    <button (click)="navbarService.createTopic()" mat-menu-item>
                      <mat-icon class="material-icons-outlined">post_add</mat-icon>
                      <span class="text-nunito font-size-16" translate>New Page</span></button>
                    <button (click)="navbarService.addPlaylist()" mat-menu-item>
                      <mat-icon>playlist_add</mat-icon>
                      <span class="text-nunito font-size-16" translate>Neue Playlist</span></button>
                    <button (click)="navbarService.openVideoRequestDialog()" mat-menu-item>
                      <mat-icon class="material-icons-outlined">add_comment</mat-icon>
                      <span class="text-nunito font-size-16" translate>New Request</span></button>
                  </mat-menu>
                </div>

                <!--show mixed cards-->
                <ng-container *ngFor="let card of mixed_cards">
                  <app-video-card [mode]="'edit'" *ngIf="card.type == 'video'" [videoObject]="card.data"
                                  style="max-width: 320px; margin-right: 20px; background-color: transparent;">
                  </app-video-card>

                  <app-video-card-playlist *ngIf="card.type == 'playlist'" [playlist_object]="card.data"
                                           routeUrl="createPlaylistMyVideos"
                                           style="max-width: 320px; margin-right: 20px;">
                  </app-video-card-playlist>

                  <app-topic-card *ngIf="card.type == 'topic'" [topic_object]="card.data" mode="edit"
                                  style="max-width: 320px; margin-right: 20px;">
                  </app-topic-card>
                </ng-container>

                <!--requests-->
                <div class="simple-card bg-white justify-content-around" style="max-height: 180px;">
                  <h2 class="m-0 text-nunito d-flex align-items-center" translate>
                    <mat-icon class="mr-1 material-icons-outlined" color="primary">
                      maps_ugc
                    </mat-icon>
                    Requests
                  </h2>
                  <!-- if user is a creator, let them fulfill requests -->
                  <ng-container *ngIf="authService.currentUser.is_creator">
                    <span class="fontNormal">
                      {{'You have'|translate}}
                      {{userActivityResp.n_open_requests}}
                      {{userActivityResp.n_open_requests == 1 ? ('open request' | translate) : ('open requests' |
                      translate)}}
                    </span>
                    <button class="border-radius-20" mat-stroked-button routerLink="/requests">
                      {{'See requests'|translate}}
                    </button>
                  </ng-container>
                  <!-- if user is not a creator, allow them to create requests -->
                  <ng-container *ngIf="!authService.currentUser.is_creator">
                    <span class="fontNormal text-center" translate>
                      You can ask your colleagues to create a Clypp
                    </span>
                    <button (click)="navbarService.openVideoRequestDialog();" class="border-radius-20"
                            mat-stroked-button>
                      {{'Request Clypp'|translate}}
                    </button>
                  </ng-container>
                </div>

                <!--button 1 with absolute position to the left-->
                <button (click)="scrollToLeftRight('continue-editing',true);"
                        class="left-right-button left-scroll-button"
                        mat-button>
                  <mat-icon class="text-white">
                    chevron_left
                  </mat-icon>
                </button>

                <!--button 2 with absolute position to the left-->
                <button (click)="scrollToLeftRight('continue-editing');" class="left-right-button right-scroll-button"
                        mat-button>
                  <mat-icon class="text-white">
                    chevron_right
                  </mat-icon>
                </button>
              </div>
            </div>
          </ng-container>

          <!-- featured content-->
          <div *ngSwitchCase="'featured'" [hidden]="mixed_featured_content.length==0"
               class="border-radius-10 my-3 p-3 bg-primary-555">
            <h2 class="fontStyle d-flex align-items-center m-0 border-radius-10">
              <mat-icon class="material-icons-outlined mr-2" color="primary">grade</mat-icon>
              {{'Hervorgehoben'|translate}}
            </h2>

            <!--slider block-->
            <!--show two buttons on the extreme, content in between, each button scrolls-->
            <div class="left-right-div" id="featured-content">
              <!--show mixed featured cards-->
              <ng-container *ngFor="let card of mixed_featured_content">
                <app-video-card *ngIf="card.type == 'video'" [videoObject]="card.data"
                                style="max-width: 320px; margin-right: 20px; background-color: transparent;">
                </app-video-card>

                <app-video-card-playlist *ngIf="card.type == 'playlist'" [playlist_object]="card.data"
                                         style="max-width: 320px; margin-right: 20px;">
                </app-video-card-playlist>

                <app-topic-card *ngIf="card.type == 'topic'" [topic_object]="card.data"
                                style="max-width: 320px; margin-right: 20px;">
                </app-topic-card>
              </ng-container>
              <!--button 1 with absolute position to the left-->
              <button (click)="scrollToLeftRight('featured-content',true);" class="left-right-button left-scroll-button"
                      mat-button>
                <mat-icon class="text-white">
                  chevron_left
                </mat-icon>
              </button>
              <!--button 2 with absolute position to the left-->
              <button (click)="scrollToLeftRight('featured-content');" class="left-right-button right-scroll-button"
                      mat-button>
                <mat-icon class="text-white">
                  chevron_right
                </mat-icon>
              </button>
            </div>
          </div>

          <!-- continue watching-->
          <div *ngSwitchCase="'continueWatching'" [hidden]="history_videos.length==0"
               class="border-radius-10 my-3 p-3 bg-primary-555">
            <div class="d-flex justify-content-between align-items-center">
              <h2 class="fontStyle d-flex align-items-center m-0 border-radius-10" mat-menu-item routerLink="/history">
                <mat-icon class="material-icons-outlined mr-2" color="primary">history</mat-icon>
                {{'Continue watching'|translate}}
              </h2>
              <a *ngIf="history_video_count > 10" class="text-nunito text-decoration-none d-none d-sm-block" mat-button
                 routerLink="/history">
                {{'View more'|translate}}
              </a>
            </div>
            <div class="left-right-div" id="history">
              <!--scrollable videos-->
              <app-video-card-history *ngFor="let video of history_videos;" [backendUrl]="backendUrl"
                                      [videoObject]="video"
                                      style="margin-right: 20px; max-width: 320px;"></app-video-card-history>
              <!--button 1 with absolute position to the left-->
              <button (click)="scrollToLeftRight('history',true);" class="left-right-button left-scroll-button"
                      mat-button>
                <mat-icon class="text-white">
                  chevron_left
                </mat-icon>
              </button>
              <!--button 2 with absolute position to the left-->
              <button (click)="scrollToLeftRight('history');" class="left-right-button right-scroll-button" mat-button>
                <mat-icon class="text-white">
                  chevron_right
                </mat-icon>
              </button>
            </div>
          </div>

          <!-- Explore your groups-->
          <div *ngSwitchCase="'explore'" class="border-radius-10 my-3 p-3 bg-primary-555">
            <div class="d-flex justify-content-between align-items-center mb-4">
              <h2 class="fontStyle d-flex align-items-center m-0 border-radius-10" mat-menu-item routerLink="/groups">
                <mat-icon class="material-icons-outlined mr-2" color="primary">groups</mat-icon>
                {{'Explore your groups'|translate}}
              </h2>
              <a class="text-nunito text-decoration-none d-none d-sm-block" mat-button routerLink="/groups">
                {{'View more'|translate}}
              </a>
            </div>

            <div class="left-right-div" id="groups">
              <!--show partners, dept, team and groups-->
              <!--partners-->
              <mat-card *ngFor="let friend of partner_companies"
                        [style.border-color]="friend.sharing_company.primary_color"
                        class="cursor-pointer border-radius-10"
                        routerLink="/partnership/{{friend.id}}"
                        style="margin-right: 20px; border: 1px solid; height: 150px;">
                <img [src]="friend.sharing_company.company_logo" height="32">
                <mat-card-title [title]="friend.sharing_company.name" class="fontNormal mt-2"
                                style="width: 200px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap;">
                  {{friend.sharing_company.name}}
                </mat-card-title>
                <mat-card-subtitle class="text-nunito">
                  {{'Partner workspace'|translate}}
                </mat-card-subtitle>
              </mat-card>


              <!--dept-->
              <mat-card [routerLink]="'/group/department'"
                        class="cursor-pointer border-radius-10"
                        style="margin-right: 20px; border: 1px solid darkgray; height: 150px;">
                <img src="https://cdn-icons-png.flaticon.com/32/554/554826.png">
                <mat-card-title [title]="my_dept_name" class="fontNormal mt-2"
                                style="width: 200px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap;">
                  {{my_dept_name}}
                </mat-card-title>
                <mat-card-subtitle class="text-nunito">
                  {{ authService.company?.dept_alias }}
                </mat-card-subtitle>
              </mat-card>

              <!--team-->
              <mat-card [routerLink]="'/group/team'"
                        class="cursor-pointer border-radius-10"
                        style="margin-right: 20px; border: 1px solid darkgray; height: 150px;">
                <img src="https://cdn-icons-png.flaticon.com/32/554/554846.png">
                <mat-card-title [title]="my_team_name" class="fontNormal mt-2"
                                style="width: 200px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap;">
                  {{my_team_name}}
                </mat-card-title>
                <mat-card-subtitle class="text-nunito">
                  {{ authService.company?.team_alias }}
                </mat-card-subtitle>
              </mat-card>

              <!--groups-->
              <mat-card (click)="viewGroup(card.id)" *ngFor="let card of all_groups"
                        class="cursor-pointer border-radius-10"
                        style="margin-right: 20px; border: 1px solid darkgray; height: 150px;">
                <img src="https://cdn-icons-png.flaticon.com/32/10629/10629626.png">
                <mat-card-title [title]="card.name" class="fontNormal mt-2"
                                style="width: 200px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap;">
                  {{ card.name }}
                </mat-card-title>
                <mat-card-subtitle [title]="authService.company?.team_alias" class="text-nunito">
                  {{ "Group" | translate }}
                </mat-card-subtitle>
              </mat-card>

              <!--button 1 with absolute position to the left-->
              <button (click)="scrollToLeftRight('groups',true);" class="left-right-button left-scroll-button mt-0"
                      mat-button style="height: 150px;">
                <mat-icon class="text-white">
                  chevron_left
                </mat-icon>
              </button>
              <!--button 2 with absolute position to the left-->
              <button (click)="scrollToLeftRight('groups');" class="left-right-button right-scroll-button mt-0"
                      mat-button style="height: 150px;">
                <mat-icon class="text-white">
                  chevron_right
                </mat-icon>
              </button>
            </div>
          </div>

          <!--newest Clypps-->
          <div *ngSwitchCase="'clypp'" [hidden]="all_videos.length==0" class="border-radius-10 my-3 p-3 bg-primary-555">
            <div class="d-flex justify-content-between align-items-center">
              <h2 [queryParams]="{ is_featured: false, sort_by: 'added_on' }"
                  class="fontStyle d-flex align-items-center m-0 border-radius-10"
                  mat-menu-item routerLink="/clypps">
                <mat-icon class="material-icons-outlined mr-2" color="primary">play_circle</mat-icon>
                {{'Latest Clypps for you'|translate}}
              </h2>
              <a *ngIf="all_video_count > 10" [queryParams]="{ is_featured: false, sort_by: 'added_on' }"
                 class="text-nunito text-decoration-none d-none d-sm-block"
                 mat-button routerLink="/clypps">
                {{'View more'|translate}}
              </a>
            </div>

            <div class="left-right-div" id="all-clypps">
              <!--scrollable videos-->
              <app-video-card *ngFor="let video of all_videos;" [videoObject]="video"
                              style="margin-right: 20px; max-width: 320px;"></app-video-card>
              <!--button 1 with absolute position to the left-->
              <button (click)="scrollToLeftRight('all-clypps',true);" class="left-right-button left-scroll-button"
                      mat-button>
                <mat-icon class="text-white">
                  chevron_left
                </mat-icon>
              </button>
              <!--button 2 with absolute position to the left-->
              <button (click)="scrollToLeftRight('all-clypps');" class="left-right-button right-scroll-button"
                      mat-button>
                <mat-icon class="text-white">
                  chevron_right
                </mat-icon>
              </button>
            </div>
          </div>

          <!--Playlist from other DEPT-->
          <div *ngSwitchCase="'playlist'" [hidden]="all_playlists.length==0"
               class="border-radius-10 my-3 p-3 bg-primary-555">
            <div class="d-flex justify-content-between align-items-center">
              <h2 [queryParams]="{ is_featured: false }"
                  class="fontStyle d-flex align-items-center m-0 border-radius-10"
                  mat-menu-item routerLink="/allPlaylists">
                <mat-icon class="material-icons-outlined mr-2" color="primary">playlist_play</mat-icon>
                {{ "Selected playlists for you"|translate}}
              </h2>
              <a *ngIf="all_playlist_count > 10" [queryParams]="{ is_featured: false }"
                 class="text-nunito text-decoration-none d-none d-sm-block"
                 mat-button routerLink="/allPlaylists">
                {{'View more'|translate}}
              </a>
            </div>
            <div class="left-right-div" id="all-playlists">
              <!--scrollable videos-->
              <app-video-card-playlist *ngFor="let pl of all_playlists;" [playlist_object]="pl"
                                       style="margin-right: 20px; max-width: 320px;"></app-video-card-playlist>
              <!--button 1 with absolute position to the left-->
              <button (click)="scrollToLeftRight('all-playlists', true);" class="left-right-button left-scroll-button"
                      mat-button>
                <mat-icon class="text-white">
                  chevron_left
                </mat-icon>
              </button>

              <!--button 2 with absolute position to the left-->
              <button (click)="scrollToLeftRight('all-playlists');" class="left-right-button right-scroll-button"
                      mat-button>
                <mat-icon class="text-white">
                  chevron_right
                </mat-icon>
              </button>
            </div>
          </div>

          <!--newest topics-->
          <div *ngSwitchCase="'explorePages'" [hidden]="all_topics.length==0"
               class="border-radius-10 my-3 p-3 bg-primary-555">
            <div class="d-flex justify-content-between align-items-center">
              <h2 [queryParams]="{ is_featured: false, sort_by: 'added_on' }"
                  class="fontStyle d-flex align-items-center m-0 border-radius-10" mat-menu-item
                  routerLink="/pages/all">
                <mat-icon class="material-icons-outlined mr-2" color="primary">article</mat-icon>
                {{'Explore pages from others'|translate}}
              </h2>
              <a *ngIf="all_topic_count > 10" [queryParams]="{ is_featured: false, sort_by: 'added_on' }"
                 class="text-nunito text-decoration-none d-none d-sm-block"
                 mat-button routerLink="/pages/all">
                {{'View more'|translate}}
              </a>
            </div>

            <div class="d-flex justify-content-start left-right-div" id="newest-topics">
              <!--scrollable topics-->
              <app-topic-card *ngFor="let topic of all_topics;" [topic_object]="topic"
                              style="max-width: 320px; margin-right: 20px;">
              </app-topic-card>
              <!--button 1 with absolute position to the left-->
              <button (click)="scrollToLeftRight('newest-topics', true);" class="left-right-button left-scroll-button"
                      mat-button>
                <mat-icon class="text-white">
                  chevron_left
                </mat-icon>
              </button>

              <!--button 2 with absolute position to the left-->
              <button (click)="scrollToLeftRight('newest-topics');" class="left-right-button right-scroll-button"
                      mat-button>
                <mat-icon class="text-white">
                  chevron_right
                </mat-icon>
              </button>
            </div>
          </div>
        </ng-container>
      </ng-container>

    </div>
  </div>
</mat-drawer-container>
