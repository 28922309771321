<div class="container-fluid mt-3 groups">
  <div class="d-flex justify-content-between align-items-center flex-wrap">
    <div class="d-flex flex-wrap align-items-baseline">
      <mat-form-field appearance="outline" class="mr-2">
        <mat-label translate>Search for…</mat-label>
        <input (keyup)="applyFilter();" [(ngModel)]="searchQuery" autocomplete="off" matInput
               placeholder="{{'Title or email' | translate}}">
      </mat-form-field>
      <div *ngIf="selection.hasValue()"
           style="background-color: #F3F8FD;">
        <!--clear button-->
        <button (click)="selection.clear();" aria-label="clear selection" mat-button>
          <mat-icon class="mr-1">close</mat-icon>
          {{ selection.selected.length }}&nbsp;{{ 'Selected'|translate|lowercase }}
        </button>
        <!--  bulk actions-->
        <button (click)="applyTopicAction();" aria-label="manage selection" mat-button>
          <mat-icon class="material-icons-outlined mr-1">check</mat-icon>
          <span translate>Admin Actions</span>
        </button>
      </div>
    </div>

    <mat-form-field appearance="outline">
      <mat-label>Filter</mat-label>
      <mat-select (selectionChange)="updateFilter();" [(ngModel)]="filterValue" class="sort-input">
        <mat-option value="published">{{'Veröffentlicht'|translate}}</mat-option>
        <mat-option value="featured">{{'Hervorgehoben'|translate}}</mat-option>
        <mat-option value="external">{{'External'|translate}}</mat-option>
        <mat-option value="restricted">{{'Restricted'|translate}}</mat-option>
        <mat-option value="drafts">{{'Drafts'|translate}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="videos-list mb-5" style="position: relative;">
    <table [dataSource]="dataSource" mat-table matSort>
      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th *matHeaderCellDef class="selectColumnTopic" mat-header-cell>
          <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>
        </th>
        <td *matCellDef="let row" class="selectColumnTopic" mat-cell>
          <mat-checkbox (change)="$event ? selection.toggle(row) : null" (click)="$event.stopPropagation()"
                        [checked]="selection.isSelected(row)">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="title">
        <th *matHeaderCellDef class="topicTitleColumn pl-3" mat-header-cell mat-sort-header>
          {{'Titel' | translate}}
        </th>
        <td *matCellDef="let element" [title]="element.title" mat-cell>
          <button (click)="viewTopic(element.id);"
                  [disabled]="element.is_draft"
                  mat-menu-item
                  style="align-items: center;display: flex;">
            <span class="emoji-box d-none d-md-flex" mat-card-avatar>
              <span class="emoji-icon">{{element.emoji}}</span>
            </span>
            <mat-icon *ngIf="element.is_featured"
                      class="mr-2 d-none d-md-inline"
                      style="color: gold; vertical-align: middle;"
                      title="{{ 'Hervorgehoben' | translate }}">
              star
            </mat-icon>
            <span class="text-nunito title-text" [ngClass]="element.is_draft ? '' : 'primary-color-text'">
              {{element.title}}
            </span>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="added_on">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>
          {{'Erstellt am' | translate}}
        </th>
        <td *matCellDef="let element"
            matTooltip="{{'Updated on' | translate}}&nbsp;{{element.last_edited_on | date: 'dd.MM.yyyy HH:mm'}}"
            matTooltipPosition="left"
            mat-cell>
          <span>{{element.added_on | date: 'dd.MM.yyyy'}}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="full_name">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>
          {{'Creator' | translate}}
        </th>
        <td *matCellDef="let element" [title]="element.uploader.email" mat-cell>
          <a (click)="changeUploader(element);"
             class="creatorRow d-flex align-items-center text-dark cursor-pointer">
            <span>{{element.uploader.first_name}}</span>
            <span class="d-none d-md-inline">&nbsp;{{element.uploader.last_name}}</span>
            <button class="showEditButton text-gray" mat-icon-button>
              <mat-icon>edit</mat-icon>
            </button>
          </a>
        </td>
      </ng-container>

      <ng-container matColumnDef="views">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>{{'Views' | translate}}</th>
        <td *matCellDef="let element" mat-cell>
          {{element.views}}
        </td>
      </ng-container>

      <ng-container matColumnDef="restrictions">
        <th *matHeaderCellDef mat-header-cell>{{'Sichtbarkeit' | translate }}</th>
        <td *matCellDef="let element" mat-cell>
          <div style="align-items: center;">
            <mat-icon [hidden]="!(element.is_external && !element.is_access_key_needed)" class="mr-2icon"
                      style="color: grey;vertical-align: middle;" title="{{'Public' | translate }}">
              public
            </mat-icon>
            <mat-icon [hidden]="!(element.is_external && element.is_access_key_needed)" class="mr-2"
                      style="color: grey;vertical-align: middle;" title="{{'Unlisted' | translate }}">
              vpn_lock
            </mat-icon>
            <mat-icon [style.visibility]="element.is_restricted ? 'visible' : 'hidden'"
                      class="mr-2 material-icons-outlined" style="color: grey;vertical-align: middle;"
                      title="{{ 'Restricted visibility inside the workspace' | translate }}">
              lock
            </mat-icon>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="is_held_for_review">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>{{'Freigabe' | translate}}</th>
        <td *matCellDef="let element" mat-cell>
          <mat-icon *ngIf="!element.is_held_for_review" mat-list-icon
                    style="color:lightgreen" title="{{'Clypp wurde freigegeben' | translate }}">check_circle_outline
          </mat-icon>
          <button (click)="applyTopicAction(element.id);"
                  [hidden]="!element.is_held_for_review"
                  class="border-radius-20"
                  color="primary"
                  mat-stroked-button
                  title="{{'Überprüfen' | translate }}">
            <mat-icon *ngIf="element.is_held_for_review" class="mr-1">check</mat-icon>
            <span class="d-none d-md-inline" translate>Überprüfen</span>
          </button>
        </td>
      </ng-container>

      <tr *matHeaderRowDef="currentlyDisplayedColumns; sticky: true" mat-header-row></tr>
      <tr *matRowDef="let row; columns: currentlyDisplayedColumns;" mat-row></tr>
    </table>
    <div *ngIf="showSpinner" class="d-flex justify-content-center">
      <mat-spinner></mat-spinner>
    </div>
  </div>

  <h2 *ngIf="!authorised" class="text-nunito m-4" translate>
    You need management rights
  </h2>
</div>
