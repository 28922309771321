<div *ngIf="inProgress" class="spinner-overlay d-flex justify-content-center align-items-center">
  <div class="mt-3 text-center text-white d-flex align-items-center flex-column">
    <mat-spinner [diameter]="60"></mat-spinner>
    <div class="processText"><span translate>Bitte warten...</span></div>
  </div>
</div>
<div class="container-fluid m-sm-3 mt-1 mb-1" style="display: flex;">
  <mat-icon class="mr-1 material-icons-outlined" color="primary" mat-icon>
    local_activity
  </mat-icon>
  <span class="h4 fontStyle mr-4" translate>Meine Aktivität</span>
  <button (click)="deleteActivity()" *ngIf="authService.currentUser?.is_profile_completed" mat-stroked-button
          class="border-radius-20"
          matTooltip="{{ 'Delete My Activity' | translate }}"
          style="margin-top: -3px;">
    <mat-icon class="mr-1" mat-list-icon>
      delete_outline
    </mat-icon>
    {{ "Delete My Activity" | translate }}
  </button>
</div>
<div class="activity-list p-2">
  <table [dataSource]="dataSource" mat-table>
    <ng-container matColumnDef="activity_code">
      <th *matHeaderCellDef mat-header-cell>{{ 'Titel' | translate }}
      </th>
      <td *matCellDef="let element" [matTooltip]="element.activity_code" class="pointer" mat-cell>
        {{ element.activity_code ? element.activity_code : '-' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="supporting_text">
      <th *matHeaderCellDef mat-header-cell>{{ 'Supporting Text' | translate }}
      </th>
      <td *matCellDef="let element" [matTooltip]="element.supporting_text" class="pointer" mat-cell>
        {{ element.supporting_text ? element.supporting_text : '-' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="created_on">
      <th *matHeaderCellDef mat-header-cell>{{ 'Erstellt am' | translate }}</th>
      <td *matCellDef="let element"
          [matTooltip]="element.created_on ? (element.created_on | date:'dd.MM.y HH:mm') : 'null'" mat-cell>
        {{ element.created_on | date:'dd.MM.y HH:mm' }}
      </td>
    </ng-container>
    <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
    <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
  </table>
  <mat-paginator (page)="updateActivites($event)" *ngIf=" activityCurrentPage > 0"
                 [length]="activityCurrentPage" [pageSizeOptions]="pageSizeOptions"
                 [pageSize]="pageSize"
                 hidePageSize
                 showFirstLastButtons
  ></mat-paginator>
</div>
