<div class="justify-content-between d-flex">
  <div class="m-2 d-flex align-items-center" style="flex-flow: wrap;">
    <mat-icon class="material-icons-outlined mr-1" color="primary">
      settings_suggest
    </mat-icon>

    <span class="fontStyle mr-3" style="font-size: x-large;" translate>
      Content Branding
    </span>

    <mat-icon class="material-icons-outlined mr-3"
              matTooltip="{{'Business users can create multiple profile to create personalised Clypps'|translate}}">
      info
    </mat-icon>

    <button (click)="addNewProfile()"
            [disabled]="!(is_authorised && is_pro_user)"
            aria-label="add new content branding"
            class="border-radius-20" mat-stroked-button>
      <mat-icon class="mr-1">
        add
      </mat-icon>
      {{ "Add profile" | translate }}
    </button>
  </div>

  <button aria-label="close" mat-icon-button [mat-dialog-close]="is_profile_added_deleted">
    <mat-icon>close</mat-icon>
  </button>
</div>

<!--Show error messages-->
<p class="text-nunito m-3" *ngIf="!is_authorised" translate>
  You need Video Management rights to edit this
</p>

<p class="text-nunito m-3" *ngIf="!is_pro_user" translate>
  Upgrade to use this feature
</p>

<p class="text-nunito m-3" *ngIf="all_profiles.length == 0 && is_authorised" translate>
  No profile yet. You can always use the default settings.
</p>

<!--show all profiles-->
<div>
  <mat-accordion>
    <!--expansion for existing profile-->
    <mat-expansion-panel *ngFor="let profile of all_profiles; let i = index;"
                         style="border: 1px solid lightgray; border-radius: 5px;"
                         [disabled]="!(is_authorised && is_pro_user)"
                         class="m-3 mat-elevation-z0">
      <mat-expansion-panel-header>
        <div class="w-100 cursor-pointer">
          <mat-panel-title class="text-nunito justify-content-between">
            {{profile.name}}
            <div class="d-flex">
              <button (click)="editProfile(profile, i);" [disabled]="!(is_authorised && is_pro_user)" mat-icon-button>
                <mat-icon mat-list-icon class="material-icons-outlined">edit</mat-icon>
              </button>
              <button (click)="deleteProfile(profile.id, i);" [disabled]="!is_authorised" mat-icon-button>
                <mat-icon mat-list-icon>delete_outline</mat-icon>
              </button>
            </div>
          </mat-panel-title>
        </div>
      </mat-expansion-panel-header>

      <!--intro outro-->
      <div>
        <span class="labelText" translate>
            Intro und Outro
        </span>
        <mat-form-field class="labelValue">
          <mat-select (selectionChange)="attachValue($event.value, profile.id, i,'ci_video_code')"
                      [disabled]="!is_authorised"
                      [(ngModel)]="profile.ci_video_code">
            <mat-option *ngFor="let creditOption of creditDropdown" [value]="creditOption.key">
              {{creditOption.value}}
            </mat-option>
          </mat-select>
          <mat-hint class="text-nunito font-size-14" translate>
            Kann automatisch vor bzw. hinter jedem erstellten Video erzeugt werden
          </mat-hint>
        </mat-form-field>
      </div>

      <!--watermark-->
      <div class="mt-5 d-flex" style="flex-flow: wrap;">
        <span class="labelText mt-2" translate>
            Watermark position
        </span>
        <div class="labelValue">
          <mat-button-toggle-group (change)="attachValue($event.value, profile.id, i, 'overlay_code')"
                                   [disabled]="!is_authorised"
                                   [(ngModel)]="profile.overlay_code">
            <mat-button-toggle value="no">
              <mat-icon>block</mat-icon>
            </mat-button-toggle>
            <mat-button-toggle value="tl">
              <mat-icon style="transform: rotate(180deg);">picture_in_picture_alt
              </mat-icon>
            </mat-button-toggle>
            <mat-button-toggle value="tr">
              <mat-icon>picture_in_picture</mat-icon>
            </mat-button-toggle>
            <mat-button-toggle value="bl">
              <mat-icon style="transform: rotate(180deg);">picture_in_picture
              </mat-icon>
            </mat-button-toggle>
            <mat-button-toggle value="br">
              <mat-icon>picture_in_picture_alt</mat-icon>
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>

      <!--overlay logo-->
      <div class="mt-3">
        <span class="labelText" translate>
            Watermark on Clypps
        </span>
        <mat-form-field class="labelValue">
          <ngx-mat-file-input #overlayCtrl="ngModel" (change)="attachValue(overlayCtrl.value,profile.id,i,'overlay_logo')"
                              [(ngModel)]="profile.overlay_logo"
                              [accept]="'.jpg, .png'" [multiple]="false"
                              [disabled]="!is_authorised"
                              valuePlaceholder="{{'Hier hochladen' | translate}} (png, jpg)">
          </ngx-mat-file-input>
          <mat-hint class="text-nunito font-size-14">
            <b>{{'Currently'|translate}}:&nbsp;</b>
            <a [href]="profile?.overlay_logo"
               target="_blank">{{profile?.overlay_logo.split("/").pop().split("?")[0]}}</a>
            <br>
            {{'This appears on the Clypps after processing.'|translate}}
          </mat-hint>
        </mat-form-field>
      </div>

      <!--background image-->
      <div class="mt-3">
        <span class="labelText" translate>
            Kundenspezifischer Hintergrund
        </span>

        <mat-form-field class="labelValue">
          <ngx-mat-file-input #bgImgCtrl="ngModel" (change)="attachValue(bgImgCtrl.value,profile.id,i,'bg_image')"
                              [(ngModel)]="profile.bg_image"
                              [disabled]="!is_authorised"
                              [accept]="'.jpg,.png,.webp'" [multiple]="false"
                              valuePlaceholder="{{'Hier hochladen' | translate}} (png, jpg, webp)">
          </ngx-mat-file-input>
          <mat-hint class="text-nunito font-size-14">
            <b>{{'Currently'|translate}}:&nbsp;</b>
            <a [href]="profile?.bg_image" target="_blank">{{profile.bg_image.split("/").pop().split("?")[0]}}</a>
            <br>
            {{'The image should have an aspect ratio of 16:9 (at least 1920p x 1080p). ' +
          'It is displayed in the background of a new Clypp to ensure a 16:9 layout of video recordings'|translate}}
          </mat-hint>
        </mat-form-field>
      </div>

      <!--intro video-->
      <div class="mt-4">
        <span class="labelText" translate>
            Intro-Video
        </span>

        <mat-form-field class="labelValue">
          <ngx-mat-file-input #intoCtrl="ngModel" (change)="attachValue(intoCtrl.value,profile.id,i,'intro_video')"
                              [(ngModel)]="profile.intro_video"
                              [disabled]="!is_authorised"
                              [accept]="'.mp4, .mov'" [multiple]="false"
                              valuePlaceholder="{{'Hier hochladen' | translate}} (mp4, mov)">
          </ngx-mat-file-input>
          <mat-hint class="text-nunito font-size-14">
            <b>{{'Currently'|translate}}:&nbsp;</b>
            <a [href]="profile?.intro_video"
               target="_blank">{{profile.intro_video.split("/").pop().split("?")[0]}}</a>
            <br>
            {{'Appended to the beginning after video processing'|translate}}
          </mat-hint>
        </mat-form-field>
      </div>

      <!--outro video-->
      <div class="mt-3">
        <span class="labelText" translate>
            Outro-Video
        </span>
        <mat-form-field class="labelValue">
          <ngx-mat-file-input #outroCtrl="ngModel" (change)="attachValue(outroCtrl.value,profile.id,i,'outro_video')"
                              [(ngModel)]="profile.outro_video"
                              [disabled]="!is_authorised"
                              [accept]="'.mp4, .mov'" [multiple]="false"
                              valuePlaceholder="{{'Hier hochladen' | translate}} (mp4, mov)">
          </ngx-mat-file-input>
          <mat-hint *ngIf="profile?.outro_video" class="text-nunito font-size-14">
            <b>{{'Currently'|translate}}:&nbsp;</b>
            <a [href]="profile?.outro_video"
               target="_blank">{{profile?.outro_video.split("/").pop().split("?")[0]}}</a>
            <br>
            {{'Appended to the end after video processing'|translate}}
          </mat-hint>
        </mat-form-field>
      </div>

      <!-- font file-->
      <div class="mt-3 mb-4">
        <span class="labelText" translate>
            Font for Text Overlays
        </span>

        <mat-form-field class="labelValue">
          <ngx-mat-file-input #fontCtrl="ngModel" (change)="attachValue(fontCtrl.value,profile.id, i,'font_file')"
                              [(ngModel)]="profile.font_file"
                              [disabled]="!is_authorised"
                              [multiple]="false" accept=".ttf"
                              valuePlaceholder="{{'Hier hochladen' | translate}} (ttf)">
          </ngx-mat-file-input>
          <mat-hint class="text-nunito font-size-14">
            <b>{{'Currently'|translate}}:&nbsp;</b>
            <a [href]="profile.font_file" target="_blank">{{profile.font_file.split("/").pop().split("?")[0]}}</a>
            <br>
            {{'Upload a font style, which shall be used for text overlays ' +
          'in the video creation process'|translate}}
          </mat-hint>
        </mat-form-field>
      </div>

    </mat-expansion-panel>
  </mat-accordion>

</div>
