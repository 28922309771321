import {Component, OnInit} from '@angular/core';
import {AuthService} from 'src/app/services/auth.service';
import {DataService} from "../../services/data.service";
import {Chart} from 'chart.js';

@Component({
  selector: 'app-analytics-dashboard',
  templateUrl: './analytics-dashboard.component.html',
  styleUrls: ['./analytics-dashboard.component.scss']
})
export class AnalyticsDashboardComponent implements OnInit {
  n_creators: number = 0;
  n_users: number = 0;
  n_videos: number = 0;
  is_authorised: boolean = false;  // only company and global managers can see the data here

  parameter: string = 'activities';
  window: string = '30D';

  lineCanvas: HTMLCanvasElement;

  constructor(private dataService: DataService,
              public authService: AuthService) {
    if (this.authService.userDetails.is_company_manager || this.authService.userDetails.is_global_manager) {
      this.is_authorised = true;
    }
  }

  ngOnInit(): void {
    this.dataService.getURL<any>(`company/num-creators/`, {observe: 'body', responseType: 'json'}).subscribe((res) => {
      this.n_creators = res['creators'];
      this.n_users = res['users'];
      this.n_videos = res['videos'];
    });

    // call activity table and chart data only if the user is company or global manager
    if (this.is_authorised) {
      this.activitiesChartAPI();
    }
  }

  //chart API call
  activitiesChartAPI() {
    this.dataService.getURL<any>(`manager/activities/date-wise/?window=${this.window}&parameter=${this.parameter}`)
      .subscribe((res) => {
          // remove existing canvas on dropdown change
          if (this.lineCanvas) {
            const existingChart = Chart.getChart(this.lineCanvas);
            existingChart.destroy();
          }
          const interval = setInterval(() => {
            // load after 1/2 a second, as html may not be ready
            this.loadCharts(res);
            clearInterval(interval);
          }, 500);
        }, (err) => {
          window.alert(err.error);
        }
      );
  }

  loadCharts(res) {
    Chart.defaults.font.family = "nunito";
    Chart.defaults.font.size = 14;

    this.lineCanvas = document.getElementById('canvas') as HTMLCanvasElement;
    const formattedLabels = res.map(label => {
      const date = new Date(label.date);
      return date.toLocaleDateString(navigator.language, {day: '2-digit', month: 'short', year: 'numeric'});
    });
    const chart = new Chart(this.lineCanvas, {
      type: "line",
      data: {
        labels: formattedLabels,
        datasets: [
          {
            data: res.map(x => x.value),
            fill: false,
            borderColor: "#f84848",
            borderWidth: 1,
            pointRadius: 4
          },
        ]
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        scales: {
          y: {
            grid: {
              display: true
            },
          },
          x: {
            grid: {
              display: true
            },
            ticks: {
              maxTicksLimit: res.length > 300 ? 12 : 30,
              autoSkip: true
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: true,
          }
        },
        elements: {
          line: {
            tension: 0
          }
        },
      }
    });
  }

  // activity selection change event
  activitySelect(event) {
    this.parameter = event.value;
    this.activitiesChartAPI();
  }

  // duration selection change event
  durationSelect(event) {
    this.window = event.value;
    this.activitiesChartAPI();
  }
}


