<div [formGroup]="questionForm" class="d-flex justify-content-between mb-2" mat-dialog-title>
  <div class="d-flex align-items-center">
    <mat-icon class="material-icons-outlined">
      edit
    </mat-icon>
    <span class="fontStyle ml-1" translate>Edit question</span>
  </div>

  <div class="d-flex align-items-center">
    <!--delete-->
    <button (click)="deleteQuestion()"
            class="mr-2"
            mat-icon-button matTooltip="{{'Löschen'|translate}}">
      <mat-icon>delete_outline</mat-icon>
    </button>

    <!--all options-->
    <button [matMenuTriggerFor]="moreMenu" class="mr-2"
            mat-icon-button matTooltip="{{'Mehr'|translate}}"
            matTooltipPosition="below">
      <mat-icon class="material-icons-outlined">
        more_vert
      </mat-icon>
    </button>

    <!--close button (same as save button)-->
    <button cdkFocusInitial mat-icon-button>
      <mat-icon (click)="saveAndClose();">close</mat-icon>
    </button>

    <!-- more menu -->
    <mat-menu #moreMenu="matMenu" yPosition="below">
      <button (click)="selectMenu($event,'setRequired')" mat-menu-item>
        <span translate>Mark question required</span>
        <mat-icon [hidden]="!questionForm.get('is_required').value" class="ml-2">check</mat-icon>
      </button>
      <button (click)="selectMenu($event,'retake')" mat-menu-item>
        <span translate>Enable question retake</span>
        <mat-icon [hidden]="!questionForm.get('is_retake_allowed').value" class="ml-2">check</mat-icon>
      </button>
      <button (click)="selectMenu($event,'adddesc')" mat-menu-item>
        <span translate>Add description</span>
        <mat-icon [hidden]='!showDescription' class="ml-2">check</mat-icon>
      </button>
      <button (click)="selectMenu($event,'survey-quiz')"
              [disabled]="['ST', 'LT', 'FU'].includes(data.type)" mat-menu-item>
        <span translate>Select correct answer (quiz)</span>
        <mat-icon *ngIf="correct_option_id != 0" class="ml-2">check</mat-icon>
      </button>
    </mat-menu>
  </div>
</div>

<!-- question editor -->
<div class="mx-0" mat-dialog-content>
  <!--title & desc-->
  <div [formGroup]="questionForm">
    <div class="d-flex w-100">
      <mat-form-field class="w-fill-available">
        <mat-label translate>Question</mat-label>
        <input class="text-nunito" formControlName="title"
               matInput maxlength="100"
               placeholder="{{'Add a question here' | translate}}" required>
        <!--todo: copy text button-->
        <!--<button mat-icon-button matSuffix>
          <mat-icon class="material-icons-outlined">content_copy</mat-icon>
        </button>-->
      </mat-form-field>

      <!--file upload-->
      <div *ngIf="!data.supporting_file" class="d-flex align-items-end" style="height: 60px;">
        <!--adding height to bring the button into correct position-->
        <label class="cursor-pointer" for="ques-file-upload">
          <mat-icon class="ml-3 material-icons-outlined">image</mat-icon>
        </label>
        <input (change)="attachFile($event)"
               accept="image/*" hidden id="ques-file-upload"
               type="file"/>
      </div>
    </div>

    <mat-form-field *ngIf="showDescription" class="w-100">
      <mat-label translate>Beschreibung</mat-label>
      <textarea class="text-nunito" formControlName="desc"
                matInput placeholder="{{'Please write your question clearly'|translate}}">
        </textarea>
    </mat-form-field>
  </div>

  <!--ques image & delete-->
  <div *ngIf="data.supporting_file" [style.background-image]="'url(' + data.supporting_file + ')'"
        class="border-radius-10 questionImageUpload">
   <button (click)="removeQuestionFile();" class="bg-white text-dark"
            style="left: 250px; top: 10px;" mat-mini-fab>
      <mat-icon>delete_outline</mat-icon>
    </button>
  </div>

  <!--options-->
  <div *ngIf="['SS', 'MS'].includes(data.type)" class="mt-4">
    <mat-card-subtitle *ngIf="correct_option_id" class="text-nunito">
      {{ 'Select correct answer(s)' | translate }}
    </mat-card-subtitle>

    <!--SS case: radio buttons-->
    <ng-container *ngIf="data.type == 'SS'">
      <mat-radio-group [(ngModel)]="correct_option_id">
        <div *ngFor="let option of allOptions; let i = index;">
          <div class="d-flex w-100 align-items-baseline">
            <!--click-->
            <mat-radio-button [value]="option.id"></mat-radio-button>

            <!--title-->
            <mat-form-field class="w-fill-available">
              <mat-label>Option</mat-label>
              <input (change)="option.changed = true;"
                     [(ngModel)]="option.title" class="text-nunito"
                     id="option-input-{{option.id}}"
                     matInput
                     maxlength="100"
                     placeholder="{{'Please provide a title' | translate}}" required>
            </mat-form-field>

            <!--add image, delete option-->
            <label [hidden]="option.supporting_file" class="cursor-pointer mx-2" for="option{{i}}">
              <mat-icon class="material-icons-outlined" style="top: 0.3em; position: relative;">
                image
              </mat-icon>
            </label>

            <input (change)="attachFile($event, option.id)"
                   accept="image/*" hidden
                   id="option{{i}}"
                   name="optionFile"
                   type="file"/>

            <!--show hide desc-->
            <button (click)="showHideDesc(option.id)" mat-icon-button>
              <mat-icon *ngIf="option.show_desc" class="material-icons-outlined">speaker_notes_off</mat-icon>
              <mat-icon *ngIf="!option.show_desc" class="material-icons-outlined">speaker_notes</mat-icon>
            </button>

            <button (click)="deleteOption(option.id)" [disabled]="allOptions.length <= 2" mat-icon-button>
              <mat-icon>close</mat-icon>
            </button>
          </div>

          <!--reason-->
          <mat-form-field [hidden]="!option.show_desc" class="ml-4 w-75">
            <mat-label translate>Beschreibung</mat-label>
            <input (change)="option.changed = true;"
                   [(ngModel)]="option.reason" class="text-nunito"
                   matInput
                   maxlength="100"
                   placeholder="{{'Please write your question clearly' | translate}}">
          </mat-form-field>

          <!--image & delete image button-->
          <div *ngIf="option.supporting_file" [style.background-image]="'url(' + option.supporting_file + ')'"
               class="my-1 ml-4 border-radius-10 answerImageUpload">
            <button (click)="removeOptionFile(option.id);" class="bg-white text-dark"
                    style="top: 10px; left: 150px" mat-mini-fab>
              <mat-icon>delete_outline</mat-icon>
            </button>
          </div>
        </div>
      </mat-radio-group>
    </ng-container>

    <!--MS case: checkboxes-->
    <ng-container *ngIf="data.type == 'MS'">
      <div *ngFor="let option of allOptions; let i = index;">
        <div class="d-flex w-100 align-items-baseline" id="option-{{option.id}}">
          <!--click-->
          <mat-checkbox (change)="option.changed = true;" [(ngModel)]="option.is_correct"></mat-checkbox>

          <!--title-->
          <mat-form-field class="ml-2 w-fill-available">
            <mat-label>Option</mat-label>
            <input (change)="option.changed = true;"
                   [(ngModel)]="option.title" class="text-nunito"
                   id="option-input-{{option.id}}"
                   matInput
                   maxlength="100"
                   placeholder="{{'Please provide a title' | translate}}" required>
          </mat-form-field>

          <!--add image, delete option-->
          <label [hidden]="option.supporting_file" class="cursor-pointer mx-2" for="option{{i}}">
              <mat-icon class="material-icons-outlined" style="top: 0.3em; position: relative;">
                image
              </mat-icon>
            </label>

          <input (change)="attachFile($event, option.id)" accept="image/*"
                 hidden
                 id="option{{i}}"
                 name="optionFile"
                 type="file"/>

          <!--show hide desc-->
          <button (click)="showHideDesc(option.id)" mat-icon-button>
            <mat-icon *ngIf="option.show_desc" class="material-icons-outlined">speaker_notes_off</mat-icon>
            <mat-icon *ngIf="!option.show_desc" class="material-icons-outlined">speaker_notes</mat-icon>
          </button>

          <!--delete option supporting file-->
          <button (click)="deleteOption(option.id)" [disabled]="allOptions.length <= 2" mat-icon-button>
            <mat-icon>close</mat-icon>
          </button>
        </div>

        <!--reason-->
        <mat-form-field [hidden]="!option.show_desc" class="ml-4 w-75">
          <mat-label translate>Beschreibung</mat-label>
          <input (change)="option.changed = true;"
                 [(ngModel)]="option.reason" class="text-nunito"
                 matInput
                 maxlength="100"
                 placeholder="{{'Please write your question clearly' | translate}}">
        </mat-form-field>

        <!--image & delete image button-->
        <div *ngIf="option.supporting_file" [style.background-image]="'url(' + option.supporting_file + ')'"
             class="my-1 ml-4 border-radius-10 answerImageUpload">
          <button (click)="removeOptionFile(option.id);" class="bg-white text-dark"
                    style="top: 10px; left: 150px" mat-mini-fab>
            <mat-icon>delete_outline</mat-icon>
          </button>
        </div>
      </div>
    </ng-container>

    <button (click)="addOption()" class="border-radius-20 my-2" mat-stroked-button id="add-option-button">
      <span translate>Add option</span>
    </button>
  </div>
</div>

<!-- save button and type selector-->
<mat-dialog-actions class="d-flex justify-content-between align-items-baseline m-2 flex-wrap"
                    style="border-top: 1px solid lightgray;">
  <!--type dropdown-->
  <mat-form-field appearance="outline" class="text-nunito">
    <mat-select (selectionChange)="changeQuestionType();" [(ngModel)]="questionType">
      <mat-option value="SS">{{ 'Single select' | translate }}</mat-option>
      <mat-option value="MS">{{ 'Multiple select' | translate }}</mat-option>
      <mat-option value="ST">{{ 'Short answer' | translate }}</mat-option>
      <mat-option value="LT">{{ 'Long answer' | translate }}</mat-option>
      <mat-option value="FU">{{ 'File upload' | translate }}</mat-option>
    </mat-select>
  </mat-form-field>

  <div class="d-flex">
    <!--view all responses-->
    <button (click)="openResponses();" [disabled]="answers.length == 0"
            [matBadgeHidden]="answers.length == 0" [matBadge]="answers.length"
            class="border-radius-20 mr-2"
            mat-button matBadgePosition="above after" style="min-width: 10em;">
      <mat-icon class="mr-2">leaderboard</mat-icon>
      <span translate>Responses</span>
    </button>

    <!--save button-->
    <button (click)='saveAndClose();' [disabled]="!questionForm.valid || titleEmpty()" class="border-radius-20"
            mat-stroked-button
            style="min-width: 10em;">
      <span class="text-nunito" translate>Speichern</span>
    </button>
  </div>

  <!--showing progress-->
  <mat-progress-bar *ngIf="showSpinner" mode="indeterminate"></mat-progress-bar>
</mat-dialog-actions>
