import { Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DataService } from 'src/app/services/data.service';
import {Question, Option, Answer} from "../../models/question_answer_option";
import {MatSnackBar} from "@angular/material/snack-bar";
import {HttpErrorResponse} from "@angular/common/http";
import {ViewResponsePopupData, ViewResponses} from "../../view-topics/view-question/view-question.component";

interface OptionEditor extends Option {
  changed: boolean;  // to keep track of what to save
  show_desc: boolean;
}

@Component({
  selector: 'quiz-popup',
  templateUrl: './quiz-popup.component.html',
  styleUrls: ['./quiz-popup.component.scss']
})
export class QuizzComponent implements OnInit {
  questionForm: FormGroup;
  questionType: string = 'SS';
  maxSize5MB: number = 5000000;  // 5 MB
  showDescription:boolean = false;
  showSpinner: boolean = false;
  allOptions: OptionEditor[] = [];
  answers: Answer[] = [];  // needed for response popup
  correct_option_id: number = 0;  // when it is zero, it becomes survey mode

  constructor(public dialogRef: MatDialogRef<QuizzComponent>,
    private translateService: TranslateService,
    private dataService: DataService,
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: Question) {

    this.questionForm = this.fb.group({
      title: [data.title, [Validators.required, Validators.maxLength(100)]],
      desc: data.desc,
      is_required: data.is_required,
      is_retake_allowed: data.is_retake_allowed,
    });

    this.questionType = data.type;

    if (this.data.desc) {
      this.showDescription = true;
    }
  }

  ngOnInit(): void {
    // get all options
    this.loadOptions();
    this.loadAnswers();
    // on backdrop click
    this.dialogRef.backdropClick().subscribe(() => {
      this.saveAndClose();
    });
  }

  saveAndClose() {
    this.showSpinner = true;
    if (this.questionForm.dirty && this.questionForm.valid) {
      this.saveQuestion(this.questionForm.value);
    }
    this.saveAllOptions();
    // close after a while
    setTimeout(() => this.dialogRef.close(this.data), 300);
  }

  deleteQuestion() {
    const message = this.translateService.instant("Bist du sicher?");
    if (window.confirm(message)) {
      this.showSpinner = true;
      this.dataService.deleteURL(`user/questions/?id=${this.data.id}`).subscribe((res) => {
        this.snackBar.open(this.translateService.instant('Erfolgreich'), '', {duration: 2000});
        this.dialogRef.close(null);
      });
    }
  }

  // load all options in any question type, as user may change the question type during the popup
  loadOptions() {
    // just load options without checking
    this.showSpinner = true;
    this.dataService.getURL<any>(`user/${this.data.id}/options/`, {
      responseType: 'json',
      observe: 'body'
    }).subscribe((resp: Option[]) => {
      for (let res of resp) {
        this.allOptions.push(
          {
            ...res,
            changed: false,
            show_desc: !(res.reason == '')
          }
        );
      }
      this.showSpinner = false;
      // find the correct option
      const correct_option = this.allOptions.find(e => e.is_correct);
      if (correct_option) {
        this.correct_option_id = correct_option.id;
      }
    })
  }

  // load all answers
  loadAnswers() {
    // 404 means no answer yet
    this.dataService.getURL(`user/${this.data.id}/answers/`, {
      observe: 'body',
      responseType: 'json'
    }).subscribe((res: Answer[]) => {
      // there can be multiple answers per question
      this.answers = res;
    });
  }

  // show/hide ques desc, etc
  selectMenu(event, option: string) {
    // stops menu from closing after selection one option
    event.stopPropagation();
    event.preventDefault();
    if (option == "setRequired") {
      const current_value: boolean = this.questionForm.get('is_required').value;
      this.questionForm.get('is_required').setValue(!current_value);
      //making this variable true as .dirty is readOnly property
      this.questionForm.markAsDirty();
    } else if (option == "retake") {
      const current_value: boolean = this.questionForm.get('is_retake_allowed').value;
      this.questionForm.get('is_retake_allowed').setValue(!current_value);
      this.questionForm.markAsDirty();
    } else if (option == "adddesc") {
      this.showDescription = !this.showDescription;
      this.questionForm.get('desc').setValue('');
    }
    else if (option == "survey-quiz") {
      this.makeItToQuiz();
    }
  }

  // change the question to quiz or Survey
  makeItToQuiz() {
    if (this.correct_option_id == 0) {
      // it was survey mode, mark first option as correct and make it a quiz mode
      if (this.allOptions.length) {
        // ms case
        this.allOptions[0].is_correct = true;
        this.allOptions[0].changed = true;
        // ss case
        this.correct_option_id = this.allOptions[0].id;
      }
    } else {
      // mark all options as incorrect
      this.correct_option_id = 0;
      this.clearAllCorrectOptions();
    }
  }

  // function overridden: used for both question & option, based on option id
  attachFile(event, option_id: number = 0) {
    const file: File = (event.target as HTMLInputElement).files[0];
    if (file.size > this.maxSize5MB) {
      let message = this.translateService.instant('Limit Exceeded: ');
      message += `${Math.floor(file.size / 1000000)} MB / ${this.maxSize5MB / 1000000} MB`
      this.snackBar.open(message, '', {duration: 2000});
      return;
    }

    if (!file.type.includes('image')) {
      return;
    }
    let formData = new FormData();
    formData.append("supporting_file", file, file.name);
    if (option_id) {
      this.saveOption(option_id, formData);
    } else {
      this.saveQuestion(formData);
    }
  }

  changeQuestionType() {
    this.showSpinner = true;
    this.dataService.putURL(`user/questions/?id=${this.data.id}`, {type: this.questionType}, {
      responseType: 'json',
      observe: 'body'
    }).subscribe((resp: Question) => {
      this.showSpinner = false;
      this.data = resp;
      // if this is an SS type, then ensure that only one option is correct
      if (this.questionType == 'SS') {
        // set all correct options to false and save it immediately
        this.clearAllCorrectOptions();
      }
    }, (err: HttpErrorResponse) => {
      this.showSpinner = false;
      console.error(err);
      if (err.status == 429) {
        this.snackBar.open(err.error.detail, '', {duration: 2000});
      } else {
        this.snackBar.open(err.error, '', {duration: 2000});
      }
    });
  }

  saveQuestion(data: any) {
    this.showSpinner = true;
    this.dataService.putURL(`user/questions/?id=${this.data.id}`, data, {
      responseType: 'json',
      observe: 'body'
    }).subscribe((res: Question) => {
      this.showSpinner = false;
      this.data = res;
    });
  }

  // find all correct options and make them false
  clearAllCorrectOptions() {
    const correct_options = this.allOptions.filter(e => e.is_correct);
    correct_options.forEach(e => {
      e.is_correct = false;
      e.changed = true;
    })
  }

  // option desc toggle
  showHideDesc(option_id: number) {
    const option = this.allOptions.find(e => e.id == option_id)
    option.show_desc = !option.show_desc;
  }

  // save any option
  saveOption(id: number, data: any) {
    this.showSpinner = true;
    this.dataService.putURL(`user/${this.data.id}/options/?id=${id}`, data, {
      responseType: 'json',
      observe: 'body'
    }).subscribe((res: Option) => {
      // find & replace
      this.showSpinner = false;
      const index = this.allOptions.findIndex(e => e.id == id);
      this.allOptions[index].supporting_file = res.supporting_file;
    })
  }

  // save all changed options
  saveAllOptions() {
    if (this.data.type == 'SS') {
      // clear all options
      this.clearAllCorrectOptions();
      if (this.correct_option_id) {
        const correct_one = this.allOptions.find(e => e.id == this.correct_option_id);
        correct_one.changed = true;
        correct_one.is_correct = true;
      }
    }
    this.allOptions.forEach(e => {
      if (e.changed && e.title) {
        const data = {
          title: e.title,
          reason: e.reason,
          is_correct: e.is_correct,
        };
        this.saveOption(e.id, data);
      }
    });
  }

  //check option title value
  titleEmpty():boolean {
    return this.allOptions.findIndex(option => option.title == '') > 0
  }

  // add an option
  addOption() {
    const request = {
      // removing the count on option deletion displaying the wrong number
      title: `Option`,
      reason: '',
      is_correct: false,
    };
    this.dataService.postURL(`user/${this.data.id}/options/`, request, {
      responseType: 'json',
      observe: 'body'
    }).subscribe((res: Option) => {
      this.allOptions.push({
          ...res,
          changed: false,
          show_desc: false
        }
      );

      setTimeout(() => {
        this.scrollToItem(res.id);
      }, 200);
    });

  }

  // delete the question's supporting file
  removeQuestionFile() {
    this.saveQuestion({
      supporting_file: '',
    });
  }

  //delete option supporting file
  removeOptionFile(option_id: number) {
    this.saveOption(option_id, {supporting_file: ''});
  }

  // deletes the option
  deleteOption(option_id: number) {
    this.dataService.deleteURL(`user/${this.data.id}/options/?id=${option_id}`).subscribe();
    const index = this.allOptions.findIndex(e => e.id == option_id);
    this.allOptions.splice(index, 1);
  }

  scrollToItem(id: number) {
    // find the newly added option & focus on it
    const input_element = document.getElementById(`option-input-${id}`) as HTMLInputElement;
    input_element.focus();
    input_element.select();
    // scroll bow
    const add_button = document.getElementById('add-option-button');
    add_button.scrollIntoView({behavior: 'smooth'});
  }

  openResponses() {
    // variable created to enforce type checking
    const data: ViewResponsePopupData = {
      question: this.data,
      options: this.allOptions,
      answers: this.answers
    };

    this.dialog.open(ViewResponses, {
      width: "60%",
      minWidth: 400,
      minHeight: 400,
      disableClose: false,
      hasBackdrop: true,
      data: data
    });
  }
}
