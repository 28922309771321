import {
  Component,
  OnInit,
  Input,
  Inject, OnChanges, SimpleChanges, ViewChild
} from '@angular/core';
import {Question, Answer, Option} from "../../models/question_answer_option";
import {MatListOption, MatSelectionList} from "@angular/material/list";
import * as mime from 'mime';
import {DataService} from "../../services/data.service";
import {TranslateService} from "@ngx-translate/core";
import {MatSnackBar} from "@angular/material/snack-bar";
import {HttpErrorResponse} from "@angular/common/http";
import {AuthService} from "../../services/auth.service";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ConfirmDialogComponent} from "../../shared/confirm-dialog/confirm-dialog.component";


@Component({
  selector: 'view-question',
  templateUrl: './view-question.component.html',
  styleUrls: ['./view-question.component.scss']
})
export class ViewQuestionComponent implements OnInit, OnChanges {
  @Input() isTopicCreator: boolean;
  @Input() ques: Question;
  @ViewChild(MatSelectionList) checkboxGroup: MatSelectionList;

  answers: Answer[] = [];
  options: Option[] = [];
  userSSValue: number = 0;  // SS answer by user
  userMSValues: number[] = [];  // MS answer by user
  userStLtValue: string = '';
  userSupportingFile: File = null;

  constructor(private dataService: DataService,
              private translateService: TranslateService,
              private snackBar: MatSnackBar,
              private authService: AuthService,
              private dialog: MatDialog) {
  }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadOptions();
    this.loadAnswers();
  }

  getMimeType(url: string): string {
    let attachmentUrl = this.getAttachedFilename(url);
    const mimeType = mime.getType(attachmentUrl);

    if (mimeType == null) {
      // can be null if not found anything
      return 'other';
    } else if (mimeType.includes('image')) {
      return 'image';
    } else {
      return 'other';
    }
  }

  getAttachedFilename(url) {
    let attachFileName = url.split('/').pop();
    return attachFileName.split("?")[0];
  }

  // for html, disable options, if user has answered it already
  hasAnsweredAlready(): boolean {
    return this.answers.findIndex(e => e.uploader == this.authService.userDetails.user) > -1;
  }

  getMyAnswer(): Answer {
    return this.answers.find(e => e.uploader == this.authService.userDetails.user);
  }

  answerSSQuestion() {
    const id = this.userSSValue;
    // id must be non zero
    if (id) {
      const body = {
        id: id,
      };
      this.answerQuestion(body);
    } else {
      return;
    }
  }

  answerQuestion(body: any) {
    // send call and fetch options again
    this.dataService.postURL(`user/${this.ques.id}/answers/`, body, {
      observe: 'body',
      responseType: 'json'
    }).subscribe((res: Answer) => {
      this.answers.push(res);
      // update userMsValues
      try {
        const my_option_id_string: string = res.text.split('ids=')[1];  // "[1, 2, 3]"
        this.userMSValues = JSON.parse(my_option_id_string); // [1, 2, 3]
      } catch (e) {
        // pass
      }
      // remove snackbar
      // this.snackBar.open(this.translateService.instant('You successfully completed this question'), '', {duration: 2500});
      // load options again, even if answer is wrong
      this.loadOptions();
      this.userSupportingFile = null;
    }, (err: HttpErrorResponse) => {
      console.error(err);
      window.alert(err.error);
    });
  }

  // ques must be SS or MS
  loadOptions(): void {
    // replace option if already there in list
    if (!['SS', 'MS'].includes(this.ques.type)) {
      // check for SS or MS
      return;
    }
    // load data
    this.dataService.getURL(`user/${this.ques.id}/options/`, {
      observe: 'body',
      responseType: 'json'
    }).subscribe((res: Option[]) => {
      this.options = res;
    });
  }

  // load all answers
  loadAnswers() {
    // 404 means no answer yet
    this.dataService.getURL(`user/${this.ques.id}/answers/`, {
      observe: 'body',
      responseType: 'json'
    }).subscribe((res: Answer[]) => {
      // there can be multiple answers per question
      this.answers = res;
      // if user's answer is here, then update userSSValue or selection group
      const my_answer = this.getMyAnswer();
      if (my_answer) {
        // user has answered the ques already, update the two form controls
        try {
          if (this.ques.type == 'SS') {
            let my_option_id: number = parseInt(my_answer.text.split('id=')[1]);  // find my id
            if (my_option_id) {
              this.userSSValue = my_option_id;
            }
          } else if (this.ques.type == 'MS') {
            const my_option_id_string: string = my_answer.text.split('ids=')[1];  // "[1, 2, 3]"
            this.userMSValues = JSON.parse(my_option_id_string); // [1, 2, 3]
            // todo: update this.checkboxGroup
          }
        } catch (ex) {
          // pass
        }
      }
    });
  }

  // returns true if there is at least 1 correct option
  isQuizMode(): boolean {
    if (['SS', 'MS'].includes(this.ques.type)) {
      // check if there is any correct answer
      const n_correct_options = this.options.filter(e => e.is_correct).length;
      if (n_correct_options > 0) {
        return true;
      }
    }  // other types are survey mode too
    return false;
  }

  getSubmitLabel(): string {
    let submit_button_label: string = this.translateService.instant('Submitted');
    let myAnswer = this.getMyAnswer();
    if (myAnswer) {
      this.userStLtValue = myAnswer.text.split('---')[0];
      // update submit button label, if there are correct answers
      if (this.isQuizMode()) {
        // this is not a survey question, there are correct answers
        if (myAnswer.is_correct) {
          submit_button_label = this.translateService.instant("Correct");
        } else {
          submit_button_label = this.translateService.instant("Incorrect");
        }
      }
    }
    return submit_button_label;
  }

  deleteAnswer(answer_id: number): void {
    // delete answer only works if you are a topic creator or retake is allowed
    if (this.isTopicCreator) {
      // ok
    } else if (this.ques.is_retake_allowed) {
      // ok
    } else {
      return;
    }

    // coming here would mean that either I am topic creator or retake is allowed
    const message = this.translateService.instant('Bist du sicher?');
    if (window.confirm(message)) {
      this.dataService.deleteURL(`user/${this.ques.id}/answers/?id=${answer_id}`).subscribe(() => {
        // 204, remove from answers array
        const answer_index: number = this.answers.findIndex(e => e.id == answer_id);
        this.answers.splice(answer_index, 1);
        this.clearAnswers();
        // show snackbar
        this.snackBar.open(this.translateService.instant('Erfolgreich gelöscht'), '', {duration: 2500});
      });
    }
  }

  // clear answers in html, in case of SS & MS
  clearAnswers() {
    // clear selected options in the view
    if (this.ques.type == "SS") {
      this.userSSValue = 0;
    } else if (this.ques.type == "MS") {
      this.checkboxGroup.deselectAll();
    }
  }

  // based on question type, select one of the answer method
  submitResponse() {
    switch (this.ques.type) {
      case 'SS':
        if (this.userSSValue) {
          this.answerSSQuestion();
        }
        break;
      case 'MS':
        break;
      case 'LT':
      case 'ST':
        this.answerSTLTQuestion();
        break;
      case 'FU':
        if (this.userSupportingFile) {
          let formData = new FormData();
          let file_name = this.userSupportingFile.name.slice(-99);
          // add the data to the form
          formData.append("supporting_file", this.userSupportingFile, file_name);
          this.answerQuestion(formData);
        } else {
          // no file
          this.snackBar.open(this.translateService.instant('Please select an image'), '', {duration: 2000});
        }
        break;
    }
  }

  answerMSQuestion(selected_options: MatListOption[]) {
    let ids: number[] = [];
    for (let option of selected_options) {
      ids.push(option.value);
    }
    // ids must not be empty
    if (ids.length == 0) {
      return;
    } else {
      const body = {
        ids: ids,
      };
      this.answerQuestion(body);
    }
  }

  // answer a short or long text question
  answerSTLTQuestion() {
    const text = this.userStLtValue.trim();
    if (text.length == 0) {
      return;
    }
    // text may contain angular brackets or execute commands, which may trigger the WAF
    else {
      const body = {
        text: text,
      };
      this.answerQuestion(body);
    }
  }

  fileSelected(event) {
    this.userSupportingFile = event.target.files[0];
    if (this.userSupportingFile.size > 5000000) {
      const message = this.translateService.instant("Limit Exceeded: ") +
        `${Math.floor(this.userSupportingFile.size / 1000000)} MB / 5 MB`;
      this.snackBar.open(message, '', {duration: 3000});
      this.userSupportingFile = null;
      event.target.value = '';
    }
  }

  openResponses() {
    // variable created to enforce type checking
    const data: ViewResponsePopupData = {
      question: this.ques,
      options: this.options,
      answers: this.answers
    };

    this.dialog.open(ViewResponses, {
      width: "60%",
      minWidth: 400,
      minHeight: 400,
      disableClose: false,
      hasBackdrop: true,
      data: data
    });
  }

  // returns if the reason should be displayed or not
  showReason(option: Option): boolean {
    if (!this.hasAnsweredAlready()) {
      // option hints are only shown after answering
      return false;
    } else if (this.ques.is_retake_allowed) {
      // if retake is allowed show hints for the only selected option(s)
      if (this.ques.type == "SS") {
        return this.userSSValue == option.id;
      } else if (this.ques.type == "MS") {
        return this.userMSValues.includes(option.id);
      }
    } else {
      // if retake is not allowed, then show hints for the correct option and the user selected option
      if (option.is_correct) {
        return true;
      } else {
        // find my answers
        if (this.ques.type == "SS") {
          return this.userSSValue == option.id;
        } else if (this.ques.type == "MS") {
          return this.userMSValues.includes(option.id);
        }
      }
    }
    return false;
  }

}


export interface ViewResponsePopupData {
  question: Question,  // needed to show title, etc
  options: Option[],  // as a ques creator, there will be correct answers
  answers: Answer[],  // may contain my own answer
}


@Component({
  templateUrl: 'view-responses.html',
  styles: []
})
export class ViewResponses {

  is_quiz_mode: boolean = false;  // if there is at least 1 correct option, then it is a quiz mode, else survey mode
  step = 0;  // index of currently shown answer
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ViewResponsePopupData,
    private dataService: DataService,
    private translateService: TranslateService,
    private snackBar: MatSnackBar,
    private authService: AuthService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<ViewResponses>,) {
    if (['SS', 'MS'].includes(this.data.question.type)) {
      this.is_quiz_mode = this.data.options.filter(e => e.is_correct).length > 0;
    }
  }

  getAnswerHtml(answer: Answer): string {
    const html_string: string = this.getUploaderName(answer.uploader) + ':<br>' + answer.text.split('---')[0].replace(/\n/g, "<br>");
    return html_string;
  }

  // show using CL data who answered it
  getUploaderName(user_id: number): string {
    const user_object = this.authService.checklist_data.find(e => e.id == user_id && e.type == 'user');
    return user_object ? user_object.name : 'User not found';
  }

  getAttachedFilename(url) {
    let attachFileName = url.split('/').pop();
    return attachFileName.split("?")[0];
  }

  deleteAnswer(answer: Answer) {
    const title: string = this.translateService.instant('Bist du sicher?');
    const toggle_message: string = this.translateService.instant("Send Email");
    let send_email: boolean = true;

    // if user is deleting own answer, then do not ask for send_email
    if (this.authService.userDetails.user == answer.uploader) {
      send_email = false;
    }

    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: [title, '', send_email, toggle_message],
      minWidth: 300,
    });

    confirmDialog.afterClosed().subscribe((res: [boolean, boolean]) => {
      if (res == undefined) {
        // backdrop clicked
        return;
      } else if (res[0]) {
        // true
        this.deleteAnswerApi(answer, res[1]);
        // go to prev answer
        this.step -= 1;
        if (this.step < 0) {
          this.step = 0;
        }
        // show snackbar
        this.snackBar.open(this.translateService.instant('Erfolgreich gelöscht'), '', {duration: 2500});
      }
    });
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  deleteAllResponses() {
    const title: string = this.translateService.instant('Bist du sicher?');
    const toggle_message: string = this.translateService.instant("Send Email");
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: [title, '', true, toggle_message],
      minWidth: 300,
    });
    confirmDialog.afterClosed().subscribe((res: [boolean, boolean]) => {
      if (res == undefined) {
        // backdrop clicked
        return;
      } else if (res[0]) {
        this.data.answers.forEach((answer: Answer) => this.deleteAnswerApi(answer, res[1]));
        this.snackBar.open(this.translateService.instant('Erfolgreich gelöscht'), '', {duration: 2500})
      }
    });
  }

  deleteAnswerApi(answer: Answer, send_email: boolean) {
    // (we need to be sure that email is not sent in case of creator)
    if (answer.uploader == this.authService.userDetails.user) {
      send_email = false;
    }

    let send_email_param: string = '';
    if (send_email) {
      // topic creator selected yes
      send_email_param = '&send_email';
    }
    this.dataService.deleteURL(`user/${answer.question}/answers/?id=${answer.id}${send_email_param}`).subscribe(() => {
      // 204, remove from answers array
      const answer_index: number = this.data.answers.findIndex(e => e.id == answer.id);
      this.data.answers.splice(answer_index, 1);
      if (this.data.answers.length == 0) {
        // close it
        this.dialogRef.close();
      }
    });
  }

  // mail to the user
  mailToUser(user_id: number) {
    const user_object = this.authService.checklist_data.find(e => e.id == user_id && e.type == 'user');
    if (user_object) {
      // it may be possible that user does not exist, if friend company or anon response
      let subject: string = this.translateService.instant('Clypp: Your response to');
      subject += ' "' + this.data.question.title + '"';
      const link: string = `mailto:${user_object.string}?subject=${subject}`
      window.open(link, '_blank');
    }
  }

  // get user selected option
  checkIfOptionIsSelected(option_id: number): boolean {
    const current_answer = this.data.answers[this.step];
    try {
      if (this.data.question.type == 'SS') {
        const selected_option: number = parseInt(current_answer.text.split("id=")[1]);
        return selected_option == option_id;
      } else if (this.data.question.type == 'MS') {
        const selected_options: number[] = JSON.parse(current_answer.text.split("ids=")[1]);
        return selected_options.includes(option_id);
      }
    } catch (e) {
      // pass
    }
  }

  getMimeType(url: string): string {
    let attachmentUrl = this.getAttachedFilename(url);
    const mimeType = mime.getType(attachmentUrl);

    if (mimeType == null) {
      // can be null if not found anything
      return 'other';
    } else if (mimeType.includes('image')) {
      return 'image';
    } else {
      return 'other';
    }
  }
}
