<div class="d-flex justify-content-between align-items-baseline m-3 flex-wrap">
  <div class="d-flex flex-wrap align-items-baseline">
    <mat-form-field appearance="outline">
      <mat-label translate>Search for…</mat-label>
      <input (keyup)="applyFilter();" [(ngModel)]="search" autocomplete="off" matInput
             placeholder="{{'Title or email' | translate}}">
    </mat-form-field>

    <div *ngIf="selection.hasValue()" class="ml-2"
         style="background-color: #F3F8FD;">
      <!--clear button-->
      <button (click)="selection.clear();" aria-label="clear selection" mat-button>
        <mat-icon class="mr-1">close</mat-icon>
        {{ selection.selected.length }}&nbsp;{{ 'Selected'|translate|lowercase }}
      </button>
      <!--  bulk actions-->
      <button (click)="applyVideoAction();" aria-label="manage selection" mat-button>
        <mat-icon class="material-icons-outlined mr-1">check</mat-icon>
        <span translate>Admin Actions</span>
      </button>
    </div>
  </div>

  <mat-form-field appearance="outline" class="ml-2"
                  matTooltipPosition="above">
    <mat-label translate>Filter</mat-label>
    <mat-select (selectionChange)="updateFilter();" [(ngModel)]="filter_option">
      <mat-option value="published">{{'Veröffentlicht'|translate}}</mat-option>
      <mat-option value="featured">{{'Hervorgehoben'|translate}}</mat-option>
      <mat-option value="external">{{'External'|translate}}</mat-option>
      <mat-option value="restricted">{{'Restricted'|translate}}</mat-option>
      <mat-option [disabled]="!authService.userDetails.is_quality_manager"
                  [matTooltip]="authService.userDetails.is_quality_manager ? '' : quality_admin_tooltip"
                  matTooltipPosition="left"
                  value="drafts">
        {{'Drafts'|translate}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>

<div class="videos-list mx-3 mb-5">
  <table [dataSource]="dataSource" mat-table matSort>
    <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
      <th *matHeaderCellDef class="selectColumnVideo" mat-header-cell>
        <mat-checkbox (change)="$event ? masterToggle() : null"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()">
        </mat-checkbox>
      </th>
      <td *matCellDef="let row" class="selectColumnVideo" mat-cell>
        <mat-checkbox (change)="$event ? selection.toggle(row) : null" (click)="$event.stopPropagation()"
                      [checked]="selection.isSelected(row)">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="restrictions">
      <th *matHeaderCellDef mat-header-cell class="restrictionsColumn"></th>
      <td *matCellDef="let element" mat-cell>
        <div class="d-flex" style="align-items: center;">
          <mat-icon [hidden]="!(element.is_external && !element.is_access_key_needed)"
                    class="mr-2" title="{{'Public' | translate }}" color="primary">
            public
          </mat-icon>
          <mat-icon [hidden]="!(element.is_external && element.is_access_key_needed)"
                    class="mr-2" title="{{'Unlisted' | translate }}" color="primary">
            vpn_lock
          </mat-icon>
          <mat-icon [style.visibility]="element.is_restricted ? 'visible' : 'hidden'"
                    class="mr-2 material-icons-outlined" style="color: grey;"
                    title="{{ 'Restricted visibility inside the workspace' | translate }}">
            lock
          </mat-icon>
          <mat-icon [style.visibility]="element.is_featured ? 'visible' : 'hidden'"
                    title="{{ 'Hervorgehoben' | translate }}"  style="color: gold;">
            star
          </mat-icon>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="title">
      <th *matHeaderCellDef mat-header-cell mat-sort-header class="titleColumn pl-1">
        {{'Titel' | translate}}
      </th>

      <td *matCellDef="let element" [title]="element.title" mat-cell>
          <button (click)="auditVideo(element);"
                  mat-menu-item>
            <span [ngClass]="element.is_draft ? '' : 'primary-color-text'" class="text-nunito">
              {{element.title}}
            </span>
            <!--[disabled]="element.is_draft"-->
          </button>
        </td>
    </ng-container>

    <ng-container matColumnDef="full_name">
      <th *matHeaderCellDef mat-header-cell>
        {{'Creator' | translate}}
      </th>
      <td *matCellDef="let element" mat-cell>
        <a (click)="changeUploader(element);" class="creatorRow d-flex align-items-center
                  text-dark cursor-pointer">
          <span>{{element.uploader.first_name}}</span>
          <span class="d-none d-sm-inline">&nbsp;{{element.uploader.last_name}}</span>
          <button class="showEditButton text-gray" mat-icon-button>
            <mat-icon>edit</mat-icon>
          </button>
        </a>
      </td>
    </ng-container>

    <ng-container matColumnDef="views">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>{{'Ansichten' | translate}}</th>
      <td *matCellDef="let element" mat-cell
          title="{{'Erstellt am' | translate}} {{element.added_on | date: 'dd.MM.yyyy HH:mm'}}">
        {{element.views}}
      </td>
    </ng-container>

    <ng-container matColumnDef="up_votes">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>{{'Likes' | translate}}</th>
      <td *matCellDef="let element" mat-cell>
        {{element.up_votes}}
      </td>
    </ng-container>

    <ng-container matColumnDef="is_held_for_review">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>{{'Freigabe' | translate}}</th>
      <td *matCellDef="let element" mat-cell>
        <mat-icon *ngIf="!element.is_held_for_review" mat-list-icon
                  title="{{'Clypp wurde freigegeben' | translate }}"
                  style="color:lightgreen">check_circle_outline
        </mat-icon>
        <button (click)="applyVideoAction(element.id)"
                *ngIf="element.is_held_for_review"
                mat-stroked-button
                class="border-radius-20"
                title="{{'Überprüfen' | translate }}">
          <mat-icon class="mr-1">check</mat-icon>
          <span translate class="d-none d-md-inline">Überprüfen</span>
        </button>
      </td>
    </ng-container>
    <tr *matHeaderRowDef="currentlyDisplayedColumns; sticky: true" mat-header-row></tr>
    <tr *matRowDef="let row; columns: currentlyDisplayedColumns;" mat-row></tr>
  </table>
  <div *ngIf="showSpinner" class="d-flex justify-content-center">
    <mat-spinner></mat-spinner>
  </div>
</div>

<!--if user is not authorised, let them know-->
<h2 *ngIf="!authorised" class="text-nunito m-4" translate>
  You need management rights
</h2>
