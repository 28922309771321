import {Component, Inject, OnInit} from '@angular/core';
import {DataService} from '../../../services/data.service';
import {MatSnackBar} from "@angular/material/snack-bar";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import {AuthService} from "../../../services/auth.service";
import {urlToBlob} from "../../../quickwin-view/quickwin-view.component";
import {UserProfileWithName, VideoCardManager, VideoView} from "../../../models/video/video.interface";
import {RestrictionDialogComponent} from "../../../restriction-dialog/restriction-dialog.component";
import {MatTableDataSource} from "@angular/material/table";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import JSZip from 'jszip';
import {
  ProgressSpinnerDialogComponent
} from "../../../shared/progress-spinner-dialog/progress-spinner-dialog.component";
import {ConfirmDialogComponent} from "../../../shared/confirm-dialog/confirm-dialog.component";


interface UserHistoryCard {
  viewer: UserProfileWithName;
  viewed_on: string;
  times_viewed: number;
  viewed_till: string;  // upto 2 decimal points
}

interface UserHistoryData {
  n_external_views: number;
  n_internal_views: number;
  n_unique_internal_viewers: number;
  data: UserHistoryCard[];
}

@Component({
  selector: 'app-video-management-popup',
  templateUrl: './video-management-popup.component.html',
  styleUrls: ['./video-management-popup.component.scss']
})
export class VideoManagementPopupComponent implements OnInit {
  vidPath: string = '';
  videoRes: VideoCardManager = null;
  can_change_uploader_title_restrictions: boolean = false;  // only quality managers can change a video's properties
  can_change_dept_team_tooltip: string = "";
  download_button_tooltip: string = "";
  disable_download_button: boolean = false;
  history_data: UserHistoryData = null;
  history_table_data_source: MatTableDataSource<UserHistoryCard> = null;
  displayed_columns: string[] = ['name', 'times_viewed', 'viewed_till', 'viewed_on'];
  company_id: number = 0;
  download_history_uri: SafeUrl = "";
  can_see_statistics: boolean = false;  // business users only
  can_see_activities: boolean = false;  // for pro + and top 2 admins only
  minimum_function: any = null;  // this is a function alias to find min of two numbers
  video_title: string = "";
  is_title_editor_visible: boolean = false;
  vttPath: string = "";
  reload: boolean = false;  // to keep track if anything has changed, so that data would be reloaded

  constructor(private translateService: TranslateService,
              private snackBar: MatSnackBar,
              private dataService: DataService,
              private authService: AuthService,
              private dialog: MatDialog,
              private sanitizer: DomSanitizer,
              public dialogRef: MatDialogRef<VideoManagementPopupComponent>,
              @Inject(MAT_DIALOG_DATA) public data: VideoCardManager) {
    this.videoRes = data;
    if (this.authService.userDetails.is_quality_manager) {
      this.can_change_uploader_title_restrictions = true;
    }
    else {
      this.can_change_dept_team_tooltip = this.translateService.instant("You need Video Management rights to edit this");
    }

    if (this.authService.company.max_upload_video_height == '720') {
      this.download_button_tooltip = this.translateService.instant("Upgrade to use this feature");
      this.disable_download_button = true;
    } else {
      this.download_button_tooltip = this.translateService.instant("Herunterladen");
      this.disable_download_button = false;
    }

    this.company_id = this.authService.company.id;

    if (this.authService.company.max_upload_video_height == '1440') {
      this.can_see_statistics = true;
    }

    if (this.authService.company.max_upload_video_height != '720' && this.authService.userDetails.is_company_manager) {
      this.can_see_activities = true;
    }

    // to use it in HTML code
    this.minimum_function = Math.min;
  }

  ngOnInit(): void {
    this.vidPath = this.videoRes.processed_file;
    this.vttPath = this.videoRes.vtt_file;

    // needed for dashboard review popup
    this.video_title = this.videoRes.title;

    // only business clients can see video stats
    if(this.can_see_statistics){
      this.loadHistory();
    }

    if (!this.vidPath) {
      this.download_button_tooltip = this.translateService.instant("Keine Videodatei vorhanden");
      this.disable_download_button = true;
    }

    this.dialogRef.backdropClick().subscribe(() => {
      this.closeDialog();
    });
  }

  // a quality manager can update the title of a video
  updateTitle(save_title: boolean = false) {
    if (save_title) {
      // user pressed ENTER, save new title after confirming
      if (this.video_title != this.videoRes.title) {
        // title has changed
        let title = this.video_title.trim().slice(0, 100); // take first 100 characters
        if (title) {
          // title is not empty, ask for confirmation and update
          if (window.confirm(this.translateService.instant("Bist du sicher?"))) {
            // user confirmed change
            this.dataService.patchURL(`manager/videos/${this.videoRes.id}/`, {'title': title})
              .subscribe((res) => {
                this.snackBar.open(this.translateService.instant('Erfolgreich'), '', {duration: 2500});
                this.videoRes.title = title;
                this.video_title = title;  // to keep the result of trim and slice
              }, (err) => {
                window.alert(err.error);
              });
          } else {
            // user denied change
            this.video_title = this.videoRes.title;
          }
        } else {
          // title is empty
          this.video_title = this.videoRes.title;
        }
      } else {
        // title has not changed
        // do nothing
      }
    } else {
      // user pressed ESC, reset to prev title
      this.video_title = this.videoRes.title;
    }
    // reset to normal mode
    this.toggleTitleVisibility();
  }


  actionOnBtn(action: string) {
    // confirm first
    const title: string = this.translateService.instant("Bist du sicher?");
    // for cases like approve, feature & unfeature, user based email conditions are applied
    let email_toggle: boolean = true;
    let message = `${this.videoRes.uploader.first_name} ${this.videoRes.uploader.last_name} `;
    message += this.translateService.instant("will also be notified about this.");

    switch (action) {
      case 'feature':
      case 'approve':
      case 'unfeature':
        email_toggle = false;  // email will be sent based on user's preference
        break
      default:
        // email will be sent based on manager's preference
        message = '';  // dont show extra message
        break
    }

    const toggle_message: string = this.translateService.instant("Send Email");
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: [title, message, email_toggle, toggle_message],
      maxWidth: 700,
      minWidth: 400
    });

    // send call
    confirmDialog.afterClosed().subscribe((res: [boolean, boolean]) => {
      if (res == undefined) {
        // backdrop clicked
        return;
      } else if (res[0]) {
        // true, send put call
        const send_email: boolean = res[1];
        const body = {
          action: action,
          ids: [this.videoRes.id],
          send_email: send_email
        }

        this.dataService.putURL(`manager/videos/`, body, {
          observe: 'body',
          responseType: 'text'
        }).subscribe((res: string) => {
          this.snackBar.open(res, '', {duration: 2500});
          this.reload = true;
          this.closeDialog();
        }, (err) => {
          console.error(err);
          this.snackBar.open(this.translateService.instant('Ein Fehler ist aufgetreten'), '', {duration: 2000});
        });
      }
    });
  }

  closeDialog() {
    this.dialogRef.close(this.reload);
  }

  async downloadFile() {
    this.registerDownload('mp4');
    const videoFile = await urlToBlob(this.vidPath);
    const video = new File([videoFile], `${this.videoRes.id}.mp4`);
    const fileLink = document.createElement('a');
    fileLink.href = window.URL.createObjectURL(video);
    fileLink.download = `${this.videoRes.title}.mp4`;
    fileLink.click();
  }

  // this method sends a GET call so that backend would know a user is trying to download a video
  registerDownload(type:string='mp4') {
    this.dataService.postURL(`user/videos/${this.videoRes.id}/download/`, {type}).subscribe();
  }

  // load the history data only if it does not exist before
  loadHistory(): void {
    if (this.history_data) {
      // do not load the data again
      return;
    }
    // else, fetch all the users who viewed this video and show them in a table
    this.dataService.getURL<UserHistoryData>(`manager/videos/${this.videoRes.id}/history/`)
      .subscribe((res: UserHistoryData) => {
        this.history_data = res;
        this.history_table_data_source = new MatTableDataSource(this.history_data.data);
        this.prepareDownloadHistoryCsv();

        // custom function to facilitate filter
        this.history_table_data_source.filterPredicate = (data: UserHistoryCard, filter: string) => {
          let full_name = data.viewer.user.first_name + " " + data.viewer.user.last_name
          if(full_name.toLowerCase().includes(filter)){
            return true;
          }
          // if not found, search for email
          if(data.viewer.user.email.includes(filter)){
            return true;
          }
          // still not found
          return false;
        };
      }, (err) => {
        window.alert(err.error);
      });
  }

  filterUser(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();
    this.history_table_data_source.filter = filterValue;
  }

  // this method fetches a video's restrictions and opens the video restriction popup
  // only a quality manager can update a video's visibility and the user is notified of this
  openRestrictionViewPopup() {
    if(!this.can_change_uploader_title_restrictions){
      // since user can not change the data, show snackbar
      this.snackBar.open(this.can_change_dept_team_tooltip, '', {duration: 2000});
      return;
    }

    // else fetch the data first
    this.dataService.getURL(`manager/videos/${this.videoRes.id}/`, {observe: 'body', responseType: 'json'})
      .subscribe((res: VideoView) => {
        // then open the popup with checklist data
        const dialogRef = this.dialog.open(RestrictionDialogComponent, {
          width: '580px',
          disableClose: false,
          data: {
            checklist_data: this.authService.checklist_data,
            video_object: res
          },
        });

        dialogRef.afterClosed().subscribe((form_response) => {
          if (form_response) {
            // res contains is_restricted, users, departments, teams, locations, groups
            let message = this.videoRes.uploader.email + " "
            message += this.translateService.instant("will also be notified about this.");
            message += '\n\n';
            message += this.translateService.instant("Would you like to continue?");
            if (window.confirm(message)) {
              this.dataService.patchURL(`manager/videos/${this.videoRes.id}/`, form_response)
                .subscribe((res) => {
                  this.snackBar.open(this.translateService.instant('Erfolgreich'), '', {duration: 2500});
                  this.reload = true;
                }, (err) => {
                  window.alert(err.error);
                });
            }
          }
        });
    });
  }

  // prepare data to be downloaded
  prepareDownloadHistoryCsv(){
    let str = "";
    let row = "first_name,last_name,email,viewed_on,viewed_till,times_viewed\r\n"
    str += row;
    // since names may contain alphanumeric charactes, they're now encoded
    for (let i of this.history_data.data) {
      row = encodeURIComponent(i.viewer.user.first_name) + ','
      row += encodeURIComponent(i.viewer.user.last_name) + ','
      row += i.viewer.user.email + ','
      row += i.viewed_on + ','
      row += i.viewed_till + ','
      row += i.times_viewed + '\r\n'
      str += row;
    }

    let uri = this.sanitizer.bypassSecurityTrustUrl("data:text/csv;charset=UTF-8," +
      encodeURIComponent(str));
    this.download_history_uri = uri;
  }

  // show/hide video title editor
  toggleTitleVisibility() {
    // only change it, if user is authorised
    if (this.can_change_uploader_title_restrictions) {
      this.is_title_editor_visible = !this.is_title_editor_visible;
    }
  }

  // download zip
  async downloadZip() {
    const dialogRef: MatDialogRef<ProgressSpinnerDialogComponent> =
      this.dialog.open(ProgressSpinnerDialogComponent, {
        panelClass: 'transparent',
        disableClose: true,
      });

    const zipFileUrl = await this.prepareFileDownload();
    this.registerDownload('zip');
    const fileLink = document.createElement('a');
    fileLink.href = zipFileUrl;
    fileLink.download = `${this.video_title}.zip`;
    fileLink.click();
    dialogRef.close();
  }

  async prepareFileDownload(): Promise<string> {
    const zip = new JSZip();
    const videoFile = await urlToBlob(this.vidPath);
    await this.outerFolder(zip, videoFile);
    const zipFile = await zip.generateAsync<'blob'>({type: 'blob'});
    return window.URL.createObjectURL(zipFile);
  }

  async outerFolder(zip, videoFile) {
    const image = new File(
      [await urlToBlob(this.videoRes.thumbnail)],
      `${this.videoRes.id}.png`
    );
    const video = new File([videoFile], `${this.videoRes.id}.mp4`);
    if (this.vttPath) {
      const vtt_file = new File([await urlToBlob(this.vttPath)],
        `${this.videoRes.id}.vtt`);
      zip.file(vtt_file.name, vtt_file);
    }
    zip.file(image.name, image);
    zip.file(video.name, video);
  }
}
